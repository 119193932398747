import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useOutletContext } from 'react-router-dom';

import { AuthModal } from 'app/views/Login/AuthModal';
import { HeaderMenu } from 'components/menus/HeaderMenu';
import { loggedInMenuItems, loggedOutMenuItems } from 'components/menus/MenuItems';
import { getCookie } from 'handlers/cookie.handler';
import { useLogout } from 'hooks/use-logout';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForUser } from 'services/amplitude/data';
import { useSelfQuery } from 'services/auth/endpoints';
import { selectUser } from 'services/auth/selectors';
import { useGetActiveParkingAndReservationQuery } from 'services/parkingAndReservations/endpoints';
import { useActiveTransactionQuery } from 'services/transactions/endpoints';

import { OutletContext } from './Layout';
import { Logo } from './Logo';

type HeaderProps = {
  className?: string;
};

export const Header = ({ className }: HeaderProps) => {
  const { t } = useTranslation();
  const logout = useLogout();
  const location = useLocation();
  const actualPath = location.pathname;

  const token = getCookie('cu_token');
  const temporaryToken = getCookie('cu_temporaryToken');
  const user = useSelector(selectUser);
  const { initAuth, setInitAuth } = useOutletContext<OutletContext>() || {};

  const [skipActiveSession, setSkipActiveSession] = useState(true);
  const [authOpen, setAuthOpen] = useState(false);
  const initiateLogin = () => {
    setAuthOpen(true);
    trackAmplitudeEvent(AMPLITUDE_EVENTS.INITIATE_LOGIN, {
      ...getAmplitudeEventPropertiesForUser(user),
      startingPoint: 'mainMenu',
    });
  };

  const { isLoading } = useSelfQuery(undefined, {
    skip: !token && !temporaryToken,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: false,
  });

  useActiveTransactionQuery(undefined, {
    skip: skipActiveSession,
    refetchOnMountOrArgChange: true,
  });

  useGetActiveParkingAndReservationQuery(undefined, {
    skip: skipActiveSession,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setSkipActiveSession(!user);
  }, [user]);

  useEffect(() => {
    if (initAuth) {
      setAuthOpen(initAuth);
    }
  }, [initAuth]);

  return (
    <div
      className={`${className} relative z-20 w-full bg-app-header sm:pt-2 shadow-md sm:shadow-none`}
    >
      <header className="sm:border-b-0">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6" aria-label="Top">
          <div className="w-full flex items-center justify-between h-14">
            <div className="flex items-center">
              <Logo navigateTo="/sites" />
            </div>
            <div className="flex items-center text-app-header-link">
              {user?.name && (
                <div className="text-sm pl-2.5">
                  {`${t('hi, [user_name]', 'Hi, {{name}}', { name: user?.name.split(' ')[0] })}`}
                </div>
              )}
              {user && (
                <HeaderMenu menuItems={loggedInMenuItems(t, logout)} selectedItem={actualPath} />
              )}
              {!user && !isLoading && (
                <>
                  <button
                    className="text-base font-semibold bg-vool-green text-white px-6 py-2 rounded-2xl"
                    type="button"
                    onClick={initiateLogin}
                  >
                    {t('logIn', 'Login')}
                  </button>
                  <HeaderMenu
                    menuItems={loggedOutMenuItems(t, initiateLogin)}
                    selectedItem={actualPath}
                  />
                </>
              )}
            </div>
          </div>
        </nav>
      </header>
      <AuthModal
        open={authOpen}
        closeModal={() => {
          setAuthOpen(false);
          setInitAuth?.(false);
        }}
      />
    </div>
  );
};
