import { Cluster, Renderer } from '@googlemaps/markerclusterer';
import { Marker } from '@googlemaps/markerclusterer/dist/marker-utils';

import availableSiteCluster from 'app/images/icons/google-map/availableSiteCluster.svg';
import availableSiteMarker from 'app/images/icons/google-map/availableSiteMarker.svg';
import occupiedSiteCluster from 'app/images/icons/google-map/occupiedSiteCluster.svg';
import occupiedSiteMarker from 'app/images/icons/google-map/occupiedSiteMarker.svg';
import offlineSiteCluster from 'app/images/icons/google-map/offlineSiteCluster.svg';
import offlineSiteMarker from 'app/images/icons/google-map/offlineSiteMarker.svg';
import privateSiteCluster from 'app/images/icons/google-map/privateSiteCluster.svg';
import privateSiteMarker from 'app/images/icons/google-map/privateSiteMarker.svg';
import { createEvses, isEvseAvailable } from 'handlers/device/device.handler';
import { ConnectorComputedStatus } from 'models/device.enums';
import { ParkingAndReservation } from 'models/parking-and-reservation';
import { Site } from 'models/site';
import { Transaction } from 'models/transaction';

const getClusterIcon = (markers?: Marker[]) => {
  const hasAvailableSites = markers?.some((marker) => {
    const icon = (marker as google.maps.Marker).getIcon() as string;
    return icon === availableSiteMarker;
  });

  if (hasAvailableSites) return availableSiteCluster;

  const hasOccupiedSites = markers?.some((marker) => {
    const icon = (marker as google.maps.Marker).getIcon() as string;
    return icon === occupiedSiteMarker;
  });

  if (hasOccupiedSites) return occupiedSiteCluster;

  const hasPrivateSites = markers?.some((marker) => {
    const icon = (marker as google.maps.Marker).getIcon() as string;
    return icon === privateSiteMarker;
  });

  return hasPrivateSites ? privateSiteCluster : offlineSiteCluster;
};

export const clusterRenderer: Renderer = {
  render: ({ count, position, markers }: Cluster) => {
    return new google.maps.Marker({
      label: {
        text: String(count),
        color: 'white',
        className: 'font-semibold',
      },
      position,
      icon: getClusterIcon(markers),
      clickable: true,
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  },
};

export const getSiteMarker = (
  site: Site,
  activeTransaction?: Transaction,
  activeReservation?: ParkingAndReservation,
) => {
  const evses = site.devices.flatMap((device) => createEvses(device));
  const availableEvses = evses.filter((d) =>
    isEvseAvailable(d, activeTransaction, activeReservation),
  );

  if (availableEvses.length) {
    const isSomeChargerCharging = availableEvses.some(
      (evse) => evse.computedStatus === ConnectorComputedStatus.CHARGING,
    );
    return isSomeChargerCharging ? occupiedSiteMarker : availableSiteMarker;
  }

  return site.isUnlisted ? privateSiteMarker : offlineSiteMarker;
};
