import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { IconButton, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    '--tw-bg-opacity': 1,
    backgroundColor: 'rgb(40 40 40 / var(--tw-bg-opacity))',
    maxWidth: '156px',
    textAlign: 'center',
    fontFamily: 'inherit',
    fontSize: '.75rem',
    fontWeight: '400',
  },
});

type DarkTooltipProps = {
  title: string;
};

export const DarkTooltip = ({ title }: DarkTooltipProps) => (
  <StyledTooltip placement="top" title={title} enterTouchDelay={0} leaveTouchDelay={4000}>
    <IconButton aria-label="tooltip">
      <QuestionMarkCircleIcon className="w-4 h-4 text-vool-gray-600" />
    </IconButton>
  </StyledTooltip>
);
