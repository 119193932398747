type LiquidBatteryProps = {
  title: string | number;
  description: string;
  titleClassName?: string;
};

export const LiquidBattery = ({
  title,
  description,
  titleClassName = 'text-sm text-white font-semibold',
}: LiquidBatteryProps) => (
  <div className="relative flex flex-col justify-center -space-y-1 w-14 h-14 rounded-full border-[3px] border-vool-green overflow-hidden overflow-hidden-ios">
    <span className={titleClassName}>{title}</span>
    <span className="text-xs text-vool-gray-200">{description}</span>
    <div className="animate-fullRotate absolute top-2/3 -left-1/2 w-2xfull h-2xfull bg-white rounded-[40%] opacity-10" />
  </div>
);
