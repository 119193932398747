import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { OverflowSpinner } from 'components/spinner/OverflowSpinner';
import { getCookie } from 'handlers/cookie.handler';
import { selectUser } from 'services/auth/selectors';

export const AuthGuard = () => {
  const token = getCookie('cu_token');
  const user = useSelector(selectUser);
  const [showLoadingScreen, setShowLoadingScreen] = useState(!!token);

  useEffect(() => {
    if (user) {
      setShowLoadingScreen(false);
    }
  }, [user]);

  if (showLoadingScreen) {
    return <OverflowSpinner size={20} fit />;
  }

  if (user) {
    return <Outlet />;
  }

  return <Navigate replace to="/sites" />;
};
