import { Navigate, RouteProps } from 'react-router-dom';

import { Account } from 'app/views/Account/Account';
import { ChargingHistory } from 'app/views/ChargingHistory/ChargingHistory';
import { Logout } from 'app/views/Logout';
import { PublicSites } from 'app/views/PublicSites/PublicSites';
import { ResetPassword } from 'app/views/ResetPassword/ResetPassword';

const DEFAULT_PATH = '/sites';

export const customerPortalPublicRoutes: RouteProps[] = [
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/',
    element: <Navigate replace to={DEFAULT_PATH} />,
  },
  {
    path: '/sites',
    element: <PublicSites />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
];

export const customerPortalPrivateRoutes: RouteProps[] = [
  {
    path: '/charge-history',
    element: <ChargingHistory />,
  },
  {
    path: '/account',
    element: <Account />,
  },
];
