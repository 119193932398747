import { buildQueryString } from 'helpers/queryBuilder';
import { Car } from 'models/car';
import { splitApi } from 'services/api';

export interface CarsResponse {
  success: boolean;
  data: Car[];
}
export interface CarResponse {
  success: boolean;
  data: Car;
}

export interface CarsRequest {
  context?: string;
  withChargingSessionsSummary?: boolean;
  withOdometerSummary?: boolean;
  periodStartTime?: Date;
  periodEndTime?: Date;
  carUuid?: string;
}

const extendedApi = splitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCars: builder.query<CarsResponse, CarsRequest>({
      query: (params) => ({
        url: `/cars?${buildQueryString(params)}`,
        method: 'GET',
      }),
    }),
    getCar: builder.query<CarResponse, string>({
      query: (uuid) => ({
        url: `/cars/${uuid}`,
        method: 'GET',
      }),
    }),
    getSmartChargingSessionsSummary: builder.query<CarsResponse, CarsRequest>({
      query: (params) => ({
        url: `/chargingSessions/summary?${buildQueryString(params)}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCarsQuery, useGetCarQuery, useGetSmartChargingSessionsSummaryQuery } =
  extendedApi;

export const carsApi = extendedApi;
