import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import ampherConfig from '../../themes/ampher.json';
import { modifyPortalConfig } from './actions';
import { portalApi } from './endpoints';

interface PortalState {
  config: any;
  bottomSheetHeight?: number;
}

const setPortalConfig = (state: PortalState, { payload }: PayloadAction<{ settingsJson: any }>) => {
  state.config = payload.settingsJson || ampherConfig;
};

const initialState: PortalState = {
  config: {},
};

export const portalSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    updateBottomSheetHeight: (state, { payload }: PayloadAction<number | undefined>) => {
      state.bottomSheetHeight = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(modifyPortalConfig, setPortalConfig);
    builder.addMatcher(portalApi.endpoints.getPortalConfig.matchFulfilled, setPortalConfig);
  },
});

export const { updateBottomSheetHeight } = portalSlice.actions;

export default portalSlice.reducer;
