import { splitApi } from 'services/api';

// TODO: Code here conflicts with schema in the back-end
interface PortalConfigResponse {
  success: boolean;
  settingsJson: any;
}

const extendedApi = splitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPortalConfig: builder.query<PortalConfigResponse, string>({
      query: (domain) => ({
        url: `/portal/${domain}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetPortalConfigQuery } = extendedApi;

export const portalApi = extendedApi;
