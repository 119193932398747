import { useMemo, useState } from 'react';

import loginCoverImage from 'app/images/vool.jpeg';
import { Login } from 'app/views/Login';
import { Modal } from 'components/modals/Modal';

import { AuthStep1 } from './AuthStep1';

type AuthModalProps = {
  open: boolean;
  closeModal: () => void;
};

export const AuthModal = ({ open, closeModal }: AuthModalProps) => {
  const [firstAuthStep, setFirstAuthStep] = useState(true);

  return useMemo(
    () => (
      <Modal
        open={open}
        onClose={closeModal}
        coverImage={firstAuthStep ? loginCoverImage : undefined}
      >
        {firstAuthStep ? (
          <AuthStep1 closeModal={closeModal} goToNextStep={() => setFirstAuthStep(false)} />
        ) : (
          <Login modal closeModal={closeModal} onClose={() => setFirstAuthStep(true)} />
        )}
      </Modal>
    ),
    [open, firstAuthStep],
  );
};
