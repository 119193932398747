import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, useMediaQuery } from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { useSnackbar } from 'components/alerts/Snackbar';
import { StyledButton } from 'components/atoms/StyledButton';
import { getCookie } from 'handlers/cookie.handler';
import { useRegisterAccountlessUserMutation, useSelfQuery } from 'services/auth/endpoints';
import {
  useAddPaymentMethodMutation,
  useSetPaymentMethodAsDefaultMutation,
} from 'services/billing/endpoints';

export type StripePaymentLinkProps = {
  onCancel: () => void;
  onSuccess: () => void;
  accountlessUser?: boolean;
};

export const PaymentLink = ({ onCancel, onSuccess, accountlessUser }: StripePaymentLinkProps) => {
  const { showSnackbar, Snackbar } = useSnackbar();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const temporaryToken = getCookie('cu_temporaryToken');
  const [getSetupIntent] = useAddPaymentMethodMutation();
  const [paymentType, setPaymentType] = useState<string>('card');
  const [loading, setLoading] = useState<boolean>(false);

  const [registerUser] = useRegisterAccountlessUserMutation();
  const [setDefault] = useSetPaymentMethodAsDefaultMutation();
  useSelfQuery(undefined, {
    skip: !temporaryToken,
    refetchOnMountOrArgChange: true,
  });

  const getReturnUrl = () => {
    return `${location.href}/${location.pathname}`;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      setLoading(false);
      showSnackbar(t('somethingWentWrong', 'Something went wrong'));
      return;
    }

    try {
      if (accountlessUser) {
        await registerUser().unwrap();
      }
      const { clientSecret } = await getSetupIntent().unwrap();
      const { error: confirmError, setupIntent } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: { return_url: getReturnUrl() },
        redirect: 'if_required',
      });

      if (confirmError) {
        setLoading(false);
        showSnackbar(t('somethingWentWrong', 'Something went wrong'));
      } else {
        if (accountlessUser && typeof setupIntent.payment_method === 'string') {
          await setDefault(setupIntent.payment_method);
        }

        onSuccess();
      }
    } catch (error) {
      setLoading(false);
      showSnackbar(t('somethingWentWrong', 'Something went wrong'));
    }
  };

  return stripe && elements ? (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      flex={1}
      mt={1.5}
      onSubmit={handleSubmit}
    >
      <PaymentElement
        onChange={(ev) => {
          setPaymentType(ev.value.type);
        }}
        onLoadError={() => {
          showSnackbar(t('errorLoadingPayment', 'Something went wrong while loading payment'));
        }}
      />
      <Box
        mt={3}
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-end"
        flex={1}
        columnGap={1.5}
      >
        <StyledButton color="secondary" fullWidth={isMobile} onClick={onCancel} disabled={loading}>
          {t('cancel', 'Cancel')}
        </StyledButton>
        <StyledButton color="success" fullWidth={isMobile} type="submit" disabled={loading}>
          {paymentType === 'card' ? t('save', 'Save') : t('addCard', 'Add card')}
        </StyledButton>
      </Box>
      {Snackbar}
    </Box>
  ) : null;
};
