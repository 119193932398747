type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  white?: boolean;
  color?: string;
  textColor?: string;
  radiusClass?: string;
  fontSize?: string;
  fontWeight?: string;
  shadow?: string;
};

export const Button = ({
  type = 'button',
  onClick,
  children,
  className,
  disabled,
  white,
  color = 'bg-app-general-dark',
  textColor = 'text-white',
  radiusClass = 'rounded-md',
  fontSize = 'text-sm',
  fontWeight = 'font-medium',
  shadow = 'shadow-sm',
}: ButtonProps) => (
  <button
    disabled={disabled}
    onClick={onClick}
    type={type}
    className={`
      ${className}
      flex
      justify-center
      items-center
      py-2
      px-4
      ${textColor}
      ${white ? 'bg-white border-2 border-app-general-dark text-app-general-dark' : `${color}`}
      focus:outline-none
      ${disabled ? 'opacity-60 hover:opacity-60' : 'hover:opacity-90'}
      ${radiusClass}
      ${fontSize}
      ${fontWeight}
      ${shadow}
    `}
  >
    {children}
  </button>
);
