import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Transaction, TransactionStatus } from 'models/transaction';
import { removeCredentials } from 'services/auth/actions';

import { clearTransaction, updateTransactionFromPusher } from './actions';
import { transactionsApi } from './endpoints';

interface TransactionsState {
  transactions: {
    list: Transaction[];
    period?: string;
  };
  activeTransaction?: Transaction;
}

const initialState: TransactionsState = {
  transactions: {
    list: [],
  },
};

const setTransactionsToState = (
  state: TransactionsState,
  { payload }: PayloadAction<{ transactions: Transaction[] }>,
) => {
  state.transactions.list = payload.transactions;
};

const setActiveTransactionState = (
  state: TransactionsState,
  { payload }: PayloadAction<{ transaction: Transaction }>,
) => {
  if (Object.keys(payload.transaction).length !== 0) {
    state.activeTransaction = payload.transaction;
  } else {
    state.activeTransaction = undefined;
  }
};

const emptyActiveTransactionState = (state: TransactionsState) => {
  state.activeTransaction = undefined;
};

const updateActiveTransactionFromPusher = (
  state: TransactionsState,
  { payload }: PayloadAction<Transaction>,
) => {
  if (state?.activeTransaction?.userUuid !== payload.userUuid) return;
  if (payload?.status === TransactionStatus.Ended) {
    state.activeTransaction = undefined;
  } else {
    state.activeTransaction = {
      ...state.activeTransaction,
      ...payload,
    };
  }
};

const deleteActiveTransaction = (state: TransactionsState) => {
  state.activeTransaction = undefined;
};

const clearState = () => initialState;

export const dataSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactionsPeriod: (state: TransactionsState, { payload }: PayloadAction<string>) => {
      state.transactions.period = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeCredentials, clearState)
      .addCase(updateTransactionFromPusher, updateActiveTransactionFromPusher)
      .addCase(clearTransaction, clearState)
      .addMatcher(
        transactionsApi.endpoints.customerTransactions.matchFulfilled,
        setTransactionsToState,
      )
      .addMatcher(
        transactionsApi.endpoints.activeTransaction.matchFulfilled,
        setActiveTransactionState,
      )
      .addMatcher(transactionsApi.endpoints.startCharging.matchFulfilled, setActiveTransactionState)
      .addMatcher(
        transactionsApi.endpoints.stopCharging.matchFulfilled,
        emptyActiveTransactionState,
      )
      .addMatcher(
        transactionsApi.endpoints.activeTransaction.matchFulfilled,
        setActiveTransactionState,
      )
      .addMatcher(
        transactionsApi.endpoints.cancelPendingTransaction.matchFulfilled,
        deleteActiveTransaction,
      );
  },
});

export const { setTransactionsPeriod } = dataSlice.actions;

export default dataSlice.reducer;
