import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';
import { Notification } from 'app/views/Notifications/Notification';
import { Spinner } from 'components/atoms/Spinner';
import { Transaction } from 'models/transaction';

type InitializingChargeSnackbarProps = {
  activeTransaction: Transaction;
};

export const InitializingChargeSnackbar = ({
  activeTransaction,
}: InitializingChargeSnackbarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { siteUuid, deviceUuid, connectorId } = activeTransaction;

  const handleNavigateToChargingDevice = () => {
    navigate(`/sites?site=${siteUuid}&device=${deviceUuid}-${connectorId}`);
  };

  return (
    <div className="relative flex w-full">
      <Notification
        onClick={handleNavigateToChargingDevice}
        backgroundColor="bg-raisingBlack"
        className="h-[72px]"
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center">
            <CountdownCircleTimer size={55} strokeWidth={3} colors="#eb4e20" duration={0}>
              {() => <Spinner className="absolute" size={4} borderColor="#FFFFFF" />}
            </CountdownCircleTimer>
            <div className="items-start ml-3">
              <p className="font-normal text-white">
                <span className="flex text-sm">
                  {t('initializingCharge', 'Initializing charge!')}
                </span>
                <span className="text-xs opacity-50">
                  {t(
                    'initializingChargeStatusMessage',
                    'Your work here is done. Sit back and relax.',
                  )}
                </span>
              </p>
            </div>
          </div>
          <img src={arrowRightIcon} alt="arrow" className="filter invert w-6 h-6 mr-3" />
        </div>
      </Notification>
    </div>
  );
};
