type SiteIconProps = {
  color?: string;
};

export const SiteIcon = ({ color = '#386957' }: SiteIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill={color} />
    <path
      d="M13.3891 7L8.57283 12.0978C8.53501 12.1382 8.51071 12.1876 8.50281 12.2402C8.49491 12.2928 8.50374 12.3463 8.52825 12.3944C8.55277 12.4426 8.59194 12.4834 8.64114 12.5119C8.69033 12.5405 8.7475 12.5556 8.80586 12.5556H10.9447V17L15.761 11.9022C15.7988 11.8618 15.8231 11.8124 15.831 11.7598C15.8389 11.7072 15.83 11.6537 15.8055 11.6056C15.781 11.5574 15.7419 11.5166 15.6927 11.4881C15.6435 11.4595 15.5863 11.4444 15.5279 11.4444H13.3891V7Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
