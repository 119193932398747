import { useTranslation } from 'react-i18next';

import AppStoreButtonIcon from 'app/images/icons/appStore.svg';
import GooglePlayButtonIcon from 'app/images/icons/googlePlay.svg';
import teslaInFog from 'app/images/tesla-in-fog-pic.png';
import { Modal } from 'components/modals/Modal';

type AddVehicleModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const AddVehicleModal = ({ isOpen, closeModal }: AddVehicleModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} onClose={closeModal} coverImage={teslaInFog}>
      <div className="flex flex-col">
        <div className="text-[32px] font-TwkEverettpb-4">
          {t('addNewVehicle', 'Add new vehicle')}
        </div>
        <div className="flex flex-col pb-4">
          <div className="text-base font-normal pb-3">
            {t(
              'addVehicleText',
              'Add your vehicle to improve the accuracy of insights and predict charge status. To add your vehicle, please log in to the VOOL app or portal.',
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-2">
        <button type="button">
          <a
            href="https://play.google.com/store/apps/details?id=com.vool.app"
            target="_blank"
            rel="noreferrer"
          >
            <img src={GooglePlayButtonIcon} alt="googleButton" />
          </a>
        </button>
        <button type="button">
          <a href="https://apple.co/3YD0rn2" target="_blank" rel="noreferrer">
            <img src={AppStoreButtonIcon} alt="appleButton" />
          </a>
        </button>
      </div>
      <div className="flex justify-center mt-8">
        <button type="button" className="text-primary border-b border-primary" onClick={closeModal}>
          {t('backToCharger', 'Back to charger')}
        </button>
      </div>
    </Modal>
  );
};
