import { Menu } from '@mui/material';

type MenuContainerProps = {
  anchorEl: HTMLElement | null;
  isMobile: boolean;
  children: React.ReactNode;
  handleClose: () => void;
};

export const MenuContainer = ({
  anchorEl,
  isMobile,
  children,
  handleClose,
}: MenuContainerProps) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      marginThreshold={0}
      slotProps={{
        paper: {
          sx: isMobile
            ? {
                width: '100%',
                maxWidth: '100%',
                height: '100%',
                maxHeight: '100%',
                top: '0 !important',
                left: '0 !important',
                paddingBottom: '16px',
              }
            : {
                width: '340px',
                paddingTop: '20px',
                paddingBottom: '16px',
                borderRadius: '20px',
                boxShadow:
                  '0px 3px 12px 0px rgba(0, 0, 0, 0.07), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
              },
        },
      }}
      MenuListProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: 0,
        },
      }}
    >
      {children}
    </Menu>
  );
};
