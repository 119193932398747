import { useTranslation } from 'react-i18next';

import { XIcon } from '@heroicons/react/solid';
import { mdiCookie } from '@mdi/js';
import Icon from '@mdi/react';

type CookieBannerProps = {
  onClose: () => void;
  className?: string;
};

export const CookieBanner = ({ onClose, className }: CookieBannerProps) => {
  const { t } = useTranslation();
  return (
    <div className={`fixed top-16 sm:top-8 center-cookie-banner inset-x-0 z-20 ${className}`}>
      <div className="max-w-4xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-primary shadow-lg sm:p-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-primary">
                <Icon path={mdiCookie} size="24px" className="text-white" />
              </span>
              <p className="ml-3 text-white text-sm">
                <span>{t('acceptCookie', 'By using our services you accept our Cookies')}</span>
              </p>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-white"
                onClick={onClose}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
