import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { availableLanguages, fallbackLanguage } from 'i18nextConf';

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const browserLang = i18n.language;
  const currentLang =
    availableLanguages.find((lng) => browserLang.includes(lng)) ?? fallbackLanguage;
  return (
    <Box width={1} height="64px">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={1}
        gap={3}
        sx={{ backgroundColor: '#F4F4F4', borderRadius: '20px' }}
      >
        {availableLanguages.map((lng) => (
          <Box
            key={lng}
            p={1}
            sx={
              currentLang === lng
                ? {
                    backgroundColor: '#FFFFFF',
                    borderRadius: '150px',
                    color: '#EB4E20',
                    cursor: 'pointer',
                  }
                : { cursor: 'pointer' }
            }
            onClick={() => {
              i18n.changeLanguage(lng);
            }}
          >
            <Typography
              width="24px"
              variant={currentLang === lng ? 'p14b' : 'p14'}
              textTransform="uppercase"
              display="flex"
              justifyContent="center"
            >
              {lng}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
