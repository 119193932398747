import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AddPaymentMethodModal } from 'app/views/Billing/AddPaymentMethodModal';
import { StyledButton } from 'components/atoms/StyledButton';
import { OverflowSpinner } from 'components/spinner/OverflowSpinner';
import { TransactionHandler } from 'handlers/transaction.handler';
import { useCurrency } from 'hooks/use-currency';
import { isEmpty } from 'lodash';
import { ConnectorComputedStatus } from 'models/device.enums';
import { Evse } from 'models/evse';
import { Site } from 'models/site';
import { User } from 'models/user';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForEvse } from 'services/amplitude/data';
import { getErrorMessage } from 'services/api';
import {
  useCancelDeviceReservationMutation,
  useReserveDeviceMutation,
} from 'services/parkingAndReservations/endpoints';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { CancelReservationModal } from './CancelReservationModal';
import { ReservationModal } from './ReservationModal';

type ReserveChargerButtonProps = {
  evse: Evse;
  user?: User;
  status?: ConnectorComputedStatus;
  site?: Site;
  onMessage: (message: string) => void;
  hasPaymentMethods: boolean;
};

export const ReserveChargerButton = ({
  evse,
  user,
  status,
  site,
  onMessage,
  hasPaymentMethods,
}: ReserveChargerButtonProps) => {
  const { t } = useTranslation();
  const activeReservation = useSelector(selectActiveReservation);
  const transaction = useSelector(selectActiveTransaction);
  const currency = useCurrency(site?.defaultCurrency);

  const activeTransaction = transaction && new TransactionHandler({ transaction, currency });
  const deviceUuid = evse.deviceUuid;

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const [isAddPaymentMethodModalOpen, setIsAddPaymentMethodModalOpen] = useState(false);

  const [reserveDevice, { isLoading: reserveLoading }] = useReserveDeviceMutation();
  const [cancelReservation, { isLoading: cancelReserveLoading }] =
    useCancelDeviceReservationMutation({
      fixedCacheKey: 'cancelReservation',
    });

  const buttonLoading = reserveLoading || cancelReserveLoading;

  const handleButtonClick = async () => {
    if (!user || !hasPaymentMethods) {
      setIsReservationModalOpen(true);
    } else if (deviceUuid === activeReservation?.deviceUuid) {
      setCancelModalOpen(true);
    } else {
      trackAmplitudeEvent(AMPLITUDE_EVENTS.START_RESERVATION, {
        ...getAmplitudeEventPropertiesForEvse({ evse, user, site }),
        startingPoint: 'deviceDetails',
      });

      try {
        await reserveDevice(deviceUuid).unwrap();
      } catch (error) {
        onMessage(getErrorMessage(error));
      }
    }
  };

  return (
    <div>
      {site?.settings?.chargerReservation?.isEnabled && (
        <div className="relative">
          <StyledButton
            color="secondary"
            onClick={handleButtonClick}
            disabled={
              (!isEmpty(activeReservation) && deviceUuid !== activeReservation.deviceUuid) ||
              (status !== ConnectorComputedStatus.AVAILABLE &&
                deviceUuid !== activeReservation?.deviceUuid) ||
              activeTransaction !== undefined
            }
            fullWidth
          >
            {deviceUuid === activeReservation?.deviceUuid
              ? t('cancelReserve', 'Cancel reservation')
              : t('reserveCharger', 'Reserve charger')}
          </StyledButton>
          {buttonLoading && <OverflowSpinner size={6} />}
        </div>
      )}
      {activeReservation && (
        <CancelReservationModal
          activeReservation={activeReservation}
          site={site}
          currency={currency}
          isOpen={cancelModalOpen}
          closeModal={() => setCancelModalOpen(false)}
          cancelReservation={() => {
            trackAmplitudeEvent(AMPLITUDE_EVENTS.CANCEL_RESERVATION, {
              ...getAmplitudeEventPropertiesForEvse({ evse, user, site }),
              startingPoint: 'deviceDetails',
            });

            return cancelReservation(deviceUuid);
          }}
          onCancel={(message) => onMessage(message)}
        />
      )}
      <ReservationModal
        openReservationModal={isReservationModalOpen}
        closeReservationModal={() => setIsReservationModalOpen(false)}
        hasPaymentMethods={hasPaymentMethods}
        user={user}
        onAddPaymentMethodClick={() => setIsAddPaymentMethodModalOpen(true)}
      />
      {isAddPaymentMethodModalOpen && (
        <AddPaymentMethodModal
          open={isAddPaymentMethodModalOpen}
          closeModal={() => setIsAddPaymentMethodModalOpen(false)}
          onSuccess={() => setIsAddPaymentMethodModalOpen(false)}
        />
      )}
    </div>
  );
};
