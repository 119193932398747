import AppleLogin from 'react-apple-login';
import { useTranslation } from 'react-i18next';

import { Box, ButtonBase, useMediaQuery } from '@mui/material';

import appleIcon from 'app/images/icons/apple.svg';
import { useSnackbar } from 'components/alerts/Snackbar';
import { appleLoginBundleId } from 'config/consts';
import { useAppleLoginMutation } from 'services/auth/endpoints';

type AppleSignInResponse = {
  scaleForMobile?: boolean;
  closeModal: () => void;
};

export const AppleAuth = ({ closeModal, scaleForMobile = true }: AppleSignInResponse) => {
  const { t } = useTranslation();
  const [appleLogin] = useAppleLoginMutation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { showSnackbar } = useSnackbar();
  scaleForMobile = scaleForMobile && isMobile;

  const handleAppleSignIn = async (response: {
    authorization: { code: string };
    user?: { name: { firstName: string; lastName: string } };
  }) => {
    try {
      if (response.authorization) {
        const { code } = response.authorization;
        const { firstName, lastName } = response?.user?.name || {};
        const bundleId = appleLoginBundleId;
        await appleLogin({ code, bundleId, firstName, lastName });
        closeModal();
      }
    } catch (error) {
      showSnackbar(t('appleLoginFailed', 'Apple login failed'));
    }
  };

  return (
    <AppleLogin
      clientId={appleLoginBundleId}
      redirectURI="https://charge.vool.com/"
      responseType="code"
      responseMode="query"
      state="apple-auth"
      usePopup={true}
      scope="name email"
      callback={handleAppleSignIn}
      render={(renderProps) => (
        <Box display="flex" justifyContent="center">
          <ButtonBase
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className={`h-14 ${scaleForMobile ? 'w-14' : 'w-full'}`}
            sx={{ backgroundColor: 'black', borderRadius: '150px' }}
          >
            <img src={appleIcon} alt="apple" />
            <span
              className={`
            w-auto font-sans text-base font-semibold text-white md:flex
            ${scaleForMobile ? 'hidden' : ''}
          `}
            >
              {scaleForMobile
                ? t('loginWithApple', 'Login with Apple')
                : t('continueWithApple', 'Continue with Apple')}
            </span>
          </ButtonBase>
        </Box>
      )}
    />
  );
};
