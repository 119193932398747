import { Box, BoxProps, styled } from '@mui/material';

import { LoadingSkeleton } from './LoadingSkeleton';

const StyledBox = styled(Box)({
  width: '100%',
  backgroundColor: '#F4F4F4',
  padding: '8px 16px',
  borderRadius: '20px',
  cursor: 'pointer',
  minHeight: '64px',
});

type GrayCardProps = BoxProps & {
  children?: React.ReactNode;
  loading?: boolean;
};

export const GrayCard = ({ children, loading, ...props }: GrayCardProps) => (
  <StyledBox {...props}>{loading ? <LoadingSkeleton /> : children}</StyledBox>
);
