import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { ReactComponent as CancelInfoIcon } from 'app/images/icons/cancelInfo.svg';
import { StyledButton } from 'components/atoms/StyledButton';
import { Modal } from 'components/modals/Modal';
import { Currency } from 'models/currency';
import { ParkingAndReservation } from 'models/parking-and-reservation';
import { Site } from 'models/site';
import moment from 'moment';
import { selectSiteVatMultiplier } from 'services/sites/selectors';

type CancelReservationModalProps = {
  activeReservation: ParkingAndReservation;
  site?: Site;
  currency: Currency;
  isOpen: boolean;
  closeModal: () => void;
  cancelReservation: () => Promise<any>;
  onCancel: (message: string) => void;
};

export const CancelReservationModal = ({
  activeReservation,
  site,
  currency,
  isOpen,
  closeModal,
  cancelReservation,
  onCancel,
}: CancelReservationModalProps) => {
  const { t } = useTranslation();
  const startReservationTimestamp = activeReservation.startTime;
  // TODO: Logic needs fixing, shouldn't use so many ?? operators
  const vatMultiplier = useSelector(selectSiteVatMultiplier(activeReservation.siteUuid)) ?? 1;
  const reservationPricePerMin =
    ((activeReservation?.priceCentsPerMinute ?? 0) / 100) * vatMultiplier;
  const freeReservationSeconds = activeReservation.freeMinutes * 60;
  const paidReservationStart = moment(startReservationTimestamp).add(
    freeReservationSeconds,
    'seconds',
  );
  const isAfterPaidTime = moment().isAfter(paidReservationStart);

  const getPaidReservationCost = () => {
    const durationInMinutes = moment().diff(moment(paidReservationStart), 'seconds') / 60;
    const cost = durationInMinutes * reservationPricePerMin;
    const minimumFee = site?.minimumFee ?? 0.5;
    return Math.max(cost, minimumFee);
  };

  const handleCancelReservation = async () => {
    const response = await cancelReservation();

    const toastMessage = response?.error
      ? t('CancelReservationFailed', 'Failed to cancel reservation')
      : t('CancelReservationSuccess', 'Reservation was cancelled successfully');

    onCancel(toastMessage);
  };

  const cost = getPaidReservationCost().toFixed(2);

  return (
    <Modal open={isOpen} onClose={closeModal} justifyContent="center" gap={3} height={1}>
      <CancelInfoIcon />
      <Typography variant="h3">{t('cancelReservation', 'Cancel reservation')}?</Typography>
      <Typography variant="p16">
        {!isAfterPaidTime ? (
          <Trans i18nKey="youAreOnFreeReservation">
            You are on <strong>free reservation</strong>
          </Trans>
        ) : (
          <Trans
            i18nKey="youAreOnPaidReservationAndCost"
            values={{ cost, currencySymbol: currency.symbol }}
          >
            You are on <strong>paid reservation</strong> and{' '}
            <strong>
              {cost}
              {currency.symbol}
            </strong>{' '}
            will be taken from your account after cancellation.
          </Trans>
        )}
      </Typography>
      <Box display="flex" gap={1} justifyContent="end">
        <StyledButton color="secondary" onClick={closeModal}>
          {t('cancel', 'Cancel')}
        </StyledButton>
        <StyledButton
          onClick={() => {
            closeModal();
            handleCancelReservation();
          }}
        >
          {t('confirm', 'Confirm')}
        </StyledButton>
      </Box>
    </Modal>
  );
};
