import { ExclamationIcon } from '@heroicons/react/outline';

type ComponentErrorProps = {
  error?: any;
};

export const ComponentError = ({ error }: ComponentErrorProps) => (
  <>
    <ExclamationIcon className="h-10 w-10 mt-5 mb-3" />
    <div className="text-center">
      <div>Something went wrong</div>
      <div className="mt-1 text-gray-400 text-xs">{`Error: ${error?.data?.error}`}</div>
    </div>
  </>
);
