import { createTheme } from '@mui/material';

import defaultTheme from 'tailwindcss/defaultTheme';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    p18: React.CSSProperties;
    p16b: React.CSSProperties;
    p16: React.CSSProperties;
    p14b: React.CSSProperties;
    p14: React.CSSProperties;
    p12: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    p18?: React.CSSProperties;
    p16b?: React.CSSProperties;
    p16?: React.CSSProperties;
    p14b?: React.CSSProperties;
    p14?: React.CSSProperties;
    p12?: React.CSSProperties;
  }
}

// https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p18: true;
    p16b: true;
    p16: true;
    p14b: true;
    p14: true;
    p12: true;
    // Disabling default styles
    h4: false;
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    button: false;
    caption: false;
    overline: false;
  }
}

const poppins = ['Poppins', ...defaultTheme.fontFamily.sans].join(',');

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 360,
      sm: 568,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: 'TwkEverett,sans-serif',
    h1: {
      fontWeight: 400,
      fontSize: '80px',
      lineHeight: '100px',
    },
    h2: {
      fontWeight: 400,
      fontSize: '32px',
      lineHeight: '36px',
    },
    h3: {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '32px',
    },
    p18: {
      fontFamily: poppins,
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '32px',
      letterSpacing: '2%',
    },
    p16b: {
      fontFamily: poppins,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    p16: {
      fontFamily: poppins,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    p14b: {
      fontFamily: poppins,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
    },
    p14: {
      fontFamily: poppins,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
    },
    p12: {
      fontFamily: poppins,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
});
