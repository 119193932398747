import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { Car } from 'models/car';

import { VehicleItemIcon } from './VehicleItemIcon';

type VehicleItemProps = {
  onClick: (id: string) => void;
  vehicle: Car;
};

export const VehicleItem = ({ vehicle, onClick }: VehicleItemProps) => {
  const { brand, model, carId, status } = vehicle;
  const batteryLevel = status?.batteryLevel;
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      onClick={() => onClick(carId)}
      gap={2}
      sx={{ cursor: 'pointer', alignItems: 'center' }}
    >
      <VehicleItemIcon brand={brand} />
      <Box display="flex" flexDirection="column">
        <Typography variant="p16">
          {brand} {model}
        </Typography>
        {!!batteryLevel && (
          <Typography variant="p14">
            {t('battery', 'Battery')}: {batteryLevel}%
          </Typography>
        )}
      </Box>
    </Box>
  );
};
