import { XCircleIcon, XIcon } from '@heroicons/react/solid';

type AlertProps = {
  content: string;
  close?: () => void;
};

export const Alert = ({ content, close }: AlertProps) => (
  <div className="rounded-md bg-red-50 p-4 w-full">
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3 flex">
        <h3 className="text-sm font-medium text-red-800 flex-grow">{content}</h3>
      </div>
      {close && (
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button type="button" className="inline-flex p-1.5 text-red-500" onClick={close}>
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      )}
    </div>
  </div>
);
