import classNames from 'classnames';

type NotificationProps = {
  children?: React.ReactNode;
  backgroundColor?: string;
  onClick?: () => void;
  isError?: boolean;
  className?: string;
};

export const Notification = ({
  children,
  backgroundColor = 'bg-green-50',
  onClick,
  isError,
  className,
}: NotificationProps) => {
  const extendedClassName = classNames(
    className,
    'flex items-center w-full px-4 py-2 text-white',
    isError ? 'bg-primary' : backgroundColor ?? 'bg-green-50',
  );
  return onClick ? (
    <button type="button" className={extendedClassName} onClick={onClick}>
      {children}
    </button>
  ) : (
    <div className={extendedClassName}>{children}</div>
  );
};
