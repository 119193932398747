import React from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@mui/material';

import classNames from 'classnames';

export type InputProps = {
  label?: string;
  type?: string;
  name: string;
  value?: string;
  inputProps?: Record<string, unknown>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  leadingAddOn?: string;
  trailingAddOn?: string;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ElementType;
  helpText?: string;
  questionMark?: string;
  className?: string;
  autoComplete?: string;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      type = 'text',
      name,
      value,
      inputProps,
      onChange,
      error,
      leadingAddOn,
      trailingAddOn,
      leadingIcon,
      trailingIcon: TrailingIcon,
      helpText,
      questionMark,
      className,
      autoComplete,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    return (
      <div className="relative pt-3">
        {label && (
          <label
            htmlFor={name}
            className="absolute z-10 top-0 left-2 px-1 bg-white text-sm font-normal text-vool-gray-400 flex items-center"
          >
            {label}
            {questionMark && (
              <Tooltip title={questionMark} placement="top">
                <div>
                  <QuestionMarkCircleIcon className="w-4 h-4 text-gray-400 ml-1" />
                </div>
              </Tooltip>
            )}
          </label>
        )}
        <div className="mt-1 flex items-center">
          <Tooltip
            title={inputProps?.disabled ? t('disabled', 'Disabled') : ''}
            placement="top-start"
          >
            <input
              ref={ref}
              type={type}
              name={name}
              value={value}
              onChange={onChange}
              autoComplete={autoComplete}
              className={classNames(
                className,
                'block w-full h-12 text-base font-normal focus:outline-none border-2',
                { 'rounded-md': !leadingAddOn && !trailingAddOn },
                { 'rounded-r-md': leadingAddOn && !trailingAddOn },
                { 'rounded-l-md': trailingAddOn && !leadingAddOn },
                error
                  ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                  : 'text-gray-900 placeholder-gray-400 border-vool-gray-200',
                { 'pl-10': leadingIcon },
                { 'pr-10': TrailingIcon ?? error },
                { 'opacity-60': inputProps?.disabled },
              )}
              {...inputProps}
            />
          </Tooltip>
          {TrailingIcon && (
            <div className="absolute right-3 h-6 w-6">
              <TrailingIcon />
            </div>
          )}
        </div>
        {helpText && (
          <span
            className={classNames('block mt-1 text-xs', {
              'text-gray-500': !error,
              'text-red-500': error,
            })}
          >
            {helpText}
          </span>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
