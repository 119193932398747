type FastChargerIconProps = {
  stroke?: string;
};

export const FastChargerIcon = ({ stroke = '#386957' }: FastChargerIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7782 2L8.14566 12.1956C8.07002 12.2764 8.02142 12.3752 8.00562 12.4804C7.98982 12.5855 8.00748 12.6926 8.05651 12.7889C8.10554 12.8852 8.18388 12.9667 8.28227 13.0238C8.38067 13.0809 8.49499 13.1112 8.61172 13.1111H12.8894V22L22.5219 11.8044C22.5976 11.7236 22.6462 11.6248 22.662 11.5196C22.6778 11.4145 22.6601 11.3074 22.6111 11.2111C22.562 11.1148 22.4837 11.0333 22.3853 10.9762C22.2869 10.9191 22.1726 10.8888 22.0559 10.8889H17.7782V2Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 5H2" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    <path d="M6 9L3 9" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    <path d="M4 13L2 13" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    <path d="M9 17L4 17" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
  </svg>
);
