import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms/Button';
import { Card } from 'components/atoms/Card';
import { Input } from 'components/atoms/Input';
import { SingleCardWrapper } from 'components/atoms/SingleCardWrapper';
import { CardSpinner } from 'components/spinner/CardSpinner';
import { LOGIN_FORM_OPTIONS } from 'handlers/login-form-options.enum';
import { useForm } from 'hooks/user-form';
import { useResetPasswordMutation } from 'services/auth/endpoints';
import * as yup from 'yup';

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Invalid email')
      .required('Required*')
      .matches(/^\S+@\S+$/, { message: 'Please provide a valid email address' }),
  })
  .required();

type ResetPasswordForm = {
  email: string;
};

type WrapperComponentProps = {
  modal?: boolean;
  children: React.ReactNode;
};

const WrapperComponent = ({ modal, children }: WrapperComponentProps) => {
  if (modal) {
    return <div className="px-4 sm:rounded-lg sm:px-10 ">{children}</div>;
  }

  return <Card radiusClass="rounded-2xl">{children}</Card>;
};

type ResetPasswordBoxProps = {
  children?: React.ReactNode;
  modal?: boolean;
  onEvent: (event: string) => void;
};

export const ResetPasswordBox = ({ children, modal, onEvent }: ResetPasswordBoxProps) => {
  const { t } = useTranslation();
  const eventHandler = () => onEvent(LOGIN_FORM_OPTIONS.LOGIN);
  const { register, handleSubmit, formState, showError, FormError } = useForm<ResetPasswordForm>({
    schema,
  });
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [success, setSuccess] = useState(false);

  const onSubmit = async ({ email }: any) => {
    try {
      await resetPassword({ email }).unwrap();
      setSuccess(true);
      setTimeout(() => eventHandler(), 5000);
    } catch (error) {
      showError(error);
    }
  };

  if (isLoading) {
    return <CardSpinner />;
  }

  if (success) {
    return (
      <SingleCardWrapper>
        <Card>{t('passwordResetLinkWasSent', 'Password reset link was sent to your email.')}</Card>
      </SingleCardWrapper>
    );
  }

  return (
    <SingleCardWrapper>
      <form
        style={{ position: 'relative' }}
        className={`space-y-4 ${modal ? 'border sm:border-none' : ''} rounded-lg py-4 sm:py-0`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <WrapperComponent modal={modal}>
          <div className="mb-6">
            {t('enterAccountRelatedEmail', 'Enter your account related email')}
          </div>
          <div className="w-full">
            <Input
              label={t('email', 'Email address')}
              error={Boolean(formState.errors?.email)}
              helpText={formState.errors?.email?.message}
              {...register('email')}
            />
          </div>
          {children && <div className="text-red-500 text-sm mb-1">{children}</div>}
          {FormError}
          <div className="mt-6 w-full">
            <Button
              type="submit"
              radiusClass="rounded-full"
              className="w-full h-12"
              color="bg-vool-green"
            >
              {t('sendPasswordLink', 'Send password reset link')}
            </Button>
          </div>
          <div className="mt-3 w-full">
            <button type="button" className="w-full text-gray-500 text-sm" onClick={eventHandler}>
              {t('cancel', 'Cancel')}
            </button>
          </div>
        </WrapperComponent>
      </form>
    </SingleCardWrapper>
  );
};
