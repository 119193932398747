import { Box, Typography } from '@mui/material';

import { IOSSwitch } from 'components/atoms/IOSSwitch';

type FilterOptionProps = {
  icon: React.ReactNode;
  name: string;
  description?: string;
  checked: boolean;
  onClick: () => void;
};

export const FilterOption = ({ icon, name, description, checked, onClick }: FilterOptionProps) => (
  <Box
    display="flex"
    flexDirection="row"
    gap={2}
    alignItems="center"
    borderBottom="2px solid #D6D6D6"
    py={description ? '8px' : '20px'}
  >
    {icon}
    <Box display="flex" flexDirection="column" flex={1}>
      <Typography variant="p16">{name}</Typography>
      {description && (
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '24px',
            fontFamily: 'Poppins',
            color: '#5E5E5E',
          }}
        >
          {description}
        </Typography>
      )}
    </Box>

    <IOSSwitch checked={checked} onClick={onClick} />
  </Box>
);
