import { Box } from '@mui/material';

import { LoadingSkeleton } from './LoadingSkeleton';

type TableSkeletonProps = {
  length?: number;
};

export const PaymentSessionsListSkeleton = ({ length = 7 }: TableSkeletonProps) => (
  <>
    {Array.from({ length }).map(() => (
      <Box
        key={crypto.randomUUID()}
        display="flex"
        alignItems="center"
        py={1.5}
        className="border-b-2 border-zinc last:border-none"
      >
        <LoadingSkeleton />
      </Box>
    ))}
  </>
);
