import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography, useMediaQuery } from '@mui/material';

import { ReactComponent as PenIcon } from 'app/images/icons/penIcon.svg';
import { ReactComponent as PlusSignIcon } from 'app/images/icons/plusSign.svg';
import { useSnackbar } from 'components/alerts/Snackbar';
import { StyledButton } from 'components/atoms/StyledButton';
import { Modal } from 'components/modals/Modal';
import { ModalControl } from 'components/modals/ModalControl';
import { PaymentMethod } from 'models/billing';
import { getErrorMessage } from 'services/api';
import {
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
} from 'services/billing/endpoints';
import { selectFooterNotification } from 'services/notifications/selectors';

import { PaymentMethodItem } from './PaymentMethodItem';
import { StripePayment } from './StripePayment';

type PaymentmethodsModalProps = {
  open: boolean;
  paymentMethods?: PaymentMethod[];
  onPaymentMethodClick: (id: string) => void;
  closeModal: () => void;
};

export const PaymentMethodsModal = ({
  open,
  paymentMethods,
  onPaymentMethodClick,
  closeModal,
}: PaymentmethodsModalProps) => {
  const { t } = useTranslation();
  const { showSnackbar, Snackbar } = useSnackbar();
  const [isEditMode, setIsEditMode] = useState(false);
  const [showAddPaymentForm, setShowAddPaymentForm] = useState(!paymentMethods?.length);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const footerNotification = useSelector(selectFooterNotification);

  const [deletePaymentMethod, { isLoading: deletePaymentMethodLoading }] =
    useDeletePaymentMethodMutation();
  const { refetch: refetchPaymentMethods } = useGetPaymentMethodsQuery();

  const deleteCard = async (id: string) => {
    if (deletePaymentMethodLoading) return;
    try {
      await deletePaymentMethod(id).unwrap();
    } catch (error) {
      showSnackbar(getErrorMessage(error));
    }
  };

  const handleClose = () => {
    closeModal();
    setIsEditMode(false);

    if (paymentMethods?.length) {
      setShowAddPaymentForm(false);
    }
  };

  return (
    <Modal
      header={<Typography variant="h3">{t('paymentMethod', 'Payment method')}</Typography>}
      open={open}
      onClose={handleClose}
      height={1}
      minHeight={showAddPaymentForm ? '367px' : 1}
    >
      {showAddPaymentForm ? (
        <StripePayment
          onCancel={handleClose}
          onSuccess={() => {
            setShowAddPaymentForm(false);
            refetchPaymentMethods();
          }}
        />
      ) : (
        <Box display="flex" flexDirection="column" flex={isEditMode ? 1 : 0}>
          {paymentMethods?.map((paymentMethod) => (
            <PaymentMethodItem
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              isEditMode={isEditMode}
              onPaymentMethodClick={() => onPaymentMethodClick(paymentMethod.id)}
              onDeleteClick={() => deleteCard(paymentMethod.id)}
            />
          ))}
        </Box>
      )}
      {!showAddPaymentForm && !isEditMode && (
        <Box display="flex" flexDirection="column" gap={5} mt={3.5}>
          <ModalControl
            Icon={PlusSignIcon}
            label={t('addPaymentMethod', 'Add payment method')}
            onClick={() => {
              setShowAddPaymentForm(true);
            }}
          />
          <ModalControl
            Icon={PenIcon}
            label={t('editPaymentmethods', 'Edit payment methods')}
            onClick={() => setIsEditMode(true)}
          />
        </Box>
      )}

      {isEditMode && (
        <Box mt={3} display="flex" justifyContent="flex-end" mb={`${footerNotification.height}px`}>
          <StyledButton color="success" fullWidth={isMobile} onClick={handleClose}>
            {t('done', 'Done')}
          </StyledButton>
        </Box>
      )}
      {Snackbar}
    </Modal>
  );
};
