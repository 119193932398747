import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { CookieBanner } from 'app/views/Notifications/CookieBanner';
import { getCookie, setCookie } from 'handlers/cookie.handler';
import { ChannelName, EventTypes } from 'handlers/pusher/pusher.enums';
import { useSubscribeToPusherChannel } from 'hooks/use-pusher-channel';
import { selectPublicSites } from 'services/sites/selectors';

import { Footer } from './Footer';
import { Header } from './Header';

export type OutletContext = {
  initAuth: boolean;
  setInitAuth: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
};

export const Layout = () => {
  const location = useLocation();
  const browserCookie = getCookie('cu_cookiedone');

  const allPublicSites = useSelector(selectPublicSites);

  const [cookiesConsented, setCookiesConsented] = useState(!!browserCookie);
  const [initAuth, setInitAuth] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const currentView = location.pathname.split('/')[1];
  const sites = allPublicSites.filter((aps) => !!aps.devices?.length);

  useEffect(() => {
    if (cookiesConsented) {
      setCookie('cu_cookiedone', 'true', { expires: 365 });
    }
  }, [cookiesConsented]);

  useSubscribeToPusherChannel(ChannelName.PUBLIC, EventTypes.PUBLIC);
  return (
    <Box display="flex" flexDirection="column" height={1} width={1}>
      <Header
        className={
          (currentView === 'sites' && sites.length) || isLoading ? 'sm:hidden' : 'hidden sm:block'
        }
      />
      <div
        className={`${
          (currentView === 'sites' && sites.length) || isLoading
            ? 'h-full w-full'
            : 'max-w-7xl mx-auto p-4 pt-5 sm:px-6 lg:px-8 h-full w-full'
        }`}
      >
        <Outlet
          context={{
            initAuth,
            setInitAuth,
            isLoading,
            setIsLoading,
          }}
        />
      </div>
      <Footer
        footerNotificationClassName={
          (currentView === 'sites' && sites.length) || isLoading
            ? 'md:max-w-[400px]'
            : 'max-w-7xl left-1/2 transform -translate-x-1/2'
        }
      />
      {!cookiesConsented && (
        <CookieBanner
          onClose={() => setCookiesConsented(true)}
          className={`
            ${(currentView === 'sites' && sites.length) || isLoading ? 'sm:left-96' : 'mt-10'}
          `}
        />
      )}
    </Box>
  );
};
