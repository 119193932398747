import { Card, CardProps } from 'components/atoms/Card';
import { ComponentError } from 'components/atoms/ComponentError';

import { OverflowSpinner } from '../spinner/OverflowSpinner';

type CardItemProps = CardProps & {
  children: React.ReactNode;
  footer?: React.FC;
  tools?: React.FC;
  loading?: boolean;
  error?: boolean;
  expandable?: boolean;
  closed?: boolean;
};

export const CardItem = ({
  children,
  footer: Footer,
  tools: Tools,
  loading,
  error,
  expandable,
  closed,
  ...props
}: CardItemProps) => {
  if (loading) {
    return (
      <Card>
        <div style={{ minHeight: 50 }}>
          <OverflowSpinner size={14} className="p-5" />
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card {...props}>
        <ComponentError />
      </Card>
    );
  }

  return (
    <Card expandable={expandable} closed={closed} {...props}>
      {Tools && (
        <div className="absolute top-0 right-0 pt-3 pr-5 z-10">
          <Tools />
        </div>
      )}
      {children}

      {Footer && (
        <div className="absolute bottom-0 p-4 pb-7 pr-7 pl-7 w-full">
          <Footer />
        </div>
      )}
    </Card>
  );
};
