import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { ReactComponent as ExitIcon } from 'app/images/icons/exit.svg';
import { Modal } from 'components/modals/Modal';
import { Car } from 'models/car';

import { VehicleItem } from './VehicleItem';

type VehicleModalProps = {
  isOpen: boolean;
  close: () => void;
  vehicles: Car[];
  onVehicleItemClick: (id?: string) => void;
};

export const VehicleModal = ({
  isOpen,
  close,
  vehicles,
  onVehicleItemClick,
}: VehicleModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpen}
      onClose={close}
      header={
        <Typography variant="h3"> {t('chooseYourVehicle', 'Choose your vehicle')}</Typography>
      }
    >
      <Box display="flex" flexDirection="column" gap={2}>
        {vehicles.map((vehicle) => (
          <VehicleItem key={vehicle.id} vehicle={vehicle} onClick={onVehicleItemClick} />
        ))}
        <Box
          display="flex"
          sx={{ cursor: 'pointer', alignItems: 'center' }}
          onClick={() => onVehicleItemClick(undefined)}
          gap={2}
        >
          <Box
            display="flex"
            sx={{
              width: 56,
              height: 56,
              bgcolor: '#eb4e20',
              borderRadius: '20px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ExitIcon color="white" />
          </Box>
          <Typography variant="p16">{t('unknownVehicle', 'Unknown vehicle')}</Typography>
        </Box>
      </Box>
    </Modal>
  );
};
