import { ServiceType } from 'models/parking-and-reservation.enums';
import { createSelector } from 'reselect';
import { RootState } from 'services/store';

export const selectSlice = (state: RootState) => state.parkingAndReservations;

export const selectActiveReservation = createSelector(selectSlice, (slice) =>
  slice?.activeParkingAndReservation?.serviceType === ServiceType.Reservation
    ? slice.activeParkingAndReservation
    : undefined,
);

export const selectActiveParking = createSelector(selectSlice, (slice) =>
  slice?.activeParkingAndReservation?.serviceType === ServiceType.Parking
    ? slice.activeParkingAndReservation
    : undefined,
);
