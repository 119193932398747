import { LocationMarkerIcon } from '@heroicons/react/outline';
import { Box, Typography, useMediaQuery } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'app/images/icons/arrowRight.svg';
import { ReactComponent as CloseIcon } from 'app/images/icons/exit.svg';
import { Site } from 'models/site';

type SiteDrawerHeaderProps = {
  site: Site;
  onCloseClicked: () => void;
};

export const SiteDrawerHeader = ({ site, onCloseClicked }: SiteDrawerHeaderProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Box
      display="flex"
      alignItems="center"
      padding="12px 16px 0px 24px"
      pl={isMobile ? '8px' : '24px'}
    >
      {isMobile && (
        <Box p={1.5} onClick={onCloseClicked} sx={{ cursor: 'pointer' }}>
          <ArrowIcon style={{ transform: 'scaleX(-1)' }} />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        width={1}
        overflow="hidden"
        gap={isMobile ? '4px' : 0}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h3" flex={1} noWrap>
            {site.name}
          </Typography>
          {!isMobile && (
            <Box
              p={1}
              borderRadius="50%"
              sx={{ backgroundColor: '#F4F4F4', cursor: 'pointer' }}
              onClick={onCloseClicked}
            >
              <CloseIcon />
            </Box>
          )}
        </Box>
        <Box display="flex" color="#999898">
          <Typography variant="p14" flex={1} noWrap>
            {site.address}
          </Typography>
          {site.distance && (
            <Box display="flex" flexShrink={0} alignItems="center" gap="4px">
              <LocationMarkerIcon className="h-5 w-5" aria-hidden="true" />
              <Typography variant="p14">{site.distance?.text}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
