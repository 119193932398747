import { ParkingAndReservation } from 'models/parking-and-reservation';
import { splitApi } from 'services/api';

interface ParkingAndReservationResponse {
  success: boolean;
  data?: ParkingAndReservation;
}

const extendedApi = splitApi.injectEndpoints({
  endpoints: (builder) => ({
    reserveDevice: builder.mutation<ParkingAndReservationResponse, string>({
      query: (deviceUuid) => ({
        url: `/devices/${deviceUuid}/reservation`,
        method: 'POST',
      }),
    }),
    cancelDeviceReservation: builder.mutation<{ success: boolean }, string>({
      query: (deviceUuid) => ({
        url: `/devices/${deviceUuid}/reservation`,
        method: 'DELETE',
      }),
    }),
    cancelParking: builder.mutation<{ success: boolean }, void>({
      query: () => ({
        url: '/sites/parking',
        method: 'DELETE',
      }),
    }),
    getActiveParkingAndReservation: builder.query<ParkingAndReservationResponse, void>({
      query: () => ({
        url: '/devices/activeParkingAndReservation',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useReserveDeviceMutation,
  useCancelDeviceReservationMutation,
  useCancelParkingMutation,
  useGetActiveParkingAndReservationQuery,
} = extendedApi;

export const parkingAndReservationApi = extendedApi;
