import infoIcon from 'app/images/icons/info.svg';

type ChargerInfoMessageProps = {
  message: string;
  children?: React.ReactNode;
};

export const ChargerInfoMessage = ({ message, children }: ChargerInfoMessageProps) => (
  <div className="flex flex-row space-x-2 mb-4">
    <img src={infoIcon} alt="charger state info" className="h-5" />
    <span>
      {message} <strong>{children}</strong>
    </span>
  </div>
);
