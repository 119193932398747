export const googleApiKey = JSON.parse(process.env.REACT_APP_GOOGLE_API ?? '')[
  process.env.REACT_APP_CONF_FILE ?? ''
];
export const googleLoginUrl = JSON.parse(process.env.REACT_APP_GOOGLE_LOGIN ?? '')[
  process.env.REACT_APP_CONF_FILE ?? ''
];

export const appleLoginBundleId = JSON.parse(process.env.REACT_APP_APPLE_LOGIN ?? '')[
  process.env.REACT_APP_CONF_FILE ?? ''
];
