import { Device } from 'models/device';
import { updatePublicDeviceFromPusher } from 'services/sites/actions';

import { DataHandler } from '../data.handler';

export class PusherDevicesDataHandler {
  data;
  meta;
  action;
  object;

  constructor({ data, meta, object }: any) {
    this.action = meta.action;
    this.object = object;
    this.data = data;
    this.meta = meta;
  }

  updateDevice() {
    const device: Device =
      this.meta?.partial === false
        ? this.data.current
        : {
            [this.meta.fieldName]: this.meta.fieldValue,
            uuid: this.meta.deviceUuid,
            status: this.data.current.status,
            activePower: this.data.current.active_power,
            ...(this.meta.connectorId && {
              stateOfConnectors: [
                DataHandler.cleanDataObject({
                  connectorId: this.meta.connectorId,
                  ocppStatus: this.data.current.ocppStatus,
                  transactionStatus: this.data.current.transactionStatus,
                  computedStatus: this.data.current.computedStatus,
                }),
              ],
            }),
          };

    if (device.uuid === undefined) {
      console.error(`Device uuid is undefined: ${this.meta.fieldName}`, this.meta.fieldValue);
    }

    const cleanDataDevice = DataHandler.cleanDataObject(device);
    return [
      updatePublicDeviceFromPusher({
        device: cleanDataDevice,
        partial: this.meta?.partial === undefined ? true : this.meta.partial,
      }),
    ];
  }
}
