import { SyntheticEvent, useEffect, useState } from 'react';

import { Slider, styled } from '@mui/material';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';

import { Spinner } from './Spinner';

const CustomSlider = styled(Slider)({
  '& .MuiSlider-thumb': {
    height: 40,
    width: 40,
    backgroundColor: '#fff',
    '&:focus, &:hover, &:active': {
      boxShadow: 'none',
    },
    zIndex: 1,
    backgroundImage: `url(${arrowRightIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  '& .MuiSlider-thumb.Mui-focusVisible': {
    boxShadow: 'none',
  },
  '& .MuiSlider-active': {},
  '& .MuiSlider-track': {
    visibility: 'hidden',
  },
  '& .MuiSlider-rail': {
    opacity: 0,
    height: 56,
    borderRadius: 28,
  },
});

type SwipeButtonProps = {
  onSuccess: () => void;
  disabled?: boolean;
  text?: string;
  className?: string;
  style?: object;
  loading?: boolean;
};

export const SwipeButton = ({
  onSuccess,
  disabled,
  text = 'Swipe to confirm',
  className,
  style,
  loading,
}: SwipeButtonProps) => {
  const [position, setPosition] = useState(0);

  const handleSuccess = (
    event: Event | SyntheticEvent<Element, Event>,
    value: number | number[],
  ) => {
    if (typeof value === 'number' && value < 100) setPosition(0);
    else onSuccess();
  };

  const handleDrag = (event: Event, value: number | number[]) => {
    if (loading || disabled) return;
    if (typeof value === 'number') setPosition(value);
  };

  useEffect(() => {
    if (!loading) {
      setPosition(0);
    }
  }, [loading]);

  return (
    <div
      className={`${className} relative rounded-full h-14 flex items-center justify-center bg-gray-500 p-2 px-7 ${
        (disabled || loading) && 'opacity-60 hover:opacity-60 pointer-events-none'
      }`}
      style={style}
    >
      {!loading && (
        <CustomSlider
          defaultValue={0}
          value={position}
          min={0}
          max={100}
          onChange={handleDrag}
          onChangeCommitted={handleSuccess}
        />
      )}
      <span className="absolute w-full text-center text-base font-semibold text-white select-none">
        {text}
      </span>
      <div
        className={`absolute border border-white rounded-full outline-none right-2 h-10 w-10 flex items-center justify-center ${
          loading ? 'bg-white' : ''
        }`}
      >
        {loading && <Spinner size={4} />}
      </div>
    </div>
  );
};
