import { DataHandler } from 'handlers/data.handler';

import { PusherDevicesDataHandler } from './pusher-devices-data.handler';
import { PusherTransactionsDataHandler } from './pusher-transactions-data.handler';
import { PusherVehicleDataHandler } from './pusher-vehicle-data.handler';

const HandlerType = {
  device: PusherDevicesDataHandler,
  transaction: PusherTransactionsDataHandler,
  car: PusherVehicleDataHandler,
};

interface PusherActionTypes {
  created: string;
  updated: string;
  deleted: string;
}

const getActionTypes = (objectType: keyof typeof HandlerType): PusherActionTypes => {
  return {
    created: `create${DataHandler.getCapitalizedString(objectType)}`,
    updated: `update${DataHandler.getCapitalizedString(objectType)}`,
    deleted: `delete${DataHandler.getCapitalizedString(objectType)}`,
  };
};

export class PusherDataHandler {
  data;
  meta;
  object: keyof typeof HandlerType;

  constructor({ data, meta }: any) {
    this.data = data;
    this.meta = meta;
    this.object = meta.object;
  }

  getActions() {
    const Handler = HandlerType[this.object];
    const actionType = getActionTypes(this.object)[this.meta.action as keyof PusherActionTypes];
    if (Handler && actionType) {
      const handlerInstance = new Handler(this);
      return (handlerInstance as any)[actionType]();
    }
    return null;
  }
}
