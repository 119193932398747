export class DataHandler {
  static getCapitalizedString(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  static cleanDataObject(object: any) {
    const objectCopy = { ...object };
    Object.keys(objectCopy).forEach((key) => {
      if (objectCopy[key] === undefined) {
        delete objectCopy[key];
      }
    });
    return objectCopy;
  }
}
