import { Box, Skeleton } from '@mui/material';

export const LoadingSkeleton = () => (
  <Box display="flex" flex={1} alignItems="center" gap={2}>
    <Skeleton variant="rounded" width={24} height={24} />
    <Box display="flex" flexDirection="column" flex={1}>
      <Skeleton width="70%" animation="wave" />
      <Skeleton width="40%" animation="wave" />
    </Box>
  </Box>
);
