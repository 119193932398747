import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Snackbar, Typography, styled } from '@mui/material';

import { selectFooterNotification } from 'services/notifications/selectors';

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  '& > :first-of-type': {
    borderRadius: '20px !important',
    padding: '12px 16px',
    backgroundColor: '#282828',
  },
  '& .MuiSnackbarContent-message': {
    padding: 0,
  },
  '& .MuiSnackbarContent-root': {
    [theme.breakpoints.up('sm')]: {
      minWidth: '343px',
    },
  },
}));

const RelativeSnackbar = styled(StyledSnackbar)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  left: 0,
  right: 0,
  bottom: 16,
  [theme.breakpoints.up('sm')]: {
    bottom: 16,
    left: 'auto',
    right: 'auto',
  },
  '& > :first-of-type': {
    width: '100%',
    margin: '0 16px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 24px',
    },
  },
}));

export function useSnackbar() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const footerNotification = useSelector(selectFooterNotification);

  const showSnackbar = (content: string) => {
    setMessage(content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    showSnackbar,
    RelativeSnackbar: (
      <Box position="relative">
        <RelativeSnackbar
          open={open}
          onClose={handleClose}
          message={<Typography variant="p14">{message}</Typography>}
          autoHideDuration={4000}
        />
      </Box>
    ),
    Snackbar: (
      <StyledSnackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        message={<Typography variant="p14">{message}</Typography>}
        autoHideDuration={4000}
        sx={{ bottom: `${24 + footerNotification?.height}px !important` }}
      />
    ),
  };
}
