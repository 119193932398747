import { ConnectorComputedStatus } from 'models/device.enums';
import { Evse } from 'models/evse';
import { Site } from 'models/site';
import { Transaction } from 'models/transaction';

import { getEvseStatus } from './device/device.handler';

export class SiteHandler {
  site: Site;

  constructor(site: Site) {
    this.site = site;
  }

  getData() {
    return this.site;
  }

  isMarketPrice() {
    const { site } = this;
    const revenuePricingModel = site.revenuePricingModel;
    return revenuePricingModel?.setting === 'market';
  }

  getPublicFixedPrice() {
    const { site } = this;
    return site.revenuePricingModel?.fixedWithVat ?? '-';
  }

  isSomeEvseCharging(evses: Evse[], activeTransaction?: Transaction) {
    return evses.some(
      (evse) => getEvseStatus(evse, activeTransaction) === ConnectorComputedStatus.CHARGING,
    );
  }
}
