import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Device } from 'models/device';
import { Evse } from 'models/evse';
import { Site } from 'models/site';

import {
  updateActiveSite,
  updateActiveSiteByUuid,
  updatePublicDeviceFromPusher,
  updateSitesDistance,
} from './actions';
import { sitesApi } from './endpoints';

type SiteHourlyPrice = {
  centsPerKwh: number;
  centsPerKwhWithVat: number;
  vatRate: number;
  currency: string;
};

export type SiteHourlyPrices = Record<string, SiteHourlyPrice>;

interface HourlyPricesPayload {
  siteUuid: string;
  pricesPerHour: SiteHourlyPrices;
}

interface SitesState {
  publicSites: Site[];
  hourlyPrices: Record<string, SiteHourlyPrices>;
  activeSiteUuid?: string;
  activeDevice?: Device;
  activeEvse?: Evse;
}

const updatePublicDeviceBySerialNumber = (
  state: SitesState,
  { payload }: PayloadAction<{ device: Device; partial: boolean }>,
) => {
  const { uuid: payloadDeviceUuid } = payload.device;
  const site = state.publicSites.find(({ devices }) =>
    devices.some(({ uuid }) => uuid === payloadDeviceUuid),
  );
  if (site) {
    const deviceIndex = site.devices.findIndex(({ uuid }) => uuid === payloadDeviceUuid);
    if (deviceIndex !== -1) {
      const deviceInState = site.devices[deviceIndex];
      const updatedDevice = payload.partial
        ? {
            ...deviceInState,
            ...payload.device,
            stateOfConnectors: deviceInState.stateOfConnectors?.map((stateOfConnector) => ({
              ...stateOfConnector,
              ...payload.device?.stateOfConnectors?.find(
                (payloadStateOfConnector) =>
                  payloadStateOfConnector.connectorId === stateOfConnector.connectorId,
              ),
            })),
          }
        : payload.device;
      site.devices[deviceIndex] = updatedDevice;
    }
  }
};

const setPublicSites = (state: SitesState, { payload }: PayloadAction<{ sites: Site[] }>) => {
  state.publicSites = orderSitesByDistance(
    payload.sites.map((site) => {
      const siteFromState = state.publicSites.find((s) => s.uuid === site.uuid);
      if (siteFromState) {
        return {
          ...siteFromState,
          ...site,
        };
      }
      return { ...site, vatMultiplier: site.countryVat / 100 + 1 };
    }),
  );
};

const setHourlyPrices = (state: SitesState, { payload }: PayloadAction<HourlyPricesPayload>) => {
  state.hourlyPrices[payload.siteUuid] = Object.fromEntries(
    Object.entries(payload.pricesPerHour).filter(([, v]) => v != null),
  );
};

const setActiveSiteUuid = (state: SitesState, { payload }: PayloadAction<Site | undefined>) => {
  state.activeSiteUuid = payload?.uuid;
};

const setActiveSiteByUuid = (state: SitesState, { payload }: PayloadAction<string>) => {
  state.activeSiteUuid = payload;
};

const updateSitesDistanceAndOrder = (state: SitesState, { payload }: PayloadAction<Site[]>) => {
  const updatedSites = state.publicSites.map((site) => {
    const siteWithDistance = payload.find((s) => s.uuid === site.uuid);
    if (siteWithDistance) {
      site.distance = siteWithDistance.distance;
    }
    return site;
  });

  state.publicSites = orderSitesByDistance(updatedSites);
};

const getDistanceValue = (d: google.maps.Distance) => {
  const value = parseFloat(d.text.replace(/[^0-9.]/g, ''));
  const isKm = d.text.includes('km');
  return isKm ? value : value / 1000;
};

const orderSitesByDistance = (sites: Site[]) => {
  return sites.sort((a, b) => {
    const aDistance = a.distance ? getDistanceValue(a.distance) : Infinity;
    const bDistance = b.distance ? getDistanceValue(b.distance) : Infinity;
    return aDistance - bDistance;
  });
};

const initialState: SitesState = {
  publicSites: [],
  hourlyPrices: {},
};

export const dataSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setActiveEvse: (state, { payload }: PayloadAction<Evse | undefined>) => {
      state.activeEvse = payload;
    },
    setActiveDevice: (state, { payload }: PayloadAction<Device | undefined>) => {
      state.activeDevice = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSitesDistance, updateSitesDistanceAndOrder);
    builder.addCase(updatePublicDeviceFromPusher, updatePublicDeviceBySerialNumber);
    builder.addCase(updateActiveSite, setActiveSiteUuid);
    builder.addCase(updateActiveSiteByUuid, setActiveSiteByUuid);
    builder.addMatcher(sitesApi.endpoints.publicSites.matchFulfilled, setPublicSites);
    builder.addMatcher(sitesApi.endpoints.getSiteHourlyPrices.matchFulfilled, setHourlyPrices);
  },
});

export const { setActiveEvse, setActiveDevice } = dataSlice.actions;
export default dataSlice.reducer;
