type OfflineSiteIconProps = {
  fill?: string;
};

export const OfflineSiteIcon = ({ fill = '#999898' }: OfflineSiteIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0007 10.5828L16.9507 5.63281L18.3647 7.04681L13.4147 11.9968L18.3647 16.9468L16.9507 18.3608L12.0007 13.4108L7.05072 18.3608L5.63672 16.9468L10.5867 11.9968L5.63672 7.04681L7.05072 5.63281L12.0007 10.5828Z"
      fill={fill}
    />
  </svg>
);
