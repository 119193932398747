import { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, MenuItem, Typography, useMediaQuery } from '@mui/material';

import { ReactComponent as ExitIcon } from 'app/images/icons/exit.svg';
import hamburgerMenu from 'app/images/icons/hamburgerMenu.svg';
import { LanguageSelector } from 'app/layouts/LoggedinBasicLayout/LanguageSelector';
import { trackAmplitudeEvent } from 'services/amplitude';
import { getAmplitudeEventPropertiesForUser } from 'services/amplitude/data';
import { selectUser } from 'services/auth/selectors';
import { updateFooterNotificationData } from 'services/notifications';
import { selectFooterNotification } from 'services/notifications/selectors';

import { FeedbackCard } from '../feedback/FeedbackCard';
import { MenuContainer } from './MenuContainer';
import { Item } from './MenuItems';

type HeaderMenuProps = {
  menuItems?: Item[];
  selectedItem?: string;
};

export const HeaderMenu = ({ menuItems, selectedItem }: HeaderMenuProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 568px)');
  const user = useSelector(selectUser);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const footerNotification = useSelector(selectFooterNotification);

  const handleFooterNotificationVisible = (isVisible: boolean) => {
    dispatch(
      updateFooterNotificationData({
        isVisible,
      }),
    );
  };

  const handleMenuClick = (item: Item) => {
    handleFooterNotificationVisible(true);
    if (item.onClick) {
      item.onClick();
    } else {
      if (item?.amplitudeEvent) {
        trackAmplitudeEvent(item?.amplitudeEvent, getAmplitudeEventPropertiesForUser(user));
      }
      if (item.href) {
        navigate(item?.href);
      }
    }

    handleClose();
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton disableRipple onClick={handleClick}>
        <img src={hamburgerMenu} />
      </IconButton>
      <MenuContainer anchorEl={anchorEl} isMobile={isMobile} handleClose={handleClose}>
        {isMobile && (
          <Box display="flex" justifyContent="flex-end" p={2} onClick={handleClose}>
            <ExitIcon />
          </Box>
        )}
        <Box display="flex" alignItems="center" flex={1} width={1}>
          <Box width={1}>
            {menuItems?.map((item) => (
              <MenuItem
                key={item.title}
                onClick={() => handleMenuClick(item)}
                sx={{
                  paddingX: '24px',
                  paddingY: 0,
                  '&.Mui-selected': {
                    backgroundColor: '#F4F4F4',
                    color: '#EB4E20',
                    '&:hover': {
                      backgroundColor: '#F4F4F4',
                    },
                  },
                }}
                selected={selectedItem === item?.href}
              >
                <Box display="flex" gap={2} height={isMobile ? '60px' : '48px'} alignItems="center">
                  {item.icon && <item.icon />}
                  <Typography variant="p16">{item.title}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          mt={3}
          px={2}
          mb={`${footerNotification.height}px`}
        >
          <FeedbackCard />
          <LanguageSelector />
        </Box>
      </MenuContainer>
    </>
  );
};
