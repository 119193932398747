import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { TextFieldProps, Typography } from '@mui/material';

import { StyledTextField } from './StyledTextField';

type FormTextFieldProps<T extends FieldValues> = TextFieldProps & {
  control: Control<T>;
  name: Path<T>;
};

export const FormTextField = <T extends FieldValues>({
  control,
  name,
  ...props
}: FormTextFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <StyledTextField
          inputRef={ref}
          {...field}
          {...props}
          error={Boolean(error)}
          helperText={error?.message}
          label={<Typography variant="p14">{props.label}</Typography>}
          inputProps={{
            ...props.inputProps,
            style: { padding: '8px 12px' },
          }}
        />
      )}
    />
  );
};
