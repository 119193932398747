import { mdiEvStation } from '@mdi/js';
import Icon from '@mdi/react';

// TODO: Needs translation
export const SlideBarEmptyView = () => (
  <div className="text-center flex flex-col items-center pt-8">
    <Icon path={mdiEvStation} size="56px" className="text-gray-400" />
    <div className="mt-4">
      <div className="text-lg">No chargers available at this site.</div>
    </div>
  </div>
);
