import classNames from 'classnames';

const sizeClasses = {
  4: 'h-4 w-4',
  6: 'h-6 w-6',
  14: 'h-14 w-14',
  16: 'h-16 w-16',
  18: 'h-18 w-18',
  20: 'h-20 w-20',
};

export type SpinnerProps = {
  size?: keyof typeof sizeClasses;
  borderColor?: string;
  className?: string;
  backgroundColor?: string;
};

export const Spinner = ({
  size = 14,
  borderColor = 'border-app-general-dark',
  className,
  backgroundColor,
}: SpinnerProps) => (
  <div className="flex justify-center items-center">
    <div
      // TODO: use tailwind capabilities to set left border style after tailwind update
      style={{ borderLeftStyle: 'inset' }}
      className={classNames(
        'animate-spin rounded-full',
        sizeClasses[size],
        'border-2',
        borderColor,
        className,
        backgroundColor,
      )}
    />
  </div>
);
