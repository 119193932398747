import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { hotjar } from 'react-hotjar';
import { I18nextProvider } from 'react-i18next';

import { App } from 'app/core';
import { initAmplitude } from 'services/amplitude';

import i18n from './i18nextConf';
import './index.css';

if (process.env.NODE_ENV === 'production') {
  hotjar.initialize(3345124, 6);
}

initAmplitude();

const domNode = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(domNode!);
root.render(
  <Suspense fallback="...is loading">
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Suspense>,
);
