import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography, useMediaQuery } from '@mui/material';

import { FormTextField } from 'components/atoms/FormTextField';
import { IOSSwitch } from 'components/atoms/IOSSwitch';
import { StyledButton } from 'components/atoms/StyledButton';
import { Modal } from 'components/modals/Modal';
import { useForm } from 'hooks/user-form';
import { CompanyBillingDetails } from 'models/company';
import { useEditBillingDetailsMutation } from 'services/auth/endpoints';
import { selectCompanyBillingDetails, selectUser } from 'services/auth/selectors';
import { selectFooterNotification } from 'services/notifications/selectors';
import * as yup from 'yup';

import { CountryAutocomplete } from './CountryAutocomplete';

const useSchema = () => {
  const { t } = useTranslation();

  return yup
    .object()
    .shape({
      name: yup.string().required(t('required*', 'Required*')),
      registrationNumber: yup.string().required(t('required*', 'Required*')),
      taxIdNumber: yup.string().nullable(),
      email: yup.string().required(t('required*', 'Required*')),
      invoicingEmail: yup.string().nullable(),
      country: yup.string().required(t('required*', 'Required*')),
      city: yup.string().required(t('required*', 'Required*')),
      address1: yup.string().required(t('required*', 'Required*')),
      zip: yup.string().nullable(),
    })
    .required();
};

type BillingDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
};

export const BillingDetailsModal = ({ open, onClose, onSave }: BillingDetailsModalProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const companyBillingDetails = useSelector(selectCompanyBillingDetails);
  const user = useSelector(selectUser);
  const footerNotification = useSelector(selectFooterNotification);

  const [companyBillingEnabled, setCompanyBillingEnabled] = useState(
    Boolean(user?.companyBillingFlag),
  );
  const schema = useSchema();
  const { control, handleSubmit, reset, showError, FormError, clearErrors } =
    useForm<CompanyBillingDetails>({
      schema,
      defaultValues: companyBillingDetails ?? {
        name: '',
        registrationNumber: '',
        taxIdNumber: '',
        email: '',
        invoicingEmail: '',
        country: '',
        city: '',
        address1: '',
        zip: '',
      },
    });

  useEffect(() => {
    if (companyBillingDetails && Object.keys(companyBillingDetails).length > 0) {
      reset({
        ...companyBillingDetails,
      });
    }
  }, [companyBillingDetails]);

  useEffect(() => {
    setCompanyBillingEnabled(Boolean(user?.companyBillingFlag));
  }, [user]);

  const [editBillingDetails, { isLoading: editBillingDetailsSaving }] =
    useEditBillingDetailsMutation();

  const onSubmit: SubmitHandler<CompanyBillingDetails> = async (billingDetails) => {
    try {
      delete billingDetails.updateTime;
      await editBillingDetails({
        billingDetails: companyBillingEnabled ? billingDetails : undefined,
        companyBillingFlag: companyBillingEnabled ? 1 : 0,
      }).unwrap();
      onSave?.();
      onClose();
    } catch (error) {
      showError(error);
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setCompanyBillingEnabled(event.target.checked);
      if (!event.target.checked) {
        clearErrors();
        await editBillingDetails({ companyBillingFlag: 0 }).unwrap();
        onSave?.();
      }
    } catch (error) {
      showError(error);
      setCompanyBillingEnabled(Boolean(user?.companyBillingFlag));
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      gap={3}
      header={<Typography variant="h3">{t('billingDetails', 'Billing details')}</Typography>}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="p18">{t('enable', 'Enable')}</Typography>
        <IOSSwitch checked={companyBillingEnabled} onChange={handleChange} />
      </Box>
      <Typography variant="p16">
        {t(
          'billingDetailsDescription',
          'Please provide your company details. These details will appear on invoices sent to you.',
        )}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          mb={isMobile ? `${footerNotification.height}px` : 0}
        >
          <Typography variant="p18" mb={1}>
            {t('company', 'Company')}
          </Typography>
          <FormTextField
            control={control}
            name="name"
            label={t('companyName', 'Company name')}
            disabled={!companyBillingEnabled}
          />
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 2 : 4}>
            <FormTextField
              control={control}
              name="registrationNumber"
              label={t('registrationNumber', 'Registration number')}
              disabled={!companyBillingEnabled}
            />
            <FormTextField
              control={control}
              name="taxIdNumber"
              label={`${t('vatNumber', 'VAT number')} (${t('optional', 'optional')})`}
              disabled={!companyBillingEnabled}
            />
          </Box>
          <Typography variant="p18">{t('invoicingDetails', 'Invoicing details')}</Typography>
          <Box display="flex" flexDirection="column" gap={3}>
            <FormTextField
              control={control}
              name="email"
              label={t('companyEmailAddress', 'Company email address')}
              disabled={!companyBillingEnabled}
            />
            <FormTextField
              control={control}
              name="invoicingEmail"
              label={`${t('invoicingEmail', 'Invoicing email')} (${t('optional', 'optional')})`}
              disabled={!companyBillingEnabled}
            />
          </Box>
          <Typography variant="p18">{t('address', 'Address')}</Typography>
          <CountryAutocomplete control={control} name="country" disabled={!companyBillingEnabled} />
          <FormTextField
            control={control}
            name="address1"
            label={t('streetAddress', 'Street address')}
            disabled={!companyBillingEnabled}
          />
          <FormTextField
            control={control}
            name="city"
            label={t('city', 'City')}
            disabled={!companyBillingEnabled}
          />
          <FormTextField
            control={control}
            name="zip"
            label={t('zipPostal', 'ZIP/postal')}
            disabled={!companyBillingEnabled}
          />
          {FormError}
          <StyledButton
            type="submit"
            color="success"
            disabled={editBillingDetailsSaving || !companyBillingEnabled}
          >
            {t('save', 'Save')}
          </StyledButton>
        </Box>
      </form>
    </Modal>
  );
};
