import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';
import { ReactComponent as InvoiceIcon } from 'app/images/icons/invoice.svg';
import { useSnackbar } from 'components/alerts/Snackbar';
import { GrayCard } from 'components/atoms/GrayCard';
import { getErrorMessage } from 'services/api';
import { useSelfQuery } from 'services/auth/endpoints';
import { selectCompanyBillingDetails, selectUser } from 'services/auth/selectors';

import { BillingDetailsModal } from './BillingDetailsModal';

export const BillingDetailsCard = () => {
  const { t } = useTranslation();
  const { showSnackbar, Snackbar } = useSnackbar();
  const user = useSelector(selectUser);
  const companyBillingDetails = useSelector(selectCompanyBillingDetails);
  const [modalOpen, setModalOpen] = useState(false);
  const { refetch: refetchUser, isFetching: userFetching } = useSelfQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const handleClick = () => {
    if (!userFetching) {
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    try {
      refetchUser().unwrap();
    } catch (error) {
      showSnackbar(getErrorMessage(error));
    }
  };

  const handleSave = () => {
    showSnackbar(t('billingDetailsSaved', 'Billing details saved'));
  };

  return (
    <>
      <GrayCard
        display="flex"
        alignItems="center"
        gap={2}
        onClick={handleClick}
        loading={userFetching}
      >
        <InvoiceIcon color={user?.companyBillingFlag ? '#EB4E20' : '#999898'} />
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography variant="p16">{t('companyDetails', 'Company Details')}</Typography>
          <Typography variant="p14" color={user?.companyBillingFlag ? '#282828' : '#999898'}>
            {!companyBillingDetails
              ? t('billingAsCompany', 'Billing as company')
              : companyBillingDetails?.name}
          </Typography>
        </Box>
        <img src={arrowRightIcon} alt="Go to company" />
      </GrayCard>
      {Snackbar}
      {modalOpen && (
        <BillingDetailsModal open={modalOpen} onClose={handleModalClose} onSave={handleSave} />
      )}
    </>
  );
};
