import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

import staticMarker from 'app/images/icons/staticMarker.svg';
import { Modal } from 'components/modals/Modal';
import { getApiUrl } from 'config/api';
import { googleApiKey } from 'config/consts';
import { format } from 'date-fns';
import { getCookie } from 'handlers/cookie.handler';
import { PaymentSessionSummary, ServiceDimension } from 'models/billing';
import { Site } from 'models/site';
import moment from 'moment';
import {
  useGetPaymentSessionDetailsQuery,
  useLazyGetInvoiceUrlQuery,
} from 'services/billing/endpoints';

import { SummaryDetails, SummaryItem } from './SummaryDetails';

type SummaryModalProps = {
  summary: PaymentSessionSummary;
  site?: Site;
  onClose: () => void;
};

export const SummaryModal = ({ summary, site, onClose }: SummaryModalProps) => {
  const { t } = useTranslation();
  const [getInvoiceUrlById] = useLazyGetInvoiceUrlQuery();
  const token = getCookie('cu_token');
  const { data } = useGetPaymentSessionDetailsQuery(summary.paymentSessionUuid, {
    skip: !summary.paymentSessionUuid,
  });

  const summaries = data?.summaries ?? [];
  const consumption = summaries.find((service) => service.dimension === ServiceDimension.ENERGY);
  const siteCoordinates = site?.addressJson
    ? `${site?.addressJson?.lat},${site?.addressJson?.lng}`
    : undefined;

  const getDuration = () => {
    const durationMs = summaries.reduce((totalDuration, service) => {
      const { startTime, endTime } = service;
      const serviceDurationMs = (endTime ? moment(endTime) : moment()).diff(startTime);
      return totalDuration + serviceDurationMs;
    }, 0);

    return `${moment.duration(durationMs).hours()}h ${moment.duration(durationMs).minutes()}m`;
  };

  const handleDownloadInvoice = async () => {
    try {
      const url = await getInvoiceUrlById(summary.invoiceId).unwrap();
      if (!url.includes('invoices/pdf?invoiceNumber=')) {
        window.open(url, '_blank');
      } else {
        const response = await fetch(`${getApiUrl()}${url}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
            Authorization: `Bearer ${token}`,
          },
        });
        const downloadUrl = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${summary.invoiceId}.pdf`;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      header={
        <Box display="flex" flexDirection="column">
          <Typography variant="h3">{summary.siteName}</Typography>
          <Typography variant="p14" color="#999898">
            {format(new Date(summary.startTime), 'dd MMM, HH:mm')}
          </Typography>
        </Box>
      }
      open={!!summary}
      onClose={onClose}
    >
      {siteCoordinates && (
        <Box position="relative" display="flex" justifyContent="center" alignItems="center">
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${siteCoordinates}&zoom=15&size=600x300&maptype=roadmap&map_id=cf767194ca2b13e0&key=${googleApiKey}`}
            alt="Map"
            style={{ borderRadius: '20px' }}
          />
          <img
            src={staticMarker}
            alt="marker"
            style={{
              position: 'absolute',
              top: 'calc(50% - 23px)',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          />
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap={2} mt={3}>
        <Box display="flex" flexDirection="column" gap={1}>
          {/* <InvoiceItem
          label={t('vehicle', 'Vehicle')}
          value={
            transaction.carBrand && transaction.carModel
              ? `${transaction.carBrand} ${transaction.carModel}`
              : transaction.carName ?? t('unknownCar', 'Unknown car')
          }
        /> */}
          <SummaryItem label={t('duration', 'Duration')} value={getDuration()} />
          {consumption && (
            <SummaryItem
              label={t('consumption', 'Consumption')}
              value={`${(consumption.volume / 1000).toFixed(2)} kWh`}
            />
          )}
          <SummaryDetails currencySymbol={summary.currencySymbol} services={summaries} />
        </Box>
        <Divider sx={{ height: '2px' }} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="p18">{t('total', 'Total')}</Typography>
          <Typography variant="p18">
            {`${(Math.ceil(summary.monetaryRevenueWithVat * 100) / 100).toFixed(2)} ${
              summary.currencySymbol
            }`}
          </Typography>
        </Box>
      </Box>
      {summary.invoiceId && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="48px"
          borderRadius="150px"
          mt={3}
          sx={{ backgroundColor: '#D6D6D6', cursor: 'pointer' }}
          onClick={handleDownloadInvoice}
        >
          <Typography variant="p16b">{t('downloadInvoice', 'Download invoice')}</Typography>
        </Box>
      )}
    </Modal>
  );
};
