import { TextField, styled } from '@mui/material';

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#999898',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D6D6D6',
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#F4F4F4',
    },
    '&.Mui-error fieldset': {
      color: '#EB4E20',
      borderColor: '#EB4E20',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
    borderRadius: '8px',
    borderColor: '#D6D6D6',
  },
  '& .MuiInputLabel-shrink': {
    color: '#999898',
    transform: 'translate(14px, -16px) !important',
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 6px)',
    '&.Mui-focused': {
      color: '#5E5E5E',
    },
    '&.Mui-disabled': {
      color: '#D6D6D6',
    },
    '&.Mui-error': {
      color: '#EB4E20',
    },
  },
  '& .MuiFormHelperText-root': {
    color: '#EB4E20',
    '&.Mui-error': {
      color: '#EB4E20',
    },
  },
});
