import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Global } from '@emotion/react';

import { SwipeButton } from 'components/atoms/SwipeButton';
import { ReserveChargerButton } from 'components/reserveCharger/ReserveChargerButton';
import { format } from 'date-fns';
import { getEvseActiveCharge, getEvseStatus } from 'handlers/device/device.handler';
import { ConnectorComputedStatus } from 'models/device.enums';
import { Evse } from 'models/evse';
import { Site } from 'models/site';
import { TransactionStatus } from 'models/transaction';
import { selectUser } from 'services/auth/selectors';
import { useGetPaymentMethodsQuery } from 'services/billing/endpoints';
import { selectFooterNotification } from 'services/notifications/selectors';
import {
  selectActiveParking,
  selectActiveReservation,
} from 'services/parkingAndReservations/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { AddPaymentMethodModal } from '../Billing/AddPaymentMethodModal';
import { ChargeIntroMessage } from './ChargeIntroMessage';
import { ChargerInfoMessage } from './ChargerInfoMessage';

type ChargerActionContainerProps = {
  site: Site;
  evse: Evse;
  startChargeHandler: () => void;
  stopChargeHandler: () => void;
  buttonLoading: boolean;
  onMessage: (message: string) => void;
};

export const ChargerActionContainer = ({
  site,
  evse,
  startChargeHandler,
  stopChargeHandler,
  buttonLoading,
  onMessage,
}: ChargerActionContainerProps) => {
  const { t } = useTranslation();
  const [paymentsModalOpen, setPaymentsModalOpen] = useState(false);
  const activeTransaction = useSelector(selectActiveTransaction);
  const activeCharge = getEvseActiveCharge(evse, activeTransaction);
  const activeReservation = useSelector(selectActiveReservation);
  const status = getEvseStatus(evse, activeTransaction, activeReservation);
  const hasAnotherChargeInProgress = activeTransaction !== undefined && !activeCharge;
  const user = useSelector(selectUser);
  const footerNotification = useSelector(selectFooterNotification);
  const activeParking = useSelector(selectActiveParking);
  const { data: paymentMethods } = useGetPaymentMethodsQuery();

  return (
    <div>
      <Global
        styles={{
          '.vool-charger-footer': {
            paddingBottom: '24px',
            '@media (max-width: 768px)': {
              paddingBottom: `${16 + footerNotification.height}px`,
            },
          },
        }}
      />
      <div className="vool-charger-footer px-4 sm:px-6 w-full flex flex-col rounded-t-3xl shadow pt-6">
        {hasAnotherChargeInProgress && (
          <ChargerInfoMessage
            message={t(
              'chargeInProgressDescription',
              'Charging session already running at another charger',
            )}
          />
        )}

        {status === ConnectorComputedStatus.AVAILABLE &&
          !hasAnotherChargeInProgress &&
          activeTransaction?.status !== TransactionStatus.Pending && (
            <ChargerInfoMessage
              message={`${t(
                'statusMessage',
                'You need to plug-in the charger, to start charging',
              )} 🔌`}
            />
          )}

        {activeParking?.deviceUuid && (
          <ChargerInfoMessage
            message={`${t(
              'parkingInfoMessage',
              'Please disconnect cable to finish session. Parking started at',
            )}`}
          >{` ${format(new Date(activeParking.startTime), 'HH:mm')}.`}</ChargerInfoMessage>
        )}

        <div className="flex flex-col space-y-2 w-full">
          {!activeCharge ? (
            <SwipeButton
              className="bg-vool-green"
              text={t('startCharging', 'Start charging')}
              disabled={status !== ConnectorComputedStatus.PLUGGED_IN || hasAnotherChargeInProgress}
              loading={buttonLoading || paymentsModalOpen}
              onSuccess={() => {
                paymentMethods?.length ? startChargeHandler() : setPaymentsModalOpen(true);
              }}
            />
          ) : (
            <SwipeButton
              className="bg-primary"
              text={
                activeTransaction?.status === TransactionStatus.Pending
                  ? t('cancel', 'Cancel')
                  : t('stopCharging', 'Stop charging')
              }
              disabled={status === ConnectorComputedStatus.OFFLINE}
              loading={buttonLoading}
              onSuccess={stopChargeHandler}
            />
          )}
          {!activeCharge && evse && (
            <ReserveChargerButton
              user={user}
              status={status}
              evse={evse}
              site={site}
              onMessage={(message) => onMessage(message)}
              hasPaymentMethods={!!paymentMethods?.length}
            />
          )}
          <ChargeIntroMessage hasPaymentMethods={!!paymentMethods?.length} site={site} />
        </div>
      </div>
      <AddPaymentMethodModal
        open={paymentsModalOpen}
        closeModal={() => {
          setPaymentsModalOpen(false);
        }}
        onSuccess={startChargeHandler}
      />
    </div>
  );
};
