import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { selectPortalLogo } from 'services/portal/selectors';

const useStyles = createUseStyles({
  wrapper: {
    background: '#131216',
    minWidth: 220,
    minHeight: 70,
  },
  image: {
    maxHeight: 45,
    width: '100%',
    maxWidth: 76,
  },
  imageLarge: {
    maxWidth: 100,
    width: '100%',
  },
});

type LogoProps = {
  navigateTo?: string;
};

export const Logo = ({ navigateTo }: LogoProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { filename, url } = useSelector(selectPortalLogo);
  const logo = filename ? require(`app/images/${filename}`) : url;

  return (
    <button
      type="button"
      {...(navigateTo && {
        onClick: () => navigate(navigateTo),
      })}
      className={url ? 'py-3' : 'py-6'}
    >
      <img
        className={classNames([url ? classes.imageLarge : classes.image])}
        src={logo}
        alt="logo"
      />
    </button>
  );
};
