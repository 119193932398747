import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { ReactComponent as ArrowRightIcon } from 'app/images/icons/arrowRight.svg';
import { ReactComponent as PaymentMethodIcon } from 'app/images/icons/paymentMethod.svg';
import { GrayCard } from 'components/atoms/GrayCard';
import { capitalize } from 'lodash';
import {
  useGetPaymentMethodsQuery,
  useSetPaymentMethodAsDefaultMutation,
} from 'services/billing/endpoints';
import { selectDefaultPaymentMethod } from 'services/billing/selectors';
import {
  selectActiveParking,
  selectActiveReservation,
} from 'services/parkingAndReservations/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { PaymentMethodsModal } from './PaymentMethodsModal';

export const PaymentMethodCard = () => {
  const { t } = useTranslation();
  const {
    isLoading: paymentMethodsLoading,
    isFetching: paymentMethodsFetching,
    data: paymentMethods,
  } = useGetPaymentMethodsQuery();
  const defaultPaymentMethod = useSelector(selectDefaultPaymentMethod);
  const [modalOpen, setModalOpen] = useState(false);
  const [setDefault, { isLoading: setDefaultLoading }] = useSetPaymentMethodAsDefaultMutation();
  const activeCharge = useSelector(selectActiveTransaction);
  const activeReservation = useSelector(selectActiveReservation);
  const activeParking = useSelector(selectActiveParking);
  const disabled = !!activeCharge || !!activeReservation || !!activeParking;

  const handleClick = () => {
    if (modalOpen || disabled) return;
    setModalOpen(true);
  };

  const handlePaymentMethodClick = (id: string) => {
    if (!setDefaultLoading && id !== defaultPaymentMethod?.id && !paymentMethodsFetching)
      setDefault(id);
  };

  const getIconColor = () => {
    if (disabled) return '#D6D6D6';
    if (defaultPaymentMethod) return '#EB4E20';
    return '#999898';
  };

  const getSubtitleColor = () => {
    if (disabled) return '#D6D6D6';
    if (defaultPaymentMethod) return '#282828';
    return '#999898';
  };

  useEffect(() => {
    if (modalOpen && paymentMethods?.length === 0) {
      setModalOpen(false);
    }

    if (paymentMethods?.length && !defaultPaymentMethod) {
      setDefault(paymentMethods[0].id);
    }
  }, [paymentMethods]);

  return (
    <GrayCard
      display="flex"
      alignItems="center"
      gap={2}
      onClick={handleClick}
      loading={paymentMethodsLoading}
    >
      <PaymentMethodIcon color={getIconColor()} />
      <Box display="flex" flexDirection="column" flex={1}>
        <Typography variant="p16" color={disabled ? '#999898' : '#282828'}>
          {capitalize(
            defaultPaymentMethod
              ? defaultPaymentMethod?.card.brand
              : t('noPaymentsFound', 'No payment methods found'),
          )}
        </Typography>
        <Typography variant="p14" color={getSubtitleColor()}>
          {defaultPaymentMethod
            ? `**** ${defaultPaymentMethod?.card.last4}`
            : t('addPaymentMethod', 'Add payment method')}
        </Typography>
      </Box>
      <ArrowRightIcon color={disabled ? '#D6D6D6' : '#282828'} />
      <PaymentMethodsModal
        open={modalOpen}
        paymentMethods={paymentMethods}
        onPaymentMethodClick={handlePaymentMethodClick}
        closeModal={() => setModalOpen(false)}
      />
    </GrayCard>
  );
};
