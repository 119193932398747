import Ccs from 'app/images/icons/connectors/ccs.svg';
import Chademo from 'app/images/icons/connectors/chademo.svg';
import Type1 from 'app/images/icons/connectors/type1.svg';
import Type2 from 'app/images/icons/connectors/type2.svg';
import Unknown from 'app/images/icons/connectors/unknown.svg';
import { ConnectorType } from 'models/device.enums';

type ConnectorIconProps = {
  type: ConnectorType;
};

export const ConnectorIcon = ({ type }: ConnectorIconProps) => {
  const icons: any = {
    [ConnectorType.CCS]: Ccs,
    [ConnectorType.CHADEMO]: Chademo,
    [ConnectorType.TYPE_1]: Type1,
    [ConnectorType.TYPE_2]: Type2,
  };

  return (
    <img
      src={icons[ConnectorType[type as keyof typeof ConnectorType]] || Unknown}
      alt={type}
      sizes="20px"
      className="ml-2"
    />
  );
};
