import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AvailabilityState {
  available: boolean;
  busy: boolean;
  unlisted: boolean;
}

interface FiltersState {
  open: boolean;
  availability: AvailabilityState;
}

const initialState: FiltersState = {
  open: false,
  availability: {
    available: true,
    busy: true,
    unlisted: true,
  },
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setAvailability: (state, action: PayloadAction<AvailabilityState>) => {
      state.availability = action.payload;
    },
    toggleFiltersOpen: (state) => {
      state.open = !state.open;
    },
    resetFilters: (state) => {
      state.availability = initialState.availability;
    },
  },
});

export const { setAvailability, toggleFiltersOpen, resetFilters } = filtersSlice.actions;
export default filtersSlice.reducer;
