import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Global } from '@emotion/react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { mdiCrosshairsGps } from '@mdi/js';
import Icon from '@mdi/react';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { IconButton } from '@mui/material';

import { ReactComponent as RoadMapIcon } from 'app/images/icons/roadMap.svg';
import { ReactComponent as SatelliteMapIcon } from 'app/images/icons/satelliteMap.svg';
import userLocationIcon from 'app/images/icons/userLocation.svg';
import { Alert } from 'app/views/Notifications/Alert';
import { Spinner } from 'components/atoms/Spinner';
import { googleApiKey } from 'config/consts';
import GoogleMapReact from 'google-map-react';
import { Site } from 'models/site';
import { toggleFiltersOpen } from 'services/filters';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { selectBottomSheetHeight } from 'services/portal/selectors';
import { setActiveEvse } from 'services/sites';
import { updateActiveSite, updateSitesDistance } from 'services/sites/actions';
import {
  selectActiveSite,
  selectPublicSites,
  selectPublicSitesFiltered,
} from 'services/sites/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { clusterRenderer, getSiteMarker } from './google-api';
import { mapStyle } from './mapStyle';

// Tallinn
const DEFAULT_CENTER = {
  lat: 59.43696079999999,
  lng: 24.7535746,
};
const DEFAULT_ZOOM = 14;

type GoogleMapProps = {
  destination?: google.maps.Place;
};

interface MapMarker {
  uuid: string;
  marker: google.maps.Marker;
}

export const GoogleMap = ({ destination }: GoogleMapProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
  const [currentPosition, setCurrentPosition] = useState<{ lat: number; lng: number }>();
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [geolocationStatus, setGeolocationStatus] = useState('');
  const [locationLoading, setLocationLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [center, setCenter] = useState<{ lat: number; lng: number }>();
  const [map, setMap] = useState<google.maps.Map>();
  const [mapType, setMapType] = useState('roadmap');
  const [markerClusterer, setMarkerClusterer] = useState<MarkerClusterer>();
  const [userMarker, setUserMarker] = useState<google.maps.Marker>();
  const [markers, setMarkers] = useState<MapMarker[]>([]);

  const sites = useSelector(selectPublicSites);
  const activeSite = useSelector(selectActiveSite);
  const filteredSites = useSelector(selectPublicSitesFiltered);
  const bottomSheetHeight = useSelector(selectBottomSheetHeight);
  const activeTransaction = useSelector(selectActiveTransaction);
  const activeReservation = useSelector(selectActiveReservation);

  const createNewMapMarker = (site: Site): MapMarker => {
    const marker = new google.maps.Marker({
      icon: getSiteMarker(site, activeTransaction, activeReservation),
      position: site.addressJson as google.maps.LatLng,
    });
    marker.addListener('click', () => {
      dispatch(updateActiveSite(site));
      dispatch(setActiveEvse(undefined));
      navigate({ search: '' });
    });
    return { uuid: site.uuid, marker };
  };

  useEffect(() => {
    if (markerClusterer) {
      filteredSites.forEach((site) => {
        const existingMarkerIndex = markers.findIndex((m) => m.uuid === site.uuid);
        const existingMarker = markers[existingMarkerIndex];
        if (!existingMarker) {
          const mapMarker = createNewMapMarker(site);
          setMarkers((prev) => [...prev, mapMarker]);
          markerClusterer.addMarker(mapMarker.marker);
        } else {
          const icon = getSiteMarker(site, activeTransaction, activeReservation);
          if (icon !== existingMarker.marker.getIcon()) {
            existingMarker.marker.setIcon(icon);
            markerClusterer.removeMarker(existingMarker.marker);
            markerClusterer.addMarker(existingMarker.marker);
            setMarkers((prev) => [
              ...prev.slice(0, existingMarkerIndex),
              existingMarker,
              ...prev.slice(existingMarkerIndex + 1),
            ]);
          }
        }
      });

      // remove filtered sites that are not in the list anymore
      markers.forEach((mapMarker) => {
        const existingSite = filteredSites.find((s) => s.uuid === mapMarker.uuid);
        if (!existingSite) {
          markerClusterer.removeMarker(mapMarker.marker);
          setMarkers((prev) => prev.filter((m) => m.uuid !== mapMarker.uuid));
        }
      });
    }
  }, [filteredSites, markerClusterer]);

  const getDistances = (cp: { lat: number; lng: number }) => {
    const destinations = sites.map((s) => s.addressJson as google.maps.LatLng);
    if (!destinations.length) return;

    const service = new google.maps.DistanceMatrixService();
    const chunkSize = 25;
    const chunks = Array.from({ length: Math.ceil(destinations.length / chunkSize) }, (_, i) =>
      destinations.slice(i * chunkSize, i * chunkSize + chunkSize),
    );

    const allDistances: google.maps.DistanceMatrixResponseElement[] = [];

    const processChunk = (chunkIndex: number) => {
      if (chunkIndex >= chunks.length) {
        dispatch(
          updateSitesDistance(
            sites.map((site, index) => ({ ...site, distance: allDistances[index].distance })),
          ),
        );
        return;
      }

      service.getDistanceMatrix(
        {
          origins: [cp],
          destinations: chunks[chunkIndex],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK && response) {
            const [row] = response.rows;
            allDistances.push(...row.elements);
            processChunk(chunkIndex + 1);
          }
        },
      );
    };

    processChunk(0);
  };

  useEffect(() => {
    if (currentPosition) {
      getDistances(currentPosition);
    }
  }, [sites.length]);

  useEffect(() => {
    if (!userMarker && currentPosition) {
      setUserMarker(
        new google.maps.Marker({
          map,
          icon: userLocationIcon,
          position: currentPosition,
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + sites.length,
          clickable: false,
        }),
      );
    } else if (map && userMarker && currentPosition) {
      userMarker.setPosition(currentPosition);
    }
  }, [currentPosition]);

  const setCurrentLocation = (errorCallback: () => void) => {
    if (navigator.geolocation) {
      setLocationLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          getDistances(pos);
          setGeolocationStatus('');
          setCurrentPosition(pos);
          setCenter(undefined);
          setCenter(pos);
          setZoom(DEFAULT_ZOOM);
          setLocationLoading(false);
        },
        ({ message }) => {
          setGeolocationStatus(message);
          setLocationLoading(false);
          errorCallback?.();
        },
      );
    }
  };

  const centerMarker = (addressJson: any) => {
    setCenter({ lat: addressJson?.lat, lng: addressJson?.lng });
    setZoom((prev) => Math.max(prev, DEFAULT_ZOOM));
  };

  const setDestinationToNavigate = (
    newDestination: google.maps.Place,
    errorCallback: () => void,
  ) => {
    if (!directionsRenderer || !map) return;
    const directionsService = new window.google.maps.DirectionsService();
    directionsRenderer.setMap(null);
    directionsRenderer.setMap(map);
    directionsRenderer.setOptions({ suppressMarkers: true });
    directionsService.route(
      {
        origin: currentPosition ?? DEFAULT_CENTER,
        destination: newDestination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        } else {
          setGeolocationStatus(`Something went wrong! Please try again: ${result}`);
          errorCallback();
        }
      },
    );
  };

  useEffect(() => {
    if (activeSite?.addressJson) {
      centerMarker(activeSite.addressJson);
    }
  }, [activeSite]);

  useEffect(() => {
    if (destination) {
      setDestinationToNavigate(destination, () => setAlert(true));
    }
  }, [destination]);

  return (
    <div className="relative w-full h-full">
      {alert && geolocationStatus && (
        <div className="absolute top-0 w-full z-10">
          <Alert content={geolocationStatus} close={() => setAlert(false)} />
        </div>
      )}
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: googleApiKey }}
        defaultCenter={DEFAULT_CENTER}
        defaultZoom={DEFAULT_ZOOM}
        zoom={zoom}
        center={center}
        onDrag={() => {
          setCenter(undefined);
        }}
        onGoogleApiLoaded={({ map }) => {
          if (!activeSite) {
            setCurrentLocation(() => setAlert(true));
          }
          setDirectionsRenderer(new google.maps.DirectionsRenderer());
          setMap(map);
          setMarkerClusterer(new MarkerClusterer({ map, renderer: clusterRenderer }));
        }}
        onZoomAnimationEnd={(z) => setZoom(z)}
        options={{
          fullscreenControl: false,
          clickableIcons: false,
          draggable: true,
          styles: mapStyle,
          gestureHandling: 'greedy',
          mapTypeId: mapType,
        }}
      >
        <Global
          styles={{
            '.vool-map-buttons,.gm-bundled-control-on-bottom': {
              '@media only screen and (max-width: 568px)': {
                bottom: `${(bottomSheetHeight ?? 0) + 90}px !important`,
              },
            },
            '.vool-map-buttons': {
              right: 1,
            },
          }}
        />
      </GoogleMapReact>
      <div className="vool-map-buttons absolute sm:bottom-24 space-y-2 pr-1 flex flex-col items-center justify-center pb-3">
        <IconButton
          color="inherit"
          className="shadow-sm w-12 h-12"
          disableRipple
          onClick={() => dispatch(toggleFiltersOpen())}
          sx={{ backgroundColor: 'white' }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
        <button
          onClick={() => {
            setCurrentLocation(() => setAlert(true));
          }}
          type="button"
          className="w-12 h-12 bg-white rounded-full flex items-center justify-center shadow-sm"
        >
          {locationLoading ? (
            <Spinner size={4} backgroundColor="bg-transparent" />
          ) : (
            <Icon path={mdiCrosshairsGps} size="24px" />
          )}
        </button>
        <button
          type="button"
          onClick={() => {
            setMapType((prev) => (prev === 'roadmap' ? 'satellite' : 'roadmap'));
          }}
        >
          {mapType === 'roadmap' ? <SatelliteMapIcon /> : <RoadMapIcon />}
        </button>
      </div>
    </div>
  );
};
