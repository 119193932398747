import { DataHandler } from 'handlers/data.handler';
import { Car } from 'models/car';
import { updateVehicleFromPusher } from 'services/vehicles/actions';

interface VehicleData {
  fieldName: string;
  fieldValue: string | number | boolean;
  current: Car;
}

interface MetaData {
  fieldName: string;
  fieldValue: string;
}

export class PusherVehicleDataHandler {
  vehicleData: VehicleData;
  meta: MetaData;

  constructor({ data, meta }: { data: VehicleData; meta: MetaData }) {
    this.vehicleData = data;
    this.meta = meta;
  }

  updateCar() {
    const vehicleData = DataHandler.cleanDataObject({
      ...this.vehicleData.current,
      [this.meta.fieldName]: this.meta.fieldValue,
    });
    return [updateVehicleFromPusher(vehicleData)];
  }
}
