import classNames from 'classnames';

type GridProps = {
  children: React.ReactNode;
  maxCol?: number;
  className?: string;
};

export const Grid = ({ children, maxCol = 2, className = '' }: GridProps) => (
  <div
    className={classNames(
      'grid',
      'grid-cols-1',
      'gap-4',
      { 'sm:grid-cols-1': maxCol === 1 },
      { 'sm:grid-cols-1': maxCol === 2 || maxCol === 3 },
      { 'md:grid-cols-1': maxCol === 2 },
      { 'md:grid-cols-1': maxCol === 3 },
      { 'lg:grid-cols-2': maxCol === 3 },
      { 'lg:grid-cols-2': maxCol === 2 },
      { 'xl:grid-cols-3': maxCol === 3 },
      { [className]: true },
    )}
  >
    {children}
  </div>
);
