import { createAction } from '@reduxjs/toolkit';

import { Device } from 'models/device';
import { Site } from 'models/site';

export const updatePublicDeviceFromPusher = createAction<{ device: Device; partial: boolean }>(
  'sites/updatePublicDeviceFromPusher',
);

export const updateSitesDistance = createAction<Site[]>('sites/updateSitesDistance');

export const updateActiveSite = createAction<Site | undefined>('sites/updateActiveSite');

export const updateActiveSiteByUuid = createAction<string>('sites/updateActiveSiteByUuid');
