import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, useMediaQuery } from '@mui/material';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';
import costIcon from 'app/images/icons/costPrimary.svg';
import timePrimaryIcon from 'app/images/icons/timePrimary.svg';
import { PaymentSessionsListSkeleton } from 'components/atoms/PaymentSessionsListSkeleton';
import { addMonths, subMonths } from 'date-fns';
import { useCurrency } from 'hooks/use-currency';
import { sumBy } from 'lodash';
import { PaymentSessionSummary } from 'models/billing';
import moment from 'moment';
import { useLazyPostPaymentSessionSummariesQuery } from 'services/billing/endpoints';
import { selectFooterNotification } from 'services/notifications/selectors';

import { BillingDetailsCard } from '../Billing/BillingDetailsCard';
import { PaymentMethodCard } from '../Billing/PaymentMethodCard';
import { ChargingHistoryList } from './ChargingHistoryList';
import { NoPaymentSessions } from './NoPaymentSessions';
import { SummaryCardSkeleton } from './SummaryCardSkeleton';

export const ChargingHistory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currency = useCurrency();
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const isMobile = useMediaQuery('(max-width: 768px)');
  const footerNotification = useSelector(selectFooterNotification);
  const [fetchSummaries, { isFetching }] = useLazyPostPaymentSessionSummariesQuery();
  const [summaries, setSummaries] = useState<PaymentSessionSummary[]>([]);

  const updateSelectedMonthSummaries = async () => {
    try {
      const response = await fetchSummaries({
        queryRange: {
          startDate: moment(selectedMonth).startOf('month').format(),
          endDate: moment(selectedMonth).endOf('month').format(),
        },
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sortOrder: {
          column: 'startTime',
          order: 'desc',
        },
      }).unwrap();

      setSummaries(response);
    } catch (error) {
      console.error('Error fetching summaries', error);
    }
  };

  useEffect(() => {
    updateSelectedMonthSummaries();
  }, [selectedMonth]);

  const selectPreviousMonth = () => {
    setSelectedMonth(subMonths(selectedMonth, 1));
  };

  const selectNextMonth = () => {
    setSelectedMonth(addMonths(selectedMonth, 1));
  };

  const totalRevenueWithVAT = sumBy(summaries, (summary) => summary.monetaryRevenueWithVat);

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row items-center pb-8">
        <button type="button" className="h-10 w-10" onClick={() => navigate('/sites')}>
          <img src={arrowRightIcon} style={{ transform: 'scaleX(-1)' }} alt="Go back" />
        </button>
        <h3 className="text-2xl leading-6 font-normal text-app-header-link">
          {t('chargingHistory', 'Charging history')}
        </h3>
      </div>
      <div className="w-full sm:justify-center flex items-center justify-between">
        <button
          type="button"
          className="h-8 w-8 flex items-center justify-center bg-vool-gray-100 rotate-180 border border-vool-gray-100 rounded-full"
          onClick={selectPreviousMonth}
        >
          <img src={arrowRightIcon} alt="previous" className="w-6 h-6" />
        </button>
        <div className="mx-4">{moment(selectedMonth).format('MMMM YYYY')}</div>
        <button
          type="button"
          className="h-8 w-8 flex items-center justify-center bg-vool-gray-100 border border-vool-gray-100 rounded-full"
          onClick={selectNextMonth}
        >
          <img src={arrowRightIcon} alt="next" className="w-6 h-6" />
        </button>
      </div>
      {!isMobile ? (
        <div className="font-semibold text-lg pt-10 pb-4">{t('overview', 'Overview')}</div>
      ) : (
        <div className="font-semibold text-lg pt-10 pb-4">
          {moment(selectedMonth).format('MMMM')} {t('overview', 'Overview')}
        </div>
      )}
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-3 gap-y-3">
        {/* <div className="bg-vool-gray-100 h-[152px] rounded-2xl">
          <div className="p-4">
            <img src={energySuppliedIcon} alt="energy" />
          </div>
          <div className="pl-4 pt-2">
            <div>
              <div className="text-base">kwh</div>
              <div className="text-2xl -m-0.5">{totalAmount.toFixed(2)}</div>
              <div className="text-sm text-vool-gray-400 font-light">
                {t('consumption', 'Consumption')}
              </div>
            </div>
          </div>
        </div> */}
        {isFetching ? (
          Array.from(Array(2).keys()).map((i) => <SummaryCardSkeleton key={i} withCurrency={!i} />)
        ) : (
          <>
            <div className="bg-vool-gray-100 h-[152px] rounded-2xl">
              <div className="p-4">
                <img src={costIcon} alt="cost" />
              </div>
              <div className="pl-4 pt-2">
                <div className="text-base">{currency.symbol}</div>
                <div className="text-2xl -m-0.5"> {totalRevenueWithVAT?.toFixed(2)}</div>
                <div className="text-sm text-vool-gray-400 font-light">{t('cost', 'Cost')}</div>
              </div>
            </div>
            <div className="bg-vool-gray-100 h-[152px] rounded-2xl">
              <div className="p-4">
                <img src={timePrimaryIcon} alt="time" />
              </div>
              <div className="pl-4 pt-7 ">
                <div className="text-2xl -m-0.5">{summaries?.length}</div>
                <div className="text-sm text-vool-gray-400 font-light">
                  {summaries?.length === 1 ? t('session', 'Session') : t('sessions', 'Sessions')}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Box display="flex" flexDirection="column" gap={2} mt={4}>
        <Typography variant="p18">{t('paymentMethod', 'Payment method')}</Typography>
        <PaymentMethodCard />
        <BillingDetailsCard />
      </Box>
      <Typography mt={5} variant="p18">
        {t('history', 'History')}
      </Typography>
      <Box mb={`${16 + footerNotification.height}px`}>
        {isFetching && <PaymentSessionsListSkeleton />}
        {!isFetching && !summaries.length && <NoPaymentSessions />}
        {!isFetching && !!summaries.length && <ChargingHistoryList summaries={summaries} />}
      </Box>
    </div>
  );
};
