import { Box, Typography } from '@mui/material';

type ModalControlProps = {
  Icon: React.FunctionComponent;
  label: string;
  onClick: () => void;
};

export const ModalControl = ({ Icon, label, onClick }: ModalControlProps) => (
  <Box display="flex" flexDirection="row" gap={2} onClick={onClick} sx={{ cursor: 'pointer' }}>
    <Icon />
    <Typography variant="p16">{label}</Typography>
  </Box>
);
