import { Country } from 'models/country';
import { splitApi } from 'services/api';

import { CountriesResponse } from './responses';

const extendedApi = splitApi.injectEndpoints({
  endpoints: (builder) => ({
    countries: builder.query<Country[], void>({
      query: () => ({
        url: '/countries',
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60,
      transformResponse: (response: CountriesResponse) => response.data,
    }),
  }),
});

export const { useCountriesQuery } = extendedApi;

export const countriesApi = extendedApi;
