import { useState } from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useTranslation } from 'react-i18next';

import { Box, ButtonBase, useMediaQuery } from '@mui/material';

import googleIcon from 'app/images/icons/google.svg';
import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { googleLoginUrl } from 'config/consts';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForUser } from 'services/amplitude/data';
import { useGoogleLoginMutation } from 'services/auth/endpoints';

type GoogleAuthProps = {
  closeModal: () => void;
  scaleForMobile?: boolean;
};

export const GoogleAuth = ({ closeModal, scaleForMobile = true }: GoogleAuthProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  scaleForMobile = scaleForMobile && isMobile;
  const [googleLogin] = useGoogleLoginMutation();
  const [passwordNeeded, setPasswordNeeded] = useState(false);
  const [googleLoginError, setGoogleLoginError] = useState<string>();
  const [googleEmail, setGoogleEmail] = useState();
  const [password, setPassword] = useState<string>();
  const googleAuth = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (!('profileObj' in response)) return;
    const { profileObj, tokenId: idToken } = response;
    setGoogleLoginError(undefined);
    setPassword(undefined);

    const { givenName, familyName, email }: any = profileObj;
    if (email) {
      setGoogleEmail(email);
    }

    try {
      const response = await googleLogin({
        name: `${givenName || ''} ${familyName || ''}`,
        email: email || googleEmail,
        password,
        idToken,
      });

      // TODO: Fix error handling
      if ('error' in response) {
        const loginError = response as any;
        if (loginError?.data?.message === 'Password needed') {
          setPasswordNeeded(true);
        }

        if (loginError?.data?.message === 'Login failed') {
          setGoogleLoginError('Entered password is incorrect');
        }
      } else {
        closeModal();
        // TODO: Fix this hack
        const responseData = response?.data as any;
        trackAmplitudeEvent(AMPLITUDE_EVENTS.LOGIN, {
          ...getAmplitudeEventPropertiesForUser(responseData?.user),
          method: 'Google',
        });
      }
    } catch (e: any) {
      setGoogleLoginError(e);
    }
  };

  return (
    <>
      {googleLoginError && (
        <>
          <div className="text-red-500 text-sm mt-3 font-medium">
            {t('somethingWentWrong', 'Something went wrong.')}
          </div>
          <div className="text-gray-500 text-xs mb-3">{`Error: ${googleLoginError}`}</div>
        </>
      )}
      <GoogleLogin
        render={(renderProps) => (
          <Box display="flex" justifyContent="center">
            <ButtonBase
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className={`h-14 ${scaleForMobile ? 'w-14' : 'w-full'}`}
              sx={{ backgroundColor: '#D6D6D6', borderRadius: '150px' }}
            >
              <img src={googleIcon} alt="google" />
              <span
                className={`
              w-auto font-sans text-base font-semibold text-raisingBlack text-app-header-link md:flex
              ${scaleForMobile ? 'hidden' : ''}
            `}
              >
                {scaleForMobile
                  ? t('loginWithGoogle', 'Login with Google')
                  : t('continueWithGoogle', 'Continue with Google')}
              </span>
            </ButtonBase>
          </Box>
        )}
        disabled={passwordNeeded}
        clientId={googleLoginUrl}
        onSuccess={googleAuth}
        // onFailure={(response) => { debugger }}
        cookiePolicy="single_host_origin"
      />
      {passwordNeeded && (
        <div className="w-full">
          <div className="text-xs text-gray-700 mt-4 mb-3 font-medium">
            {`
              Existing account found with your Google email.
              To confirm that this is your account, please enter account's password:
            `}
          </div>
          <Input
            name="password"
            type="password"
            error={!!googleLoginError}
            label="Password"
            value={password}
            onChange={({ target: { value } }) => {
              setGoogleLoginError(undefined);
              setPassword(value);
            }}
          />
          {/** TODO: Should this trigger google auth? */}
          <Button disabled={!password} className="w-full mt-3">
            Confirm
          </Button>
          <button
            type="button"
            className="w-full mt-3 text-xs text-gray-500"
            onClick={() => {
              setPasswordNeeded(false);
              setPassword(undefined);
              setGoogleLoginError(undefined);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
};
