import { useTranslation } from 'react-i18next';

import { Logo } from 'app/layouts/LoggedinBasicLayout/Logo';

export const EmptyView = () => {
  const { t } = useTranslation();
  return (
    <div className="p-5 flex flex-col items-center text-center mt-20">
      <Logo navigateTo="/sites" />
      <div className="text-lg font-medium mt-6">
        {t('noChargingSitesAvailable', 'No charging sites available.')}
      </div>
      <div className="text-gray-500 text-sm mt-2">
        {t(
          'wantToHaveYourOwnChargersHereAvailable',
          'Want to have your own chargers here available?',
        )}
        <br />
        <button className="underline" type="button">
          {t('letUsKnow', 'Let us know.')}
        </button>
      </div>
    </div>
  );
};
