import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getEvseActiveCharge } from 'handlers/device/device.handler';
import { Evse } from 'models/evse';
import { Site } from 'models/site';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { SinglePublicCharger } from '../Charger/SinglePublicCharger';

type ChargersListingProps = {
  title: string;
  evseList: Evse[];
  site: Site;
  setActiveEvseIfAvailable: (evse: Evse) => void;
};

export const ChargersListing = ({
  title,
  evseList,
  site,
  setActiveEvseIfAvailable,
}: ChargersListingProps) => {
  const activeTransaction = useSelector(selectActiveTransaction);
  const isListHidden = !evseList.length;

  const [evsesOrdered, setEvsesOrdered] = useState(evseList);

  const chargersOrderedByActiveCharge = (evses: Evse[]) =>
    [...evses].sort((a, b) => {
      const aHasActiveCharge = getEvseActiveCharge(a, activeTransaction);
      const bHasActiveCharge = getEvseActiveCharge(b, activeTransaction);
      if (aHasActiveCharge && !bHasActiveCharge) {
        return -1;
      }
      if (!aHasActiveCharge && bHasActiveCharge) {
        return 1;
      }
      return 0;
    });

  useEffect(() => {
    setEvsesOrdered(chargersOrderedByActiveCharge(evseList));
  }, [evseList, activeTransaction]);

  return (
    <div className={`mb-6 space-y-2 ${isListHidden ? 'hidden' : ''}`}>
      {!!evsesOrdered.length && <span className="text-lg font-semibold">{title}</span>}
      <div className="space-y-4">
        {evsesOrdered.map((evse) => (
          <SinglePublicCharger
            site={site}
            key={evse.evseId}
            selectActiveEvse={setActiveEvseIfAvailable}
            evse={evse}
          />
        ))}
      </div>
    </div>
  );
};
