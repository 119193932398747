import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { ReactComponent as ArrowRightIcon } from 'app/images/icons/arrowRight.svg';
import { ReactComponent as FeedbackIcon } from 'app/images/icons/feedbackIcon.svg';

export const FeedbackCard = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      width={1}
      height="80px"
      p="12px 16px"
      gap={2}
      color={'#FFFFFF'}
      sx={{ backgroundColor: '#EB4E20', borderRadius: '20px', cursor: 'pointer' }}
      onClick={() => {
        window.open('https://support.vool.com/en', 'VoolHelpCenter', 'noopener');
      }}
    >
      <FeedbackIcon style={{ alignSelf: 'flex-start' }} />
      <Box display="flex" flexDirection="column" flex={1}>
        <Typography variant="p16b">{t('foundIssue?', 'Found issue?')}</Typography>
        <Typography variant="p12">
          {t('feedbackDescription', 'Be part of our journey towards an improved experience.')}
        </Typography>
      </Box>
      <ArrowRightIcon />
    </Box>
  );
};
