import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { Modal } from 'components/modals/Modal';

import { StripePayment } from './StripePayment';

type AddPaymentmethodModalProps = {
  open: boolean;
  closeModal: () => void;
  onSuccess: () => void;
};

export const AddPaymentMethodModal = ({
  open,
  closeModal,
  onSuccess,
}: AddPaymentmethodModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      header={<Typography variant="h3">{t('paymentMethod', 'Payment method')}</Typography>}
      open={open}
      onClose={closeModal}
      height={1}
    >
      <StripePayment
        onCancel={closeModal}
        onSuccess={() => {
          onSuccess();
          closeModal();
        }}
        accountlessUser
      />
    </Modal>
  );
};
