export type PaymentMethod = {
  id: string;
  card: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
  };
  type: string;
  defaultFlag: boolean;
};

export enum PaymentSessionStatus {
  ACTIVE = 'active',
  ENDED = 'ended',
}

export enum ServiceDimension {
  TIME = 'TIME',
  ENERGY = 'ENERGY',
  PARKING_TIME = 'PARKING_TIME',
  RESERVATION_TIME = 'RESERVATION_TIME',
}

export interface Service {
  amountInCents: number;
  amountInCentsWithVat: number;
  dimension: ServiceDimension;
  volume: number;
  description: string;
  startTime: Date;
  endTime?: Date;
  siteUuid: string;
}

export interface PaymentSession {
  id: number;
  userUuid: string;
  userEmail: string;
  companyUuid?: string;
  sessionUuid: string;
  status: PaymentSessionStatus;
  countryCode: string;
  bookingId?: string;
  invoiceId?: string;
  bookingStatus?: string;
  invoiceStatus?: string;
  customerId?: string;
  stripeConnectAccountId?: string;
  timezone?: string;
  addTime?: Date;
  updateTime?: Date;
}

export type Invoice = {
  id: number;
  invoiceNumber: string;
  paymentSessionUuid: string;
  stripePaymentIntent?: string;
  fromInfoJson: any;
  toInfoJson: any;
  monetaryCostTotal: number;
  monetaryCostTotalWithVat: number;
  currency: string;
  vat: number;
  status: string;
  itemsJson: Service[];
  dateIssued?: Date;
  dueDate?: Date;
  addTime: Date;
  updateTime?: Date;
};

export type PaymentSessionDetails = {
  paymentSession?: PaymentSession;
  summaries: Service[];
  invoice?: Invoice;
};

export type PaymentSessionSummary = {
  id: number;
  paymentSessionUuid?: string;
  startTime: Date;
  endTime?: Date;
  siteUuid?: string;
  siteName?: string;
  customerEmail: string;
  companyUuid?: string;
  /** Device UUID, named this way because of permissions logic */
  uuid?: string;
  monetaryRevenueWithVat: number;
  invoiceId: string;
  currencySymbol: string;
};
