import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dialog, Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/solid';

import check from 'app/images/icons/check.svg';
import exitIcon from 'app/images/icons/exitWithBackground.svg';
import { ReactComponent as PaymentMethodIcon } from 'app/images/icons/paymentMethod.svg';
import { useCurrency } from 'hooks/use-currency';
import { isFinite, isNaN } from 'lodash';
import { selectDefaultPaymentMethod } from 'services/billing/selectors';

import { Button } from '../../atoms/Button';
import { LineItem } from './LineItem';

type ThankYouModalProps = {
  lastChargingData: {
    lastCharging: {
      amountInCents: number;
      timeInMinutes: number;
      amount: number;
    };
    lastReservation: {
      amountInCents: number;
      timeInMinutes: number;
    };
    lastParking: {
      amountInCents: number;
      timeInMinutes: number;
    };
    hasParkingData: boolean;
    hasReservationData: boolean;
    currency: string;
  };
  closeModal: () => void;
  userName: string;
};

export const ThankYouModal = ({ lastChargingData, closeModal, userName }: ThankYouModalProps) => {
  const currency = useCurrency(lastChargingData.currency);
  const defaultPaymentMethod = useSelector(selectDefaultPaymentMethod);
  const lastChargingCost = lastChargingData?.lastCharging?.amountInCents;
  const lastChargingTime = lastChargingData?.lastCharging?.timeInMinutes;
  const lastChargingAmount = lastChargingData?.lastCharging?.amount;
  const lastReservationCost = lastChargingData.lastReservation?.amountInCents;
  const lastReservationTime = lastChargingData?.lastReservation?.timeInMinutes;
  const lastParkingCost = lastChargingData?.lastParking?.amountInCents;
  const lastParkingTime = lastChargingData?.lastParking?.timeInMinutes;
  const hasParkingData = lastChargingData?.hasParkingData;
  const hasReservationData = lastChargingData?.hasReservationData;

  const { t } = useTranslation();

  const calculateCostInEuros = (cost: number) => cost / 100;

  const chargingCost = calculateCostInEuros(lastChargingCost) || 0;
  const reservationCost = calculateCostInEuros(lastReservationCost) || 0;
  const parkingCost = calculateCostInEuros(lastParkingCost) || 0;
  const chargingAmountInKwh = lastChargingAmount / 1000 || 0;

  const totalCost = chargingCost + reservationCost + parkingCost || 0;

  const formatTime = (seconds: number) => {
    if (isNaN(seconds) || !isFinite(seconds)) {
      return '00:00:00';
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  const chargingTimeFormatted = formatTime(lastChargingTime);
  const reservationTimeFormatted = formatTime(lastReservationTime);
  const parkingTimeFormatted = formatTime(lastParkingTime);

  const lineItems: LineItem[] = [
    {
      title: t('charging_noun', 'Charging'),
      cost: chargingCost,
      details: [
        { type: t('time', 'Time'), amount: chargingTimeFormatted },
        { type: t('energy', 'Energy'), amount: `${chargingAmountInKwh.toFixed(2)} kWh` },
      ],
    },
    ...(hasReservationData
      ? [
          {
            title: t('reservation', 'Reservation'),
            cost: reservationCost,
            details: [{ type: t('time', 'Time'), amount: reservationTimeFormatted }],
          },
        ]
      : []),
    ...(hasParkingData
      ? [
          {
            title: t('parking', 'Parking'),
            cost: parkingCost,
            details: [{ type: t('time', 'Time'), amount: parkingTimeFormatted }],
          },
        ]
      : []),
  ];

  return (
    <Transition.Root as={Fragment}>
      <Dialog as="div" onClose={closeModal} className="fixed z-30 inset-0 overflow-y-auto">
        <div className="w-full min-h-full flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-raisingBlack bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 sm:scale-95"
            enterTo="opacity-100 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 sm:scale-100"
            leaveTo="opacity-0 sm:scale-95"
          >
            <div className="bg-white px-4 sm:rounded-2xl overflow-hidden transform transition-all w-full flex flex-col items-center sm:max-w-login min-h-screen sm:min-h-0">
              <button
                className="absolute p-0 top-8 aria-hidden: right-8"
                type="button"
                onClick={closeModal}
              >
                <img src={exitIcon} alt="exit" className="h-12 w-12 hidden sm:block" />
              </button>
              <button
                className="absolute p-0 top-8 sm:hidden left-4 sm:left-8"
                type="button"
                onClick={closeModal}
              >
                <ArrowLeftIcon className="h-6 w-6" />
              </button>
              <div className="text-left pb-10 flex flex-col mt-20 w-full max-w-[344px]">
                <div className="border-4 border-vool-green rounded-full w-20 h-20 flex items-center justify-center">
                  <img src={check} alt="check" />
                </div>
                <div className="flex flex-col ">
                  <div className="py-6 text-2xl">
                    {t('thankYouForCharging', 'Thanks for charging with us')}
                    {userName && `, ${userName.split(' ')[0]}`}!
                  </div>
                  <div>
                    {lineItems.map((lineItem) => (
                      <LineItem lineItem={lineItem} key={lineItem.title} currency={currency} />
                    ))}
                  </div>
                  <div className="flex  pt-4 flex-col">
                    <div className="flex flex-row justify-between text-lg font-semibold">
                      <div>{t('total', 'Total')}</div>
                      <div>
                        {totalCost.toFixed(2)}
                        {currency.symbol}
                      </div>
                    </div>
                    <div>
                      <div className="flex flex-row pt-2 space-x-2">
                        <div>
                          <PaymentMethodIcon color="#282828" />
                        </div>
                        <div className="text-sm text-raisingBlack">{`**** ${defaultPaymentMethod?.card?.last4}`}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-8 flex flex-col text-center">
                  <Button
                    type="submit"
                    className="flex w-full h-14 mt-4 rounded-full"
                    fontSize="text-base"
                    fontWeight="font-semibold"
                    radiusClass="rounded-full"
                    color="bg-vool-green"
                    shadow="shadow-none"
                    onClick={closeModal}
                  >
                    {t('continue', 'Continue')}
                  </Button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
