import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Box, TextFieldProps, Typography } from '@mui/material';

import { StyledTextField } from 'components/atoms/StyledTextField';
import { countries } from 'config/countries';

type CountryAutocompleteProps<T extends FieldValues> = TextFieldProps & {
  control: Control<T>;
  name: Path<T>;
};

export const CountryAutocomplete = <T extends FieldValues>({
  control,
  name,
  ...props
}: CountryAutocompleteProps<T>) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          disabled={props.disabled}
          onChange={(_event, country) => field.onChange(country?.code)}
          options={countries}
          getOptionLabel={(option) => option.label}
          value={countries.find((c) => field.value === c.code) ?? null}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                alt=""
              />
              {option.label} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              inputRef={field.ref}
              label={<Typography variant="p14">{t('country', 'Country')} *</Typography>}
              error={Boolean(error)}
              helperText={error?.message}
              InputLabelProps={{
                shrink: true,
                sx: {
                  transform: 'translate(14px, -16px)',
                },
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
                style: { padding: '0 4px' },
              }}
            />
          )}
        />
      )}
    />
  );
};
