import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';
import { ReactComponent as SuspendedIcon } from 'app/images/icons/computedStatuses/suspended.svg';
import { Notification } from 'app/views/Notifications/Notification';
import { LiquidBattery } from 'components/animations/LiquidBattery';
import { DurationCounter } from 'components/atoms/DurationCounter';
import { TransactionHandler } from 'handlers/transaction.handler';
import { useCurrency } from 'hooks/use-currency';
import { ConnectorComputedStatus } from 'models/device.enums';
import { Transaction } from 'models/transaction';
import { trackAmplitudeEvent } from 'services/amplitude';
import {
  AMPLITUDE_EVENTS,
  getAmplitudeEventPropertiesForTransaction,
} from 'services/amplitude/data';
import { selectUser } from 'services/auth/selectors';
import { useGetDeviceStatusQuery } from 'services/devices/endpoints';
import { selectActiveEvse, selectDevice } from 'services/sites/selectors';

const useActivePower = ({ deviceUuid, connectorId }: Transaction) => {
  const device = useSelector(selectDevice(deviceUuid));
  const { data } = useGetDeviceStatusQuery(deviceUuid);
  return (
    device?.activePower ||
    data?.deviceStatus?.connectors?.find(({ connector_id }) => connector_id === connectorId)
      ?.active_power
  );
};

type ActiveTransactionSnackbarProps = {
  activeTransaction: Transaction;
};

export const ActiveTransactionSnackbar = ({
  activeTransaction,
}: ActiveTransactionSnackbarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { siteUuid, deviceUuid, connectorId } = activeTransaction;
  const currency = useCurrency(activeTransaction.currency);
  const activeEvse = useSelector(selectActiveEvse);
  const user = useSelector(selectUser);
  const device = useSelector(selectDevice(deviceUuid));
  const connectorStatus = device?.stateOfConnectors?.find(
    (connector) => connector.connectorId === connectorId,
  )?.computedStatus;
  const isSuspendedEvse = ConnectorComputedStatus.SUSPENDED_EVSE === connectorStatus;
  const isSuspendedEv = ConnectorComputedStatus.SUSPENDED_EV === connectorStatus;
  const isSuspended = isSuspendedEvse || isSuspendedEv;

  const activeTransactionHandler = new TransactionHandler({
    transaction: activeTransaction,
    currency,
  });

  const activePower = useActivePower(activeTransaction);

  const handleNavigateToChargingDevice = () => {
    trackAmplitudeEvent(
      AMPLITUDE_EVENTS.CHARGING_SESSION_SNACKBAR,
      getAmplitudeEventPropertiesForTransaction({
        transaction: activeTransaction,
        user,
      }),
    );
    navigate(`/sites?site=${siteUuid}&evse=${deviceUuid}-${connectorId}`);
  };

  return (
    <Notification
      onClick={handleNavigateToChargingDevice}
      backgroundColor="bg-raisingBlack"
      className="h-[72px]"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box display="flex" justifyContent="start" alignItems="center">
          {isSuspended ? (
            <>
              <Box
                flexShrink={0}
                border="3px solid #EB4E20"
                width={56}
                height={56}
                borderRadius="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                alignSelf="center"
                color="#FFFFFF"
              >
                <SuspendedIcon />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                ml={1}
              >
                <Typography variant="p14b" color="white">
                  {t('suspended', 'Suspended')}
                </Typography>
                <Typography variant="p12" color="#D6D6D6" sx={{ display: 'flex', gap: '8px' }}>
                  {isSuspendedEvse
                    ? t('dueToCharger', 'Due to charger')
                    : t('dueToEV', 'Due to EV')}
                  <Tooltip
                    title={
                      isSuspendedEvse
                        ? t(
                            'suspendedEvseText',
                            'EV is connected to the charger but the charger is not offering energy to the EV, e.g. due to a smart charging restriction, load management etc.',
                          )
                        : t(
                            'suspendedEvText',
                            'EV is connected to the charger and the charger is offering energy but the EV is not taking any energy.',
                          )
                    }
                  >
                    <QuestionMarkCircleIcon className="w-4 h-4 text-white" />
                  </Tooltip>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <LiquidBattery
                title={activeTransactionHandler?.getAmount(2).split(' ')[0] ?? ''}
                description="kWh"
              />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                ml={1}
              >
                <Typography variant="p14b" color="white">
                  {activeTransaction?.siteName}
                </Typography>
                <Typography variant="p12" color="#D6D6D6" sx={{ display: 'flex', gap: '8px' }}>
                  <DurationCounter datetime={activeTransaction?.startTime} />
                  <span>•</span>
                  {activeTransactionHandler?.getMonetaryRevenueWithVAT(2)}
                  <span>•</span>
                  {activePower?.toFixed(2) ?? '--'} kW
                </Typography>
              </Box>
            </>
          )}
        </Box>
        {!activeEvse && (
          <IconButton edge="end" color="inherit" sx={{ mr: 1 }}>
            <img src={arrowRightIcon} alt="arrow" className="filter invert w-6 h-6" />
          </IconButton>
        )}
      </Box>
    </Notification>
  );
};
