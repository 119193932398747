import { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';

import { PaymentLink, StripePaymentLinkProps } from './Stripe/PaymentLink';

export const StripePayment = (props: StripePaymentLinkProps) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    const initStripe = async () => {
      if (process.env.REACT_APP_STRIPE_CONFIG && process.env.REACT_APP_CONF_FILE) {
        const loadStripeResult = await loadStripe(
          JSON.parse(process.env.REACT_APP_STRIPE_CONFIG)[process.env.REACT_APP_CONF_FILE].key,
        );
        setStripe(loadStripeResult);
      }
    };

    initStripe().catch(console.error);
  }, []);

  return (
    <Elements
      stripe={stripe}
      options={{
        mode: 'setup',
        currency: 'eur',
        setup_future_usage: 'off_session',
        payment_method_types: ['card'],
      }}
    >
      <PaymentLink {...props} />
    </Elements>
  );
};
