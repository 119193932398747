import { Box, Skeleton } from '@mui/material';

export const SinglePublicSiteSkeleton = () => (
  <Box
    display="flex"
    p={2}
    gap={2}
    borderRadius="20px"
    bgcolor="#F4F4F4"
    alignItems="center"
    border="2px solid white"
  >
    <Skeleton variant="circular" width={56} height={56} />
    <Box display="flex" flexDirection="column" flex={1}>
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="text" width="90%" />
      <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
        <Skeleton variant="rounded" width="20%" height={24} />
        <Skeleton variant="rounded" width="20%" height={24} />
      </Box>
    </Box>
  </Box>
);
