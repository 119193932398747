import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PusherConfig from 'config/pusher';
import { getCookie } from 'handlers/cookie.handler';
import { PusherDataHandler } from 'handlers/pusher/pusher-data.handler';
import { EventTypes } from 'handlers/pusher/pusher.enums';

export const useSubscribeToPusherChannel = (
  pusherChannel?: string,
  eventType: EventTypes = EventTypes.PRIVATE,
) => {
  const dispatch = useDispatch();

  const token = getCookie('cu_token');

  const dispatchActions = (actions: string[]) => {
    if (actions?.length) {
      actions.forEach((action) => dispatch(action));
    }
  };

  useEffect(() => {
    if (!pusherChannel) {
      return () => {};
    }

    const pusher = PusherConfig.getInstance(token);
    const subscriptionChannel = pusher.subscribe(pusherChannel);
    subscriptionChannel.bind(eventType, (data: any) => {
      const pusherDataHandler = new PusherDataHandler(data);
      const actions = pusherDataHandler.getActions();
      dispatchActions(actions);
    });

    return () => {
      pusher.unsubscribe(pusherChannel);
    };
  }, [token, pusherChannel]);
};
