import { useEffect, useState } from 'react';

import moment from 'moment';

const calculateRemainingTime = (datetime?: Date | moment.Moment, maxTimeSeconds = 0) => {
  const now = moment();
  const endOfFreePeriod = moment(datetime).add(maxTimeSeconds, 'seconds');
  return Math.abs(now.diff(endOfFreePeriod, 'seconds'));
};

type DurationCounterProps = {
  datetime?: Date | moment.Moment;
  maxTimeSeconds?: number;
  onMaxTimeAction?: () => void;
  isCounterStopped?: boolean;
};

export const DurationCounter = ({
  datetime,
  maxTimeSeconds = 0,
  onMaxTimeAction,
  isCounterStopped = false,
}: DurationCounterProps) => {
  const [timeLeft, setTimeLeft] = useState(calculateRemainingTime(datetime, maxTimeSeconds));

  useEffect(() => {
    if (!isCounterStopped) {
      const timer = setInterval(() => {
        const newTimeLeft = calculateRemainingTime(datetime, maxTimeSeconds);
        setTimeLeft(newTimeLeft);

        if (newTimeLeft <= 0) {
          onMaxTimeAction?.();
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isCounterStopped]);

  const formattedTime = [
    Math.floor(timeLeft / 3600),
    Math.floor((timeLeft % 3600) / 60),
    timeLeft % 60,
  ]
    .map((value) => String(value).padStart(2, '0'))
    .join(':');

  return <>{formattedTime}</>;
};
