import { updateTransactionFromPusher } from 'services/transactions/actions';

export class PusherTransactionsDataHandler {
  data;
  meta;
  action;
  object;

  constructor({ data, meta, object }: any) {
    this.action = meta.action;
    this.object = object;
    this.data = data;
    this.meta = meta;
  }

  updateTransaction() {
    const { startTime } = this.data.current;
    const transaction = {
      amount: this.data.current.amount,
      status: this.data.current.status,
      ...(startTime && { startTime }),
      transactionId: this.data.current.transactionId,
      monetaryRevenueWithVAT: this.data.current.monetaryRevenueWithVAT,
      userUuid: this.meta.userUuid,
    };
    return [updateTransactionFromPusher(transaction)];
  }
}
