import { Box } from '@mui/material';

import { Site } from 'models/site';

import { SinglePublicSite } from './SinglePublicSite';
import { SinglePublicSiteSkeleton } from './SinglePublicSiteSkeleton';

type SiteListProps = {
  sites: Site[];
  isLoading?: boolean;
};

export const SiteList = ({ sites, isLoading }: SiteListProps) => (
  <Box
    className="no-scrollbar pb-24"
    sx={{
      px: 2,
      height: '100%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    }}
  >
    {isLoading
      ? Array.from(Array(5).keys()).map((key) => <SinglePublicSiteSkeleton key={key} />)
      : sites.map((site) => <SinglePublicSite site={site} key={site.uuid} />)}
  </Box>
);
