import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { ReactComponent as PaymentMethodIcon } from 'app/images/icons/paymentMethod.svg';
import radioIcon from 'app/images/icons/radio.svg';
import recycleIcon from 'app/images/icons/recycle.svg';
import selectedRadioIcon from 'app/images/icons/selectedRadio.svg';
import { capitalize } from 'lodash';
import { PaymentMethod } from 'models/billing';

type PaymentMethodItemProps = {
  paymentMethod: PaymentMethod;
  isEditMode: boolean;
  onPaymentMethodClick: () => void;
  onDeleteClick: () => void;
};

export const PaymentMethodItem = ({
  paymentMethod,
  isEditMode,
  onPaymentMethodClick,
  onDeleteClick,
}: PaymentMethodItemProps) => {
  const { card } = paymentMethod;
  const { t } = useTranslation();
  const expirationDate = new Date(card.exp_year, card.exp_month);
  const isExpired = expirationDate < new Date();
  const isDisabled = paymentMethod.defaultFlag || isExpired || isEditMode;

  const handleOnClick = () => {
    if (!isDisabled) {
      onPaymentMethodClick();
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      height="64px"
      gap={2}
      className="border-b-2 border-zinc last:border-none"
      onClick={handleOnClick}
      style={{ cursor: isEditMode || isExpired ? 'default' : 'pointer' }}
    >
      <PaymentMethodIcon color={isExpired ? '#999898' : '#EB4E20'} />
      <Box display="flex" flexDirection="column" flex={1}>
        <Typography variant="p16" color={isExpired ? '999898' : '#282828'}>
          {capitalize(card.brand)}
        </Typography>
        <Typography variant="p14" color="#999898">
          {`**** ${card?.last4}`}
        </Typography>
      </Box>
      {isEditMode && paymentMethod.defaultFlag && (
        <Box
          px="4px"
          color="#386957"
          borderRadius="4px"
          sx={{
            background: '#D7E1DD',
          }}
        >
          <Typography variant="p14">{t('default', 'Default')}</Typography>
        </Box>
      )}
      {isExpired && (
        <Box
          px="4px"
          color="#EB4E20"
          borderRadius="4px"
          sx={{
            background:
              'linear-gradient(0deg, rgba(235, 78, 32, 0.20) 0%, rgba(235, 78, 32, 0.20) 100%), #FFF;',
          }}
        >
          <Typography variant="p14">{t('expired', 'Expired')}</Typography>
        </Box>
      )}
      {isEditMode ? (
        <button onClick={onDeleteClick}>
          <img src={recycleIcon} alt="recycle" />
        </button>
      ) : (
        <img src={paymentMethod.defaultFlag ? selectedRadioIcon : radioIcon} alt="radio" />
      )}
    </Box>
  );
};
