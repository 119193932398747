import { useState } from 'react';
import { FieldValues, UseFormProps, useForm as useReactHookForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Fade, Typography } from '@mui/material';

import { ReactComponent as AlertIcon } from 'app/images/icons/alert.svg';
import { getErrorMessage } from 'services/api';
import * as Yup from 'yup';

type FormProps<T extends FieldValues> = UseFormProps<T> & {
  schema: Yup.AnyObjectSchema;
};

export const useForm = <T extends FieldValues>({ schema, ...props }: FormProps<T>) => {
  const [err, setError] = useState<unknown>();

  const showError = (error: unknown) => {
    setError(error);
    setTimeout(() => {
      setError(undefined);
    }, 5000);
  };

  const useTypedForm = () =>
    useReactHookForm<T>({
      ...props,
      resolver: yupResolver(schema),
    });

  return {
    ...useTypedForm(),
    showError,
    FormError: (
      <Fade in={err ? true : false}>
        <Box
          display={err ? 'flex' : 'none'}
          p="12px 16px"
          color="#FFF"
          borderRadius="20px"
          gap={2}
          sx={{ backgroundColor: '#282828' }}
        >
          <Box flexShrink={0}>
            <AlertIcon />
          </Box>
          <Typography variant="p14">{getErrorMessage(err)}</Typography>
        </Box>
      </Fade>
    ),
  };
};
