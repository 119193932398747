import { Box } from '@mui/material';

import { ReactComponent as VehicleIcon } from 'app/images/icons/vehicle.svg';
import audiLogo from 'app/images/icons/vehicles/audi.png';
import bmwLogo from 'app/images/icons/vehicles/bmw.png';
import cupraLogo from 'app/images/icons/vehicles/cupra.png';
import hyundaiLogo from 'app/images/icons/vehicles/hyundai.png';
import kiaLogo from 'app/images/icons/vehicles/kia.png';
import nissanLogo from 'app/images/icons/vehicles/nissan.png';
import peugeotLogo from 'app/images/icons/vehicles/peugeot.png';
import porscheLogo from 'app/images/icons/vehicles/porsche.png';
import renaultLogo from 'app/images/icons/vehicles/renault.png';
import skodaLogo from 'app/images/icons/vehicles/skoda.png';
import teslaLogo from 'app/images/icons/vehicles/tesla.png';
import vwLogo from 'app/images/icons/vehicles/volkswagen.png';
import volvoLogo from 'app/images/icons/vehicles/volvo.png';

type VehicleItemIconProps = {
  brand?: string;
};

export const VehicleItemIcon = ({ brand }: VehicleItemIconProps) => {
  const logoMap = {
    volvo: volvoLogo,
    tesla: teslaLogo,
    audi: audiLogo,
    skoda: skodaLogo,
    renault: renaultLogo,
    volkswagen: vwLogo,
    bmw: bmwLogo,
    porshe: porscheLogo,
    cupra: cupraLogo,
    hunday: hyundaiLogo,
    kia: kiaLogo,
    nissan: nissanLogo,
    peugeot: peugeotLogo,
  };
  const carLogo = logoMap[brand?.toLowerCase() as keyof typeof logoMap];

  return (
    <Box
      display="flex"
      sx={{
        width: 56,
        height: 56,
        borderRadius: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#F4F4F4',
      }}
    >
      {!carLogo ? <VehicleIcon /> : <img className="h-8" src={carLogo} alt={brand} />}
    </Box>
  );
};
