import { splitApi } from 'services/api';

import { DeviceStatusResponse } from './responses';

const extendedApi = splitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeviceStatus: builder.query<DeviceStatusResponse, string>({
      query: (deviceUuid) => ({
        url: `/devices/public/status/${deviceUuid}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetDeviceStatusQuery } = extendedApi;
