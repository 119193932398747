import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { format } from 'date-fns';
import { PaymentSessionSummary } from 'models/billing';
import { usePublicSitesQuery } from 'services/sites/endpoints';
import { selectPublicSites } from 'services/sites/selectors';

import { ChargerIcon } from '../PublicSites/ChargerIcon';
import { SummaryModal } from './SummaryModal';

type ChargingHistoryListProps = {
  summaries: PaymentSessionSummary[];
};

export const ChargingHistoryList = ({ summaries }: ChargingHistoryListProps) => {
  const sites = useSelector(selectPublicSites);
  // TODO: Sites should be loaded when user comes to the site and account changes
  const { isLoading } = usePublicSitesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [selectedSummary, setSelectedSummary] = useState<PaymentSessionSummary>();

  return (
    <Box>
      {summaries.map((summary) => {
        return (
          <Box
            key={summary.id}
            display="flex"
            alignItems="center"
            gap={2}
            py={1.5}
            className="border-b border-zinc cursor-pointer"
            onClick={() => setSelectedSummary(summary)}
          >
            <Box width="24px" height="24px">
              <ChargerIcon stroke="#999898" />
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
              <Typography variant="p16">{summary.siteName}</Typography>
              <Box>
                <Typography variant="p14" color="gray" noWrap>
                  {format(new Date(summary.startTime), 'dd MMM, HH:mm')}
                  {/* {transaction.carBrand && transaction.carModel
                    ? ` • ${transaction.carBrand} ${transaction.carModel}`
                    : transaction.carName && ` • ${transaction.carName}`} */}
                </Typography>
              </Box>
            </Box>
            <Typography variant="p16">
              {`${(Math.ceil(summary.monetaryRevenueWithVat * 100) / 100).toFixed(2)} ${
                summary.currencySymbol
              }`}
            </Typography>
          </Box>
        );
      })}
      {selectedSummary && !isLoading && (
        <SummaryModal
          summary={selectedSummary}
          site={sites.find((site) => site.uuid === selectedSummary.siteUuid)}
          onClose={() => setSelectedSummary(undefined)}
        />
      )}
    </Box>
  );
};
