import { PaymentMethod, PaymentSessionDetails, PaymentSessionSummary } from 'models/billing';
import { splitApi } from 'services/api';

import {
  AddPaymentMethodResponse,
  DeletePaymentMethodResponse,
  PaymentMethodsResponse,
  SetDefaultPaymentResponse,
} from './responses';

export type QueryRange = {
  startDate: string;
  endDate?: string;
};

export type Order = 'asc' | 'desc';

export type SortOrder<ContextDataType> = {
  column: keyof ContextDataType;
  order: Order;
};

export type ColumnFilter<ContextDataType> = {
  column: keyof ContextDataType;
  values: any[];
  inclusive: boolean;
};

/**
 * Represents a database cursor.
 */
export type DbCursor = {
  /** The unique identifier to start the query from. (e.g. PK) */
  after: any;

  /** The value to start the query from. */
  value: any;
};

export type PagedDataQuery<ContextDataType> = {
  queryRange: QueryRange;
  cursor?: DbCursor;
  pageSize?: number;
  columnFilters?: ColumnFilter<ContextDataType>[];
  timeZone?: string;
  sortOrder: SortOrder<ContextDataType>;
};

const extendedApi = splitApi.injectEndpoints({
  endpoints: (builder) => ({
    addPaymentMethod: builder.mutation<AddPaymentMethodResponse, void>({
      query: () => ({
        url: '/billing/paymentMethods',
        method: 'POST',
      }),
      invalidatesTags: ['PaymentMethods'],
    }),
    deletePaymentMethod: builder.mutation<DeletePaymentMethodResponse, string>({
      query: (paymentMethodId) => ({
        url: '/billing/paymentMethods',
        method: 'DELETE',
        body: { paymentMethodId },
      }),
      invalidatesTags: ['PaymentMethods'],
    }),
    setPaymentMethodAsDefault: builder.mutation<SetDefaultPaymentResponse, string>({
      query: (id) => ({
        url: '/billing/paymentMethods/default',
        method: 'POST',
        body: { default: id },
      }),
      invalidatesTags: ['PaymentMethods'],
      onQueryStarted(id, { dispatch, queryFulfilled }) {
        const update = dispatch(
          billingApi.util.updateQueryData('getPaymentMethods', undefined, (paymentMethods) => {
            paymentMethods?.forEach((paymentMethod) => {
              paymentMethod.defaultFlag = paymentMethod.id === id;
            });
          }),
        );
        queryFulfilled.catch(() => {
          update.undo();
        });
      },
    }),
    getPaymentMethods: builder.query<PaymentMethod[], void>({
      query: () => ({
        url: '/billing/paymentMethods',
        method: 'GET',
      }),
      transformResponse: (response: PaymentMethodsResponse) =>
        response.paymentMethods.map((paymentMethod) => ({
          ...paymentMethod,
          defaultFlag: Boolean(paymentMethod.defaultFlag),
        })),
      providesTags: ['PaymentMethods'],
    }),
    postPaymentSessionSummaries: builder.query<
      PaymentSessionSummary[],
      PagedDataQuery<PaymentSessionSummary>
    >({
      query: (body) => ({
        method: 'POST',
        url: 'payments/summaries/user',
        body,
      }),
      transformResponse: (response: { data: PaymentSessionSummary[] }) => response.data,
    }),
    getPaymentSessionDetails: builder.query<PaymentSessionDetails, string | undefined>({
      query: (sessionUuid) => ({
        method: 'GET',
        url: `payments/${sessionUuid}`,
      }),
      transformResponse: (response: { data: PaymentSessionDetails }) => response.data,
    }),
    getInvoiceUrl: builder.query<string, string>({
      query: (invoiceId) => `payments/invoices/${invoiceId}/url`,
      transformResponse: (response: { data: string }) => response.data,
    }),
  }),
});

export const {
  useAddPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useSetPaymentMethodAsDefaultMutation,
  useGetPaymentMethodsQuery,
  useLazyPostPaymentSessionSummariesQuery,
  useGetPaymentSessionDetailsQuery,
  useLazyGetInvoiceUrlQuery,
} = extendedApi;

export const billingApi = extendedApi;
