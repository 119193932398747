export enum DeviceType {
  CHARGER = 'charger',
  LMC = 'lmc',
  PHASER = 'phaser',
}
export enum DeviceStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  FAULTED = 'faulted',
}
export enum OcppVersion {
  V1_6 = '1.6',
  V2_0 = '2.0',
}
export enum DeviceTransactionStatus {
  NONE = 'none',
  PENDING = 'pending',
  IN_PROGRESS = 'inProgress',
}
export enum Monitoring {
  VOOL = 'vool',
  OWNER = 'owner',
}
export enum Provider {
  VOOL = 'vool',
  ENODE = 'enode',
}

export enum PowerType {
  AC = 'AC',
  AC_1_PHASE = 'AC_1_PHASE',
  AC_3_PHASE = 'AC_3_PHASE',
  DC = 'DC',
}

export enum ConnectorType {
  TYPE_1 = 'Type1',
  TYPE_2 = 'Type2',
  CHADEMO = 'CHAdeMO',
  CCS = 'CCS',
}

export enum ConnectorFormat {
  CABLE = 'CABLE',
  SOCKET = 'SOCKET',
}

export enum ConnectorComputedStatus {
  AVAILABLE = 'AVAILABLE',
  CHARGING = 'CHARGING',
  FAULTED = 'FAULTED',
  NEVER_CONNECTED = 'NEVER_CONNECTED',
  OFFLINE = 'OFFLINE',
  PLUGGED_IN = 'PLUGGED_IN',
  RESERVED = 'RESERVED',
  STARTING = 'STARTING',
  STOPPING = 'STOPPING',
  SUSPENDED_EV = 'SUSPENDED_EV',
  SUSPENDED_EVSE = 'SUSPENDED_EVSE',
  UNAVAILABLE = 'UNAVAILABLE',
  UNKNOWN = 'UNKNOWN',
  OCCUPIED = 'OCCUPIED',
  PENDING = 'PENDING',
}
