import { createUseStyles } from 'react-jss';

import classnames from 'classnames';
import { Spinner, SpinnerProps } from 'components/atoms/Spinner';

const useStyles = ({
  backgroundColor,
  position,
  top,
  bottom,
  left,
  right,
  opacity,
  fit = false,
}: Partial<OverflowSpinnerStyleProps>) =>
  createUseStyles({
    background: {
      backgroundColor: backgroundColor ?? 'white',
      position: position ?? 'absolute',
      top: fit ? 0 : top ?? -4,
      bottom: fit ? 0 : bottom ?? -4,
      left: fit ? 0 : left ?? -4,
      right: fit ? 0 : right ?? -4,
      opacity: opacity ?? 0.8,
      marginTop: '0!important',
    },
  })();

type OverflowSpinnerStyleProps = {
  backgroundColor: string;
  position: string;
  top: number;
  bottom: number;
  left: number;
  right: number;
  opacity: number;
  fit: boolean;
};

type OverflowSpinnerProps = Partial<OverflowSpinnerStyleProps> &
  SpinnerProps & {
    fit?: boolean;
    className?: string;
  };

export const OverflowSpinner = (props: OverflowSpinnerProps) => {
  const { className, ...rest } = props;
  const classes = useStyles({ ...props });

  return (
    <div
      className={classnames(
        'flex',
        'justify-center',
        'items-center',
        classes.background,
        className,
      )}
    >
      <Spinner {...rest} />
    </div>
  );
};
