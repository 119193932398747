import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import voolCharger from 'app/images/vool-charger-source.jpeg';
import { OutletContext } from 'app/layouts/LoggedinBasicLayout/Layout';
import { StyledButton } from 'components/atoms/StyledButton';
import { Modal } from 'components/modals/Modal';
import { User } from 'models/user';

type ReservationModalProps = {
  user?: User;
  openReservationModal: boolean;
  closeReservationModal: () => void;
  onAddPaymentMethodClick: () => void;
  hasPaymentMethods: boolean;
};

export const ReservationModal = ({
  user,
  openReservationModal,
  closeReservationModal,
  onAddPaymentMethodClick,
  hasPaymentMethods,
}: ReservationModalProps) => {
  const { t } = useTranslation();
  const { setInitAuth } = useOutletContext<OutletContext>();

  const initiateLogin = () => {
    closeReservationModal();
    setInitAuth(true);
  };

  const signInButton = () => (
    <StyledButton color="success" onClick={initiateLogin} fullWidth>
      {t('signIn', 'Sign in')}
    </StyledButton>
  );

  const addPaymentMethodButton = () => (
    <StyledButton
      color="success"
      onClick={() => {
        closeReservationModal();
        onAddPaymentMethodClick();
      }}
      fullWidth
    >
      {t('addPaymentMethod', 'Add Payment Method')}
    </StyledButton>
  );

  return (
    <Modal open={openReservationModal} onClose={closeReservationModal} coverImage={voolCharger}>
      <div className="flex flex-col">
        <div className="text-[32px] font-TwkEverett pb-4">
          {t('reserveCharger', 'Reserve Charger')} ⏱️
        </div>
        <div className="flex flex-col pb-4">
          <div className="text-base font-normal pb-6">
            {user && !hasPaymentMethods
              ? t(
                  'addPaymentMethodText',
                  'Get the confidence that comes with having a charging spot waiting for you. Please add a payment method to access the reservation feature.',
                )
              : t(
                  'reserveChargerText',
                  'Get the confidence that comes with having a charging spot waiting for you. Please register to access the reservation feature.',
                )}
          </div>
        </div>
      </div>
      <div className="flex justify-center pb-6">
        <div className="w-full max-w-[343px]">
          {user && !hasPaymentMethods ? addPaymentMethodButton() : signInButton()}
        </div>
      </div>
    </Modal>
  );
};
