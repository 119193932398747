import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import 'react-spring-bottom-sheet/dist/style.css';

import { OutletContext } from 'app/layouts/LoggedinBasicLayout/Layout';
import { createEvses } from 'handlers/device/device.handler';
import { setActiveDevice, setActiveEvse } from 'services/sites';
import { updateActiveSite, updateActiveSiteByUuid } from 'services/sites/actions';
import { usePublicSitesQuery } from 'services/sites/endpoints';
import { selectActiveEvse, selectActiveSite } from 'services/sites/selectors';

import { ChargerDrawer } from '../Charger/ChargerDrawer';
import { EmptyView } from './EmptyView';
import { GoogleMap } from './GoogleMap/GoogleMap';
import { PublicSiteList } from './PublicSiteList';
import { SiteDrawer } from './SiteDrawer';

export const PublicSites = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { setIsLoading } = useOutletContext<OutletContext>();
  const searchParams = new URLSearchParams(search);
  const urlSiteUuid = searchParams.get('site');
  const urlDeviceUuid = searchParams.get('device');

  const { isSuccess, isLoading, data } = usePublicSitesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const activeSite = useSelector(selectActiveSite);
  const activeEvse = useSelector(selectActiveEvse);

  useEffect(() => {
    if (urlSiteUuid) {
      dispatch(updateActiveSiteByUuid(urlSiteUuid));
    }

    if (urlDeviceUuid && activeSite) {
      const device = activeSite.devices.find((device) => device.uuid === urlDeviceUuid);
      if (device?.configOfConnectors?.length === 1) {
        const [evse] = createEvses(device);
        dispatch(setActiveEvse(evse));
      }
      dispatch(setActiveDevice(device));
    }
  }, [urlSiteUuid, urlDeviceUuid, activeSite, isSuccess]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  if (!isLoading && !data?.sites.length) {
    return <EmptyView />;
  }

  return (
    <div className="flex h-full w-full">
      <PublicSiteList isLoading={isLoading} />
      <div className={`w-full h-full ${activeSite ? 'sm:max-w-map-with-slidebar' : ''}`}>
        <GoogleMap />
      </div>
      {activeSite && (
        <SiteDrawer
          site={activeSite}
          isOpen={activeSite !== undefined}
          closeSlidebar={() => {
            dispatch(updateActiveSite(undefined));
            dispatch(setActiveDevice(undefined));
            navigate({ search: '' });
          }}
        />
      )}
      {activeSite && activeEvse && (
        <ChargerDrawer
          evse={activeEvse}
          site={activeSite}
          closeSlideBar={() => {
            dispatch(setActiveEvse(undefined));
            navigate({ search: '' });
          }}
        />
      )}
    </div>
  );
};
