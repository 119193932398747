import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { Service, ServiceDimension } from 'models/billing';

type SummaryItemProps = {
  label: string;
  value: string;
};

export const SummaryItem = ({ label, value }: SummaryItemProps) => (
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="p14" color="#999898">
      {label}
    </Typography>
    <Typography variant="p14">{value}</Typography>
  </Box>
);

type SummaryDetailsProps = {
  currencySymbol: string;
  services: Service[];
};

export const SummaryDetails = ({ currencySymbol, services }: SummaryDetailsProps) => {
  const { t } = useTranslation();

  const labels: Record<ServiceDimension, string> = {
    [ServiceDimension.TIME]: t('time', 'Time'),
    [ServiceDimension.ENERGY]: t('charging', 'Charging'),
    [ServiceDimension.PARKING_TIME]: t('parking', 'Parking'),
    [ServiceDimension.RESERVATION_TIME]: t('reservation', 'Reservation'),
  };

  return (
    <>
      {services.map((service, index) => {
        return (
          <SummaryItem
            key={`${service.dimension}-${index}`}
            label={labels[service.dimension]}
            value={`${(service.amountInCentsWithVat / 100).toFixed(2)} ${currencySymbol}`}
          />
        );
      })}
    </>
  );
};
