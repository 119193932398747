import Pusher from 'pusher-js';

interface AppConfigPusher {
  key: string;
  authEndpoint?: string;
  cluster: string;
}

export default class PusherConfig {
  private static instance: Pusher | null = null;

  private static token: string | undefined = undefined;

  static getInstance(token?: string): Pusher {
    if (!this.instance || (this.token !== token && token !== undefined)) {
      this.instance = this.initPusher(token);
      this.token = token;
    }
    return this.instance;
  }

  private static initPusher(token?: string): Pusher {
    const appConfigPusher: AppConfigPusher = JSON.parse(process.env.REACT_APP_PUSHER_CONFIG ?? '')[
      process.env.REACT_APP_CONF_FILE ?? ''
    ];
    return new Pusher(appConfigPusher.key, {
      authEndpoint: `${appConfigPusher.authEndpoint ?? ''}/pusher/auth`,
      auth: {
        headers: { 'X-CSRF-Token': token },
      },
      cluster: appConfigPusher.cluster,
      forceTLS: true,
    });
  }
}
