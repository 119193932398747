import { Device } from 'models/device';
import { ConnectorComputedStatus, DeviceStatus } from 'models/device.enums';
import { Evse } from 'models/evse';
import { ParkingAndReservation } from 'models/parking-and-reservation';
import { Transaction } from 'models/transaction';

export const getEvseStatus = (
  evse: Evse,
  activeTransaction?: Transaction,
  activeReservation?: ParkingAndReservation,
): ConnectorComputedStatus => {
  const { status, computedStatus, reserved } = evse;

  if (
    status === DeviceStatus.OFFLINE ||
    computedStatus === ConnectorComputedStatus.NEVER_CONNECTED
  ) {
    return ConnectorComputedStatus.OFFLINE;
  }
  if (
    !computedStatus ||
    [ConnectorComputedStatus.FAULTED, ConnectorComputedStatus.UNKNOWN].includes(computedStatus)
  ) {
    return ConnectorComputedStatus.UNAVAILABLE;
  }

  const isCharging = getEvseActiveCharge(evse, activeTransaction);

  if (computedStatus === ConnectorComputedStatus.CHARGING) {
    return isCharging ? ConnectorComputedStatus.CHARGING : ConnectorComputedStatus.OCCUPIED;
  }
  if (
    [ConnectorComputedStatus.STARTING, ConnectorComputedStatus.STOPPING].includes(computedStatus)
  ) {
    return isCharging ? ConnectorComputedStatus.PENDING : ConnectorComputedStatus.OCCUPIED;
  }
  if (computedStatus === ConnectorComputedStatus.RESERVED || reserved) {
    if (activeReservation?.deviceUuid === evse.deviceUuid) {
      return computedStatus === ConnectorComputedStatus.PLUGGED_IN
        ? ConnectorComputedStatus.PLUGGED_IN
        : ConnectorComputedStatus.AVAILABLE;
    }

    return ConnectorComputedStatus.RESERVED;
  }
  if (
    computedStatus === ConnectorComputedStatus.SUSPENDED_EV ||
    computedStatus === ConnectorComputedStatus.SUSPENDED_EVSE
  ) {
    return isCharging ? computedStatus : ConnectorComputedStatus.OCCUPIED;
  }
  if (computedStatus && Object.values(ConnectorComputedStatus).includes(computedStatus)) {
    return computedStatus;
  }

  return ConnectorComputedStatus.UNAVAILABLE;
};

export const getEvseActiveCharge = (evse: Evse, activeTransaction?: Transaction) => {
  return activeTransaction?.deviceUuid === evse.deviceUuid;
};

export const isEvseAvailable = (
  evse: Evse,
  activeTransaction?: Transaction,
  activeReservation?: ParkingAndReservation,
) => {
  const status = getEvseStatus(evse, activeTransaction, activeReservation);

  return [
    ConnectorComputedStatus.AVAILABLE,
    ConnectorComputedStatus.CHARGING,
    ConnectorComputedStatus.PLUGGED_IN,
    ConnectorComputedStatus.PENDING,
    ConnectorComputedStatus.SUSPENDED_EV,
    ConnectorComputedStatus.SUSPENDED_EVSE,
  ].includes(status);
};

export const createEvses = (device: Device): Evse[] => {
  return (
    device.configOfConnectors?.map((config) => {
      const status = device.stateOfConnectors?.find(
        (state) => state.connectorId === config.connectorId,
      );
      return {
        deviceUuid: device.uuid,
        activePower: device.activePower,
        userUuid: device.userUuid,
        companyUuid: device.companyUuid,
        evseId: `${device.uuid}-${config.connectorId}`,
        siteUuid: device.siteUuid,
        name: device.name,
        status: device.status,
        ocppVersion: device.ocppVersion,
        vendor: device.vendor,
        model: device.model,
        firmwareVersion: device.firmwareVersion,
        type: device.type,
        serialNumber: device.serialNumber,
        deviceSerialNumber: device.deviceSerialNumber,
        pinCode: device.pinCode,
        deleted: device.deleted,
        addTime: device.addTime,
        updateTime: device.updateTime,
        gridConnection: device.gridConnection,
        ocppPassword: device.ocppPassword,
        connectorId: config.connectorId,
        powerType: config.powerType,
        connectorType: config.connectorType,
        connectorFormat: config.connectorFormat,
        maxPowerKw: config.maxPowerKw,
        ocppStatus: status?.ocppStatus,
        transactionStatus: status?.transactionStatus ?? device.transactionStatus,
        reserved: status?.reserved,
        computedStatus: status?.computedStatus,
        pusherChannel: device.metricsPusherChannel,
      };
    }) ?? []
  );
};

export const isAnyEvseAvailable = (
  device: Device,
  activeTransaction?: Transaction,
  activeReservation?: ParkingAndReservation,
) => {
  const evses = createEvses(device);
  return evses.some((evse) => isEvseAvailable(evse, activeTransaction, activeReservation));
};
