import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Car } from 'models/car';

import { updateVehicleFromPusher } from './actions';
import { CarsResponse, carsApi } from './endpoints';

interface VehiclesState {
  vehicles: Car[];
  defaultVehicle?: Car;
}

const setVehicles = (state: VehiclesState, { payload }: PayloadAction<CarsResponse>) => {
  state.vehicles = payload.data;
  state.defaultVehicle = payload.data[0];
};

const updateVehicle = (state: VehiclesState, { payload }: PayloadAction<Car>) => {
  const { status, uuid } = payload;

  const vehicleToUpdate = state.vehicles.find((vehicle) => vehicle.uuid === uuid);
  if (vehicleToUpdate) {
    vehicleToUpdate.status = status;

    if (uuid === state.defaultVehicle?.uuid) {
      state.defaultVehicle = vehicleToUpdate;
    }
  }
};

const initialState: VehiclesState = {
  vehicles: [],
  defaultVehicle: undefined,
};

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateVehicleFromPusher, updateVehicle);
    builder.addMatcher(carsApi.endpoints.getCars.matchFulfilled, setVehicles);
  },
  reducers: {
    clearVehicles: (state) => {
      state.vehicles = [];
      state.defaultVehicle = undefined;
    },
    setDefaultVehicle: (state, { payload }: PayloadAction<Car | undefined>) => {
      state.defaultVehicle = payload;
    },
  },
});

export const { clearVehicles, setDefaultVehicle } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
