import { useState } from 'react';
import { createUseStyles } from 'react-jss';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

type ChevronProps = {
  closed?: boolean;
};

const Chevron = ({ closed }: ChevronProps) => {
  if (closed) {
    return <ChevronDownIcon className="h-4 w-4 text-gray-500" />;
  }

  return <ChevronUpIcon className="h-4 w-4 text-gray-500" />;
};

const useStyles = createUseStyles({
  title: {
    fontWeight: 100,
    fontSize: 14,
    textAlign: 'left',
    width: '100%',
  },
});

export type CardProps = {
  children: React.ReactNode;
  title?: string;
  full?: boolean;
  className?: string;
  wrapperClass?: string;
  expandable?: boolean;
  closed?: boolean;
  borderless?: boolean;
  items?: string;
  style?: object;
  radiusClass?: string;
};

export const Card = ({
  children,
  title,
  full,
  className,
  wrapperClass,
  expandable,
  closed,
  borderless,
  items = 'items-center',
  style,
  radiusClass = 'rounded-lg',
}: CardProps) => {
  const [cardClosed, setCardClosed] = useState(closed);
  const classes = useStyles();
  const fullTitle = full && title;
  const titleNotFull = title && !full;

  return (
    <div className={wrapperClass}>
      {fullTitle && <div className="pl-2 pb-1 text-xs">{title}</div>}
      <div
        {...(style && {
          style,
        })}
        className={`relative
           ${radiusClass}
           ${borderless ? '' : 'border bg-white shadow-sm'}
           ${cardClosed ? 'pb-2' : ''}
           ${full ? '' : 'px-6 py-5'}
           ${titleNotFull ? 'pt-2' : ''}
           flex
           flex-col
           ${items}
           overflow-hidden
           ${className}`}
      >
        {titleNotFull && (
          <div
            className={`
              ${cardClosed ? '' : 'pb-1 '}
              ${expandable ? ' cursor-pointer' : ''}
              ${classes.title}
              flex
              items-center
            `}
            {...(expandable && {
              onClick: () => expandable && setCardClosed(!cardClosed),
              role: 'button',
              tabIndex: 0,
            })}
          >
            <div className={`flex-1${cardClosed ? ' font-bold' : ''}`}>{title}</div>
            {expandable && <Chevron closed={cardClosed} />}
          </div>
        )}

        {!cardClosed && children}
      </div>
    </div>
  );
};
