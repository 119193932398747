import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import steeringWheel from 'app/images/tesla-steering-wheel-source.jpeg';
import { Button } from 'components/atoms/Button';
import { Modal } from 'components/modals/Modal';
import { useCurrency } from 'hooks/use-currency';
import { Site } from 'models/site';
import { selectPortalConfig } from 'services/portal/selectors';
import { selectSiteVatMultiplier } from 'services/sites/selectors';

type ChargingAndReservationInfoModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  site: Site;
};

export const ChargingAndReservationInfoModal = ({
  isOpen,
  closeModal,
  site,
}: ChargingAndReservationInfoModalProps) => {
  const { t } = useTranslation();
  const vatMultiplier = useSelector(selectSiteVatMultiplier(site?.uuid ?? '')) ?? 1;
  const {
    host: { paymentReceiver },
  } = useSelector(selectPortalConfig) ?? { host: {} };
  const currency = useCurrency(site?.defaultCurrency);
  const freeReservationMinutes = site?.settings?.chargerReservation?.freeMinutes ?? 0;
  const isReservationEnabled = site?.settings?.chargerReservation?.isEnabled;
  const isParkingEnabled = site?.settings?.parking?.isEnabled;
  const freeParkingMinutes = site?.settings?.parking?.freeMinutes ?? 0;
  // TODO: VAT might be undefined
  const getPricePerMinute = (cents?: number) => ((cents ?? 0) / 100) * vatMultiplier;
  const reservationPricePerMinutes = getPricePerMinute(
    site?.settings?.chargerReservation?.priceCentsPerMinute,
  ).toFixed(2);
  const parkingPricePerMinutes = getPricePerMinute(
    site?.settings?.parking?.priceCentsPerMinute,
  ).toFixed(2);

  return (
    <Modal open={isOpen} onClose={closeModal} coverImage={steeringWheel}>
      <Box className="flex flex-col" gap={3}>
        <div className="text-3xl sm:whitespace-nowrap ">
          {t('ChargingAndPricingInformation', 'Charging & pricing information').toString()}
        </div>
        <ul>
          <li>
            {`• ${t(
              'chargingWillStartAfterPayment',
              'Charging will automatically start after payment.',
            )}`}
          </li>
          <li>
            {`• ${t(
              'paymentMessageWithCurrency',
              '{{bookedAmount}} {{currencySymbol}} will be booked on your account by {{paymentReceiver}}',
              {
                paymentReceiver,
                bookedAmount: site.bookingAmount,
                currencySymbol: currency.symbol,
              },
            )}`}
          </li>
          {isReservationEnabled &&
            (freeReservationMinutes > 0 ? (
              <li>
                {`• ${t(
                  'reservationInfoWithFreeMinutes',
                  'To reserve: {{freeReservationMinutes}} min free, then {{reservationPricePerMinutes}} {{currencySymbol}}/min',
                  {
                    freeReservationMinutes,
                    reservationPricePerMinutes,
                    currencySymbol: currency.symbol,
                  },
                )}`}
              </li>
            ) : (
              <li>
                {`• ${t(
                  'reservationInfo',
                  'To reserve: {{reservationPricePerMinutes}} {{currencySymbol}}/min',
                  { reservationPricePerMinutes, currencySymbol: currency.symbol },
                )}`}
              </li>
            ))}
          {isParkingEnabled &&
            (freeParkingMinutes > 0 ? (
              <li>
                {`• ${t(
                  'parkingFeeWithFreeMinutes',
                  'Parking fee: {{freeParkingMinutes}} min free, then {{parkingPricePerMinutes}} {{currencySymbol}}/min will be applied if your car stays here after the battery is full',
                  {
                    parkingPricePerMinutes,
                    freeParkingMinutes,
                    currencySymbol: currency.symbol,
                  },
                )}`}
              </li>
            ) : (
              <li>
                {`• ${t(
                  'parkingFee',
                  'Parking fee: {{parkingPricePerMinutes}} {{currencySymbol}}/min will be applied if your car stays here after the battery is full',
                  { parkingPricePerMinutes, currencySymbol: currency.symbol },
                )}`}
              </li>
            ))}
          <li>{`• ${t('minimumFee', 'Minimum fee {{value}} {{currencySymbol}}', {
            value: site?.minimumFee?.toFixed(2),
            currencySymbol: currency.symbol,
          })}
        `}</li>
        </ul>
        <div className="flex w-full self-center pb-4 max-w-[343px]">
          <Button
            radiusClass="rounded-full"
            className="w-full py-4 bg-vool-gray-200"
            textColor="black"
            fontSize="text-md"
            onClick={closeModal}
          >
            {t('back', 'Back').toString()}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
