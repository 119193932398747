import { Button, ButtonProps, Typography, styled } from '@mui/material';

type ButtonColor = 'primary' | 'secondary' | 'success';

type ColorMap = {
  [key in ButtonColor]: string;
};

const backgroundColorMap: ColorMap = {
  primary: '#EB4E20',
  secondary: '#D6D6D6',
  success: '#386957',
};

const colorMap: ColorMap = {
  primary: '#FFFFFF',
  secondary: '#282828',
  success: '#FFFFFF',
};

type StyledButtonProps = ButtonProps & {
  color?: ButtonColor;
};

const BaseStyledButton = styled(Button)<StyledButtonProps>(({ color = 'primary' }) => ({
  backgroundColor: backgroundColorMap[color],
  color: colorMap[color],
  borderRadius: '150px',
  padding: '8px 24px',
  height: '56px',
  '&:hover': {
    backgroundColor: backgroundColorMap[color],
  },
  '&.Mui-disabled': {
    backgroundColor: '#F4F4F4',
    color: '#999898',
  },
}));

export const StyledButton = ({ color, ...props }: StyledButtonProps) => {
  return (
    <BaseStyledButton color={color} {...props}>
      <Typography variant="p16b" textTransform="none">
        {props.children}
      </Typography>
    </BaseStyledButton>
  );
};
