import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { ReactComponent as OfflineIcon } from 'app/images/icons/computedStatuses/offline.svg';
import { ReactComponent as PluggedIcon } from 'app/images/icons/computedStatuses/pluggedIn.svg';
import { ReactComponent as SuspendedIcon } from 'app/images/icons/computedStatuses/suspended.svg';
import { ReactComponent as UnavailableIcon } from 'app/images/icons/computedStatuses/unavailable.svg';
import { ReactComponent as TimePrimaryIcon } from 'app/images/icons/timePrimary.svg';
import { ConnectorComputedStatus } from 'models/device.enums';

import { ChargerIcon } from '../PublicSites/ChargerIcon';
import { FastChargerIcon } from '../PublicSites/FastChargerIcon';
import { OfflineSiteIcon as OccupiedIcon } from '../PublicSites/OfflineSiteIcon';

type ComputedStatusDef = {
  label: string;
  icon: ReactElement;
  iconColor?: string;
};

export const useComputedStatusDef = (
  computedStatus: ConnectorComputedStatus,
  maxPowerKw?: number,
): ComputedStatusDef => {
  const { t } = useTranslation();

  const unavailableComputedStatusDef: ComputedStatusDef = {
    label: t('unavailable', 'Unavailable'),
    icon: <UnavailableIcon />,
  };

  const computedStatusDefs: Partial<Record<ConnectorComputedStatus, ComputedStatusDef>> = {
    AVAILABLE: {
      label: t('available', 'Available'),
      icon: <ChargerIcon stroke="#386957" />,
      iconColor: '#386957',
    },
    PLUGGED_IN: {
      label: t('pluggedIn', 'Plugged in'),
      icon: <PluggedIcon />,
      iconColor: '#407090',
    },
    CHARGING: {
      label: t('charging', 'Charging'),
      icon:
        maxPowerKw && maxPowerKw > 22 ? (
          <FastChargerIcon stroke="#EB4E20" />
        ) : (
          <ChargerIcon stroke="#EB4E20" />
        ),
      iconColor: '#EB4E20',
    },
    SUSPENDED_EV: {
      label: t('suspendedDueToEv', 'Suspended Due To EV'),
      icon: <SuspendedIcon />,
    },
    SUSPENDED_EVSE: {
      label: t('suspendedDueToCharger', 'Suspended Due To Charger'),
      icon: <SuspendedIcon />,
    },
    PENDING: {
      label: t('pending', 'Pending'),
      icon: <OccupiedIcon />,
    },
    RESERVED: {
      label: t('reserved', 'Reserved'),
      icon: <TimePrimaryIcon />,
      iconColor: '#EB4E20',
    },
    OFFLINE: {
      label: t('offline', 'Offline'),
      icon: <OfflineIcon />,
    },
    OCCUPIED: {
      label: t('occupied', 'Occupied'),
      icon: <OccupiedIcon />,
    },
  };

  const computedStatusDef = computedStatusDefs[computedStatus] ?? unavailableComputedStatusDef;

  return {
    ...computedStatusDef,
    icon: (
      <Box
        flexShrink={0}
        border={`3px solid ${computedStatusDef?.iconColor ?? '#999898'}`}
        width={56}
        height={56}
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        color={computedStatusDef?.iconColor ?? '#999898'}
      >
        {computedStatusDef.icon}
      </Box>
    ),
  };
};
