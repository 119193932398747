import { CompanyBillingDetails } from 'models/company';
import { splitApi } from 'services/api';

import {
  CompanyBillingDetailsResponse,
  LoginResponse,
  RegisterAccountlessUserResponse,
  SelfResponse,
} from './responses';

const extendedApi = splitApi.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: ({ name, email, password }) => ({
        url: 'auth/register',
        method: 'POST',
        body: { name, email, password },
      }),
    }),
    self: builder.query<SelfResponse, void>({
      query: () => ({
        url: 'users/self',
        method: 'GET',
      }),
    }),
    updateSelf: builder.mutation<SelfResponse, any>({
      query: (data) => ({
        url: 'users/self',
        method: 'PUT',
        body: data,
      }),
    }),
    login: builder.mutation<LoginResponse, any>({
      query: ({ email, password }) => ({
        url: 'auth/login',
        method: 'POST',
        body: { email, password },
      }),
      invalidatesTags: ['PaymentMethods'],
    }),
    resetPassword: builder.mutation({
      query: ({ email }) => ({
        url: 'auth/reset-password',
        method: 'POST',
        body: { email },
      }),
    }),
    checkPasswordHash: builder.query({
      query: ({ token }) => ({
        url: 'auth/check-password-hash',
        method: 'POST',
        body: { token },
      }),
    }),
    changeResetPassword: builder.mutation({
      query: ({ password, token }) => ({
        url: 'auth/change-reset-password',
        method: 'POST',
        body: { password, token },
      }),
    }),
    googleLogin: builder.mutation({
      query: ({ email, name, password, idToken }) => ({
        url: 'auth/google-login',
        method: 'POST',
        body: {
          email,
          name,
          password,
          idToken,
        },
      }),
    }),

    appleLogin: builder.mutation({
      query: ({ code, bundleId, firstName, lastName }) => ({
        url: `auth/apple-login?code=${code}&bundleId=${bundleId}&firstName=${firstName}&lastName=${lastName}`,
        method: 'POST',
      }),
    }),

    registerAccountlessUser: builder.mutation<RegisterAccountlessUserResponse, void>({
      query: () => ({
        url: 'auth/registerAccountlessUser',
        method: 'POST',
      }),
    }),
    updateUserPassword: builder.mutation({
      query: (body) => ({
        url: 'users/update-password',
        method: 'PUT',
        body,
      }),
    }),
    editBillingDetails: builder.mutation<
      CompanyBillingDetailsResponse,
      { billingDetails?: CompanyBillingDetails; companyBillingFlag: 0 | 1 }
    >({
      query: (body) => ({
        url: '/users/self/billingDetails',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useRegisterMutation,
  useSelfQuery,
  useUpdateSelfMutation,
  useLoginMutation,
  useResetPasswordMutation,
  useCheckPasswordHashQuery,
  useChangeResetPasswordMutation,
  useGoogleLoginMutation,
  useAppleLoginMutation,
  useRegisterAccountlessUserMutation,
  useUpdateUserPasswordMutation,
  useEditBillingDetailsMutation,
} = extendedApi;

export const authApi = extendedApi;
