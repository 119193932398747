import { initReactI18next } from 'react-i18next';

import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const availableLanguages = ['et', 'en', 'ru'];
const fallbackLang = 'en';

const options: InitOptions = {
  fallbackLng: fallbackLang,
  saveMissing: true,
  saveMissingTo: 'all',
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    caches: ['cookie'],
  },
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: '/locales/{{lng}}.json',
  },
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(options);

export { availableLanguages, fallbackLang as fallbackLanguage };
export default i18n;
