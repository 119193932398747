import { Box, Skeleton } from '@mui/material';

type SummaryCardSkeletonProps = {
  withCurrency?: boolean;
};

export const SummaryCardSkeleton = ({ withCurrency }: SummaryCardSkeletonProps) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    bgcolor="#F4F4F4"
    height="152px"
    borderRadius="20px"
    p={2}
  >
    <Skeleton variant="circular" width={24} height={24} />
    <Box>
      {withCurrency && <Skeleton variant="text" width="10%" height={20} />}
      <Skeleton variant="text" width="30%" height={30} />
      <Skeleton variant="text" width="20%" height={20} />
    </Box>
  </Box>
);
