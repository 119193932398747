import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, Typography } from '@mui/material';

import { SiteIcon } from 'app/images/icons/SiteIcon';
import { resetFilters, setAvailability, toggleFiltersOpen } from 'services/filters';
import { selectAvailabilityFilters } from 'services/filters/selectors';

import { FilterOption } from './FilterOption';

export const Filters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const availability = useSelector(selectAvailabilityFilters);

  return (
    <Box display="flex" flexDirection="column" px={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <IconButton
            color="inherit"
            disableRipple
            onClick={() => dispatch(toggleFiltersOpen())}
            sx={{ paddingLeft: 0 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h3">{t('filters', 'Filters')}</Typography>
        </Box>
        <Typography
          variant="p16"
          onClick={() => dispatch(resetFilters())}
          sx={{
            color: '#EB4E20',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {t('reset', 'Reset')}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="p16b">
          {t('chargePointAvailability', 'Charge point availability')}
        </Typography>
        <Box display="flex" flexDirection="column" mt={1}>
          <FilterOption
            icon={<SiteIcon />}
            name={t('available', 'Available')}
            checked={availability.available}
            onClick={() =>
              dispatch(setAvailability({ ...availability, available: !availability.available }))
            }
          />
          <FilterOption
            icon={<SiteIcon color="#EB4E20" />}
            name={t('busy', 'Busy')}
            checked={availability.busy}
            onClick={() => dispatch(setAvailability({ ...availability, busy: !availability.busy }))}
          />
          <FilterOption
            icon={<SiteIcon color="#407090" />}
            name={t('private', 'Private')}
            checked={availability.unlisted}
            onClick={() =>
              dispatch(setAvailability({ ...availability, unlisted: !availability.unlisted }))
            }
          />
        </Box>
      </Box>
      {/* <Box mt={4}>
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '24px',
        }}
      >
        Charge speed
      </Typography>
      <Box display="flex" flexDirection="column" mt={1}>
        <FilterOption name="Slow" description="1-22 kW" />
        <FilterOption name="Fast" description="23-99 kW" />
        <FilterOption name="Ultra fast" description="+100 kW" />
      </Box>
    </Box> */}
    </Box>
  );
};
