import { createSelector } from 'reselect';
import { RootState } from 'services/store';

import { AuthState } from '.';

export const selectSlice = (state: RootState): AuthState => state.auth;

export const selectUser = createSelector(selectSlice, (slice) => slice.user);
export const selectCompanyBillingDetails = createSelector(
  selectSlice,
  (slice) => slice.companyBillingDetails,
);
