import React, { useState } from 'react';

import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';

import { Input, InputProps } from 'components/atoms/Input';

export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [passwordMasked, setPasswordMasked] = useState(true);

  const trailingIcon = () => (
    <button type="button" onClick={() => setPasswordMasked((mask) => !mask)}>
      {passwordMasked ? (
        <EyeOffIcon className="h-full w-full" />
      ) : (
        <EyeIcon className="h-full w-full" />
      )}
    </button>
  );

  return (
    <div className="items-center w-full relative">
      <Input
        {...props}
        type={passwordMasked ? 'password' : 'text'}
        className="flex-grow"
        ref={ref}
        trailingIcon={trailingIcon}
      />
    </div>
  );
});

PasswordInput.displayName = 'PasswordInput';
