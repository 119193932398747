import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import { OverflowSpinner } from 'components/spinner/OverflowSpinner';
import { LOGIN_FORM_OPTIONS } from 'handlers/login-form-options.enum';
import { useForm } from 'hooks/user-form';
import { useRegisterMutation } from 'services/auth/endpoints';
import * as yup from 'yup';

const schema = yup
  .object()
  .shape({
    name: yup.string().required('Required*'),
    email: yup
      .string()
      .email('Invalid email')
      .required('Required*')
      .matches(/^\S+@\S+$/, { message: 'Please provide a valid email address' }),
    password: yup.string().required('Required*'),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required();

type RegisterForm = {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

type RegisterBoxProps = {
  modal?: boolean;
  closeModal: () => void;
  onEvent: (event: LOGIN_FORM_OPTIONS) => void;
};

export const RegisterBox = ({ modal, closeModal, onEvent }: RegisterBoxProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registerCustomer, { isLoading }] = useRegisterMutation();
  const { register, handleSubmit, formState, showError, FormError } = useForm<RegisterForm>({
    schema,
  });

  const onSubmit = async ({ name, email, password }: RegisterForm) => {
    try {
      await registerCustomer({ name, email, password }).unwrap();
      if (modal) {
        closeModal();
        onEvent(LOGIN_FORM_OPTIONS.LOGIN);
      } else {
        navigate('/');
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <div className="flex flex-col">
      <span className="text-3xl font-TwkEverett">{t('registerTitle', 'Getting started')}</span>
      <span className="pt-2">{t('registerSubtitle', 'We love new faces around here!')}</span>
      <span>{t('registerMessage', 'Let’s set you up for charging.')}</span>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 pt-4 w-full relative text-center"
      >
        {FormError}
        <Input
          label={t('name', 'Name')}
          error={Boolean(formState.errors?.name)}
          helpText={formState.errors?.name?.message}
          {...register('name')}
        />
        <Input
          label={t('email', 'Email address')}
          error={Boolean(formState.errors?.email)}
          helpText={formState.errors?.email?.message}
          {...register('email')}
        />
        <Input
          label={t('password', 'Password')}
          type="password"
          autoComplete="new-password"
          error={Boolean(formState.errors?.password)}
          helpText={formState.errors?.password?.message}
          {...register('password')}
        />
        <Input
          label={t('confirmPassword', 'Confirm password')}
          type="password"
          autoComplete="new-password"
          error={Boolean(formState.errors?.passwordConfirmation)}
          helpText={formState.errors?.passwordConfirmation?.message}
          {...register('passwordConfirmation')}
        />
        <Button
          type="submit"
          radiusClass="rounded-full"
          className="w-full h-12"
          color="bg-vool-green"
        >
          {t('Register', 'Register')}
        </Button>
        <button
          type="button"
          className="font-medium text-app-general-dark hover:opacity-80 underline"
          onClick={() => {
            onEvent(LOGIN_FORM_OPTIONS.LOGIN);
          }}
          style={{ textUnderlineOffset: '0.5em' }}
        >
          {t('alreadyHaveAccount', 'I’m not new here. Let me SIGN IN.')}
        </button>
        {isLoading && <OverflowSpinner size={16} />}
      </form>
    </div>
  );
};
