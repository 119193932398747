import { useCountriesQuery } from 'services/countries/endpoints';

export const useCurrency = (currency?: string) => {
  const { data: countries, isLoading } = useCountriesQuery();

  if (isLoading || !countries?.length) {
    return {
      code: '',
      symbol: '',
      centsName: 'cents',
      centsSymbol: '',
    };
  }

  const country = countries.find((c) => c.currency === currency?.toUpperCase());

  if (country) {
    return {
      code: country.currency,
      symbol: country.currencySymbol,
      centsName: country.currencyCentsName,
      centsSymbol: country.currencyCentsSymbol,
    };
  } else {
    return {
      code: 'EUR',
      symbol: '€',
      centsName: 'cents',
      centsSymbol: '¢',
    };
  }
};
