import { FunctionComponent } from 'react';
import { TFunction } from 'react-i18next';

import { ReactComponent as AccountIcon } from 'app/images/icons/account.svg';
import { ReactComponent as EnergyIcon } from 'app/images/icons/energy.svg';
import { ReactComponent as InfoIcon } from 'app/images/icons/info.svg';
import { ReactComponent as LoginIcon } from 'app/images/icons/login.svg';
import { ReactComponent as LogoutIcon } from 'app/images/icons/logout.svg';
import { ReactComponent as HistoryIcon } from 'app/images/icons/time.svg';
import { AMPLITUDE_EVENTS } from 'services/amplitude/data';

export type Item = {
  href?: string;
  label?: string;
  amplitudeEvent?: string;
  title: string;
  icon: FunctionComponent;
  onClick?: () => void;
};

export const loggedInMenuItems = (t: TFunction<'translation', undefined>, logout: () => void) => {
  return [
    {
      title: t('account', 'Account'),
      href: '/account',
      icon: AccountIcon,
      amplitudeEvent: AMPLITUDE_EVENTS.OPEN_ACCOUNT_VIEW,
    },
    {
      title: t('sites', 'Sites'),
      href: '/sites',
      icon: EnergyIcon,
      amplitudeEvent: AMPLITUDE_EVENTS.OPEN_SITES_VIEW,
    },
    {
      title: t('chargingHistory', 'Charging history'),
      href: '/charge-history',
      icon: HistoryIcon,
      amplitudeEvent: AMPLITUDE_EVENTS.OPEN_CHARGING_HISTORY_VIEW,
    },
    {
      title: t('support', 'Support'),
      icon: InfoIcon,
      onClick: () => {
        window.open('https://support.vool.com/en', 'VoolHelpCenter', 'noopener');
      },
    },
    {
      title: t('logOut', 'Log out'),
      onClick: () => {
        logout();
      },
      icon: LogoutIcon,
    },
  ];
};

export const loggedOutMenuItems = (
  t: TFunction<'translation', undefined>,
  initiateLogin: () => void,
) => {
  return [
    {
      title: t('sites', 'Sites'),
      href: '/sites',
      icon: EnergyIcon,
      amplitudeEvent: AMPLITUDE_EVENTS.OPEN_SITES_VIEW,
    },
    {
      title: t('support', 'Support'),
      icon: InfoIcon,
      onClick: () => {
        window.open('https://support.vool.com/en', 'VoolHelpCenter', 'noopener');
      },
    },
    {
      title: t('logIn', 'Login'),
      onClick: initiateLogin,
      icon: LoginIcon,
    },
  ];
};
