import { Card } from 'components/atoms/Card';
import { SingleCardWrapper } from 'components/atoms/SingleCardWrapper';

import { OverflowSpinner } from './OverflowSpinner';

export const CardSpinner = () => (
  <SingleCardWrapper>
    <Card className="h-36">
      <OverflowSpinner size={16} />
    </Card>
  </SingleCardWrapper>
);
