import { useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';
import timeIcon from 'app/images/icons/time.svg';
import { Notification } from 'app/views/Notifications/Notification';
import { DurationCounter } from 'components/atoms/DurationCounter';
import { useCurrency } from 'hooks/use-currency';
import moment from 'moment';
import { clearParkingAndReservation } from 'services/parkingAndReservations/actions';
import { useCancelDeviceReservationMutation } from 'services/parkingAndReservations/endpoints';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { selectActiveEvse, selectSiteVatMultiplier } from 'services/sites/selectors';

export const ReserveChargerSnackbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const activeReservation = useSelector(selectActiveReservation);
  const activeEvse = useSelector(selectActiveEvse);
  const currency = useCurrency(activeReservation?.currency);
  // TODO: Again some state mess
  const freeReservationSeconds = (activeReservation?.freeMinutes ?? 0) * 60;
  const [, { isLoading: cancelReserveLoading }] = useCancelDeviceReservationMutation({
    fixedCacheKey: 'cancelReservation',
  });
  const vatMultiplier =
    useSelector(selectSiteVatMultiplier(activeReservation?.siteUuid ?? '')) ?? 1;
  const reservationPricePerMin =
    ((activeReservation?.priceCentsPerMinute ?? 0) / 100) * vatMultiplier;
  const activeReservationDeviceUuid = activeReservation?.deviceUuid;
  const activeReservationConnectorId = activeReservation?.connectorId;
  const startReservationTimestamp = activeReservation?.startTime;

  const remainingSeconds = moment().diff(moment(startReservationTimestamp), 'seconds');
  const paidReservationStart = moment(startReservationTimestamp).add(
    freeReservationSeconds,
    'seconds',
  );
  const isAfterPaidTime = moment().isAfter(paidReservationStart);

  const [isPaidReservation, setIsPaidReservation] = useState(isAfterPaidTime);
  const [paidReservationCost, setPaidReservationCost] = useState(0);

  const handleNavigateToReservedDevice = () => {
    navigate(
      `/sites?site=${activeReservation?.siteUuid}&evse=${activeReservationDeviceUuid}-${activeReservationConnectorId}`,
    );
  };

  const calculatePaidReservationPrice = () => {
    const paidReservationMin = moment().diff(moment(paidReservationStart), 'seconds') / 60;
    return paidReservationMin * reservationPricePerMin;
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isPaidReservation) {
      setPaidReservationCost(calculatePaidReservationPrice());
      intervalId = setInterval(() => {
        setPaidReservationCost(calculatePaidReservationPrice());
      }, 60000);
    }

    return () => clearInterval(intervalId);
  }, [isPaidReservation]);

  return (
    <div className="relative flex w-full">
      <Notification
        onClick={handleNavigateToReservedDevice}
        backgroundColor="bg-raisingBlack"
        className="h-[72px]"
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center">
            <CountdownCircleTimer
              isPlaying={true}
              size={55}
              strokeWidth={3}
              duration={freeReservationSeconds}
              trailColor="#5e5e5e"
              colors={freeReservationSeconds === 0 ? '#5e5e5e' : '#eb4e20'}
              initialRemainingTime={freeReservationSeconds - remainingSeconds}
              onComplete={() => ({
                shouldRepeat: false,
              })}
            >
              {() => <img src={timeIcon} alt="time" className="invert" />}
            </CountdownCircleTimer>
            <div className="items-start ml-3">
              <p className="font-normal text-white">
                <span className="flex text-sm">
                  <DurationCounter
                    datetime={startReservationTimestamp}
                    maxTimeSeconds={freeReservationSeconds}
                    onMaxTimeAction={() =>
                      reservationPricePerMin === 0
                        ? dispatch(clearParkingAndReservation())
                        : setIsPaidReservation(true)
                    }
                    isCounterStopped={cancelReserveLoading}
                  />
                  <span className="pl-2">
                    {isPaidReservation && `-  ${paidReservationCost.toFixed(2)} ${currency.symbol}`}
                  </span>
                </span>
                <span className="text-xs opacity-50">
                  {isPaidReservation
                    ? t('onPaidReservation', 'On paid reservation')
                    : t('onFreeReservation', 'On free reservation')}
                </span>
              </p>
            </div>
          </div>
          {!activeEvse && (
            <img src={arrowRightIcon} alt="arrow" className="filter invert w-6 h-6 mr-3" />
          )}
        </div>
      </Notification>
    </div>
  );
};
