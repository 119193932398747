import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { ReactComponent as ArrowRightIcon } from 'app/images/icons/arrowRight.svg';
import { ReactComponent as VehicleIcon } from 'app/images/icons/vehicle.svg';
import { GrayCard } from 'components/atoms/GrayCard';
import { Car } from 'models/car';
import {
  selectActiveParking,
  selectActiveReservation,
} from 'services/parkingAndReservations/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';
import { setDefaultVehicle } from 'services/vehicles';
import { useGetCarsQuery } from 'services/vehicles/endpoints';
import { selectDefaultVehicle, selectVehicles } from 'services/vehicles/selectors';

import { AddVehicleModal } from './AddVehicleModal';
import { VehicleModal } from './VehicleModal';

export const VehicleCard = () => {
  const { t } = useTranslation();
  const vehicles = useSelector(selectVehicles);
  const activeCharge = useSelector(selectActiveTransaction);
  const activeReservation = useSelector(selectActiveReservation);
  const activeParking = useSelector(selectActiveParking);
  const disabled = !!activeCharge || !!activeReservation || !!activeParking;
  const defaultVehicle = useSelector(selectDefaultVehicle);
  const defaultVehicleBatteryLevel = defaultVehicle?.status?.batteryLevel;
  const dispatch = useDispatch();
  useGetCarsQuery({});

  const [vehicleModalOpen, setVehicleModalOpen] = useState(false);
  const [addVehicleModalOpen, setAddVehicleModalOpen] = useState(false);
  const color = disabled ? '#D6D6D6' : '#282828';

  const handleClick = (): void => {
    if (disabled) return;
    if (vehicles.length === 0) {
      setAddVehicleModalOpen(true);
    } else {
      setVehicleModalOpen(true);
    }
  };

  const handleVehicleItemClick = (vehicleId?: string) => {
    if (vehicleId) {
      const clickedVehicle = vehicles.find((vehicle: Car) => vehicle.carId === vehicleId);
      dispatch(setDefaultVehicle(clickedVehicle));
    } else {
      dispatch(setDefaultVehicle(undefined));
    }

    setVehicleModalOpen(false);
  };

  return (
    <>
      <GrayCard display="flex" alignItems="center" gap={2} onClick={handleClick}>
        <VehicleIcon color={disabled ? '#D6D6D6' : '#EB4E20'} />
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography variant="p16" color={disabled ? '#999898' : '#282828'}>
            {defaultVehicle
              ? `${defaultVehicle.brand} ${defaultVehicle.model}`
              : t('unknownVehicle', 'Unknown vehicle')}
          </Typography>
          {defaultVehicle && !!defaultVehicleBatteryLevel && (
            <Typography variant="p14" color={color}>
              {t('battery', 'Battery')}: {defaultVehicleBatteryLevel}%
            </Typography>
          )}
          {!vehicles.length && (
            <Typography variant="p14" color={color}>
              {t('tapToLearnMore', 'Tap to learn more')}
            </Typography>
          )}
        </Box>
        <ArrowRightIcon color={color} />
      </GrayCard>
      <AddVehicleModal
        isOpen={addVehicleModalOpen}
        closeModal={() => setAddVehicleModalOpen(false)}
      />
      <VehicleModal
        isOpen={vehicleModalOpen}
        close={() => setVehicleModalOpen(false)}
        onVehicleItemClick={handleVehicleItemClick}
        vehicles={vehicles}
      />
    </>
  );
};
