import { useDispatch, useSelector } from 'react-redux';

import { removeCookie } from 'handlers/cookie.handler';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForUser } from 'services/amplitude/data';
import { splitApi } from 'services/api';
import { removeCredentials } from 'services/auth/actions';
import { selectUser } from 'services/auth/selectors';
import { clearParkingAndReservation } from 'services/parkingAndReservations/actions';
import { clearVehicles } from 'services/vehicles/index';

export const useLogout = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  return () => {
    dispatch(removeCredentials());
    dispatch(clearParkingAndReservation());
    dispatch(clearVehicles());
    dispatch(splitApi.util.resetApiState());
    removeCookie('cu_token');
    removeCookie('cu_refreshToken');
    removeCookie('cu_temporaryToken');
    removeCookie('cu_refreshToken', { domain: '.vool.com' });
    removeCookie('cu_temporaryToken', { domain: '.vool.com' });
    trackAmplitudeEvent(AMPLITUDE_EVENTS.LOGOUT, getAmplitudeEventPropertiesForUser(user));
  };
};
