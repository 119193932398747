import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Drawer, useMediaQuery } from '@mui/material';

import { VehicleCard } from 'app/views/Vehicles/VehicleCard';
import { useSnackbar } from 'components/alerts/Snackbar';
import { EnergyPrice } from 'components/energyPrice';
import { ThankYouModal } from 'components/modals/chargingSummary/ThankYouModal';
import { getEvseActiveCharge, getEvseStatus } from 'handlers/device/device.handler';
import { SiteHandler } from 'handlers/site.handler';
import { useLogout } from 'hooks/use-logout';
import { some } from 'lodash';
import { ConnectorComputedStatus } from 'models/device.enums';
import { Evse } from 'models/evse';
import { Site } from 'models/site';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForEvse } from 'services/amplitude/data';
import { getErrorMessage } from 'services/api';
import { selectUser } from 'services/auth/selectors';
import { clearParkingAndReservation } from 'services/parkingAndReservations/actions';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { clearTransaction } from 'services/transactions/actions';
import {
  useActiveTransactionQuery,
  useStartChargingMutation,
  useStopChargingMutation,
} from 'services/transactions/endpoints';
import { selectActiveTransaction } from 'services/transactions/selectors';
import { selectDefaultVehicle } from 'services/vehicles/selectors';

import { PaymentMethodCard } from '../Billing/PaymentMethodCard';
import { ChargerActionContainer } from './ChargerActionContainer';
import { ChargerDetailsCard } from './ChargerDetailsCard';
import { ChargerDrawerHeader } from './ChargerDrawerHeader';

type ChargerDrawerProps = {
  site: Site;
  evse: Evse;
  closeSlideBar: () => void;
};

export const ChargerDrawer = ({ site, evse, closeSlideBar }: ChargerDrawerProps) => {
  const { deviceUuid, connectorId } = evse;
  const isMobile = useMediaQuery('(max-width: 768px)');

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showSnackbar, RelativeSnackbar } = useSnackbar();
  const user = useSelector(selectUser);
  const activeTransaction = useSelector(selectActiveTransaction);

  const activeReservation = useSelector(selectActiveReservation);
  const defaultVehicle = useSelector(selectDefaultVehicle);
  // TODO: Needs proper type
  const [lastChargingData, setLastChargingData] = useState<any>(undefined);
  const siteHandler = new SiteHandler(site);
  const status = getEvseStatus(evse, activeTransaction, activeReservation);
  const activeCharge = getEvseActiveCharge(evse, activeTransaction);

  const [startCharging, { isLoading: isStartLoading }] = useStartChargingMutation();
  const [stopCharging, { isLoading: isStopLoading, isSuccess: isStopSuccess }] =
    useStopChargingMutation();
  const buttonLoading = some([isStartLoading, isStopLoading]);
  const { refetch: refetchActiveTransaction } = useActiveTransactionQuery(undefined);

  const logout = useLogout();

  const startChargeHandler = async () => {
    dispatch(clearParkingAndReservation());
    try {
      await startCharging({ deviceUuid, connectorId, carUuid: defaultVehicle?.uuid }).unwrap();
      showSnackbar(`${t('chargeStartedAlertMessage', 'Your charge was successfully started.')}`);
    } catch (error) {
      trackAmplitudeEvent(AMPLITUDE_EVENTS.INITIATE_CHARGING, {
        ...getAmplitudeEventPropertiesForEvse({ evse, site, user }),
        status: 'Failure',
      });
      showSnackbar(getErrorMessage(error));
    }
  };

  const stopChargeHandler = async () => {
    try {
      if (!activeTransaction?.deviceSerialNumber) {
        throw Error('No active transaction found in stopChargeHandler');
      }

      const response = await stopCharging({
        deviceSerialNumber: activeTransaction.deviceSerialNumber,
        connectorId,
      }).unwrap();
      if (!user?.name && !response?.data?.parking) {
        logout();
      }

      setLastChargingData({
        hasParkingData: !!response?.data?.parking,
        hasReservationData: !!response?.data?.reservation,
        lastCharging: response?.data?.charging,
        lastParking: response?.data?.parking,
        lastReservation: response?.data?.reservation,
        currency: activeTransaction.currency, // TODO: improve currency from active transaction for ThankYouModal
      });
    } catch (error) {
      trackAmplitudeEvent(AMPLITUDE_EVENTS.STOP_CHARGING, {
        ...getAmplitudeEventPropertiesForEvse({ evse, site, user }),
        status: 'Failure',
      });
      showSnackbar(getErrorMessage(error));
    } finally {
      dispatch(clearTransaction());
    }
  };

  useEffect(() => {
    if (
      status === ConnectorComputedStatus.CHARGING &&
      activeTransaction?.transactionId?.includes?.('pending')
    ) {
      refetchActiveTransaction();
    }

    if (status === ConnectorComputedStatus.CHARGING && activeCharge) {
      trackAmplitudeEvent(AMPLITUDE_EVENTS.INITIATE_CHARGING, {
        ...getAmplitudeEventPropertiesForEvse({ evse, site, user }),
        status: 'Success',
      });
    }
  }, [status]);

  useEffect(() => {
    if (isStopSuccess) {
      trackAmplitudeEvent(AMPLITUDE_EVENTS.STOP_CHARGING, {
        ...getAmplitudeEventPropertiesForEvse({ evse, site, user }),
        status: 'Success',
      });
    }
  }, [isStopSuccess]);

  return (
    <Drawer
      variant="permanent"
      anchor={isMobile ? 'top' : 'right'}
      open
      PaperProps={{
        sx: {
          height: '100%',
          width: { xs: '100%', md: '408px' },
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
      }}
    >
      <ChargerDrawerHeader site={site} evse={evse} closeSlideBar={closeSlideBar} />
      {/* scrollable charger content section */}
      <div className="flex h-full flex-col w-full overflow-y-auto px-4">
        {siteHandler.isMarketPrice() && (
          <div>
            <EnergyPrice title="" borderless full siteUuid={site.uuid} />
          </div>
        )}
        <ChargerDetailsCard siteHandler={siteHandler} maxPower={evse.maxPowerKw} />
        {user?.name && (
          <Box display="flex" flexDirection="column" gap={2}>
            <VehicleCard />
            <PaymentMethodCard />
          </Box>
        )}
      </div>
      {RelativeSnackbar}
      {/* CTA buttons section */}
      <ChargerActionContainer
        site={site}
        evse={evse}
        startChargeHandler={startChargeHandler}
        stopChargeHandler={stopChargeHandler}
        buttonLoading={buttonLoading}
        onMessage={(message) => showSnackbar(message)}
      />
      {lastChargingData === 100 && (
        <ThankYouModal
          closeModal={() => setLastChargingData(undefined)}
          userName={user?.name ?? ''}
          lastChargingData={lastChargingData}
        />
      )}
    </Drawer>
  );
};
