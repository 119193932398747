import { Evse } from 'models/evse';
import { Site } from 'models/site';
import { Transaction } from 'models/transaction';
import { User } from 'models/user';

export const AMPLITUDE_EVENTS = {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  INITIATE_LOGIN: 'InitiateLogin',
  SELECT_SITE: 'SelectSite',
  SELECT_CHARGER: 'SelectCharger',
  INITIATE_CHARGING: 'InitiateCharging',
  CHARGING_SESSION_SNACKBAR: 'ChargingSessionSnackbar',
  NAVIGATE_TO_SITE: 'NavigateToCharger',
  STOP_CHARGING: 'StopCharging',
  OPEN_ACCOUNT_VIEW: 'OpenAccountView',
  OPEN_SITES_VIEW: 'OpenSitesView',
  OPEN_CHARGING_HISTORY_VIEW: 'OpenChargingHistoryView',
  OPEN_BILLING_VIEW: 'OpenBillingView',
  SAVE_ACCOUNT_DETAILS: 'SaveAccountDetails',
  CANCEL_CHARGE: 'CancelCharge',
  START_RESERVATION: 'StartReservation',
  CANCEL_RESERVATION: 'CancelReservation',
};

export const getAmplitudeEventPropertiesForUser = (user?: User) => ({
  userId: user?.uuid,
});

export const getAmplitudeEventPropertiesForSite = ({
  site,
  user,
}: {
  site?: Site;
  user?: User;
}) => ({
  ...getAmplitudeEventPropertiesForUser(user),
  siteId: site?.uuid,
  siteName: site?.name,
  companyId: site?.companyUuid,
});

export const getAmplitudeEventPropertiesForEvse = ({
  evse,
  site,
  user,
}: {
  evse?: Evse;
  site?: Site;
  user?: User;
}) => ({
  ...getAmplitudeEventPropertiesForSite({ site, user }),
  deviceId: evse?.deviceUuid,
  deviceName: evse?.name,
  companyId: evse?.companyUuid ?? site?.companyUuid,
});

export const getAmplitudeEventPropertiesForTransaction = ({
  transaction,
  user,
}: {
  transaction?: Transaction;
  user?: User;
}) => ({
  ...getAmplitudeEventPropertiesForUser(user),
  siteId: transaction?.siteUuid,
  siteName: transaction?.siteName,
  deviceId: transaction?.deviceUuid,
  deviceName: transaction?.deviceName,
});
