import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { ActiveTransactionSnackbar } from 'app/views/Charger/ActiveTransactionSnackbar';
import { InitializingChargeSnackbar } from 'app/views/Charger/InitializingChargeSnackbar';
import { ParkingSnackbar } from 'components/parking/ParkingSnackbar';
import { ReserveChargerSnackbar } from 'components/reserveCharger/ReserveChargerSnackbar';
import { EventTypes } from 'handlers/pusher/pusher.enums';
import { useSubscribeToPusherChannel } from 'hooks/use-pusher-channel';
import { TransactionStatus } from 'models/transaction';
import { selectUser } from 'services/auth/selectors';
import { updateFooterNotificationData } from 'services/notifications';
import { selectFooterNotification } from 'services/notifications/selectors';
import {
  selectActiveParking,
  selectActiveReservation,
} from 'services/parkingAndReservations/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

type FooterProps = {
  footerNotificationClassName?: string;
};

export const Footer = ({ footerNotificationClassName }: FooterProps) => {
  const dispatch = useDispatch();

  const footerNotificationRef = useRef<HTMLDivElement>(null);
  const user = useSelector(selectUser);
  const activeReservation = useSelector(selectActiveReservation);
  const activeParking = useSelector(selectActiveParking);

  const footerNotification = useSelector(selectFooterNotification);
  const activeTransaction = useSelector(selectActiveTransaction);

  const isActiveTransactionForLoggedInUser = !!user && activeTransaction?.userUuid === user.uuid;

  useEffect(() => {
    if (footerNotificationRef.current) {
      const footerHeight = footerNotificationRef.current.getBoundingClientRect().height;
      dispatch(
        updateFooterNotificationData({
          height: footerHeight,
        }),
      );
    }
  }, [isActiveTransactionForLoggedInUser, activeTransaction?.status, activeReservation?.uuid]);

  useSubscribeToPusherChannel(activeTransaction?.generalPusherChannel, EventTypes.PRIVATE);

  return (
    <Box className={'fixed bottom-0 w-full flex flex-col justify-center'} sx={{ zIndex: 1400 }}>
      <div
        ref={footerNotificationRef}
        className={`${footerNotificationClassName} fixed bottom-0 flex flex-col w-full justify-center items-center sm:block ${
          !footerNotification.isVisible ? 'hidden' : ''
        }`}
      >
        {isActiveTransactionForLoggedInUser &&
          (activeTransaction?.status === TransactionStatus.Started ||
            activeTransaction?.status === TransactionStatus.Updated) && (
            <ActiveTransactionSnackbar activeTransaction={activeTransaction} />
          )}
        {activeReservation && <ReserveChargerSnackbar />}
        {activeParking && <ParkingSnackbar />}
        {isActiveTransactionForLoggedInUser &&
          activeTransaction?.status === TransactionStatus.Pending && (
            <InitializingChargeSnackbar activeTransaction={activeTransaction} />
          )}
      </div>
    </Box>
  );
};
