import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FooterNotification {
  height: number;
  isVisible: boolean;
}

interface NotificationsState {
  footerNotification: FooterNotification;
}

const updateFooterNotification = (
  state: NotificationsState,
  { payload }: PayloadAction<Partial<FooterNotification>>,
) => {
  state.footerNotification = {
    ...state.footerNotification,
    ...payload,
  };
};

const initialState: NotificationsState = {
  footerNotification: {
    height: 0,
    isVisible: true,
  },
};

export const dataSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateFooterNotificationData: updateFooterNotification,
  },
});

export const { updateFooterNotificationData } = dataSlice.actions;

export default dataSlice.reducer;
