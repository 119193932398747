import { Transaction } from 'models/transaction';
import { splitApi } from 'services/api';

export interface TransactionsResponse {
  success: boolean;
  transactions: Transaction[];
}

export interface TransactionResponse {
  success: boolean;
  transaction: Transaction;
}

export interface StartCharge {
  deviceUuid: string;
  connectorId: number;
  carUuid?: string;
}

export interface StopCharge {
  deviceSerialNumber: string;
  connectorId: number;
}

const extendedApi = splitApi.injectEndpoints({
  endpoints: (builder) => ({
    customerTransactions: builder.query<TransactionsResponse, void>({
      query: () => ({
        url: 'transactions/customer',
        method: 'GET',
      }),
    }),
    getTransaction: builder.query<TransactionResponse, string>({
      query: (uuid) => ({
        url: `/transactions/${uuid}?userContext=transaction`,
        method: 'GET',
      }),
    }),
    startCharging: builder.mutation<TransactionResponse, StartCharge>({
      query: (body) => ({
        url: '/ocpp/start',
        method: 'POST',
        body,
      }),
    }),
    // TODO: Figure out what type is actually returned
    stopCharging: builder.mutation<any, StopCharge>({
      query: (body) => ({
        url: '/ocpp/stop',
        method: 'POST',
        body,
      }),
    }),
    activeTransaction: builder.query<TransactionResponse, undefined>({
      query: () => ({
        url: '/transactions/activeTransaction',
        method: 'GET',
      }),
    }),
    cancelPendingTransaction: builder.mutation({
      query: () => ({
        url: '/transactions/pendingTransaction',
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCustomerTransactionsQuery,
  useGetTransactionQuery,
  useStartChargingMutation,
  useStopChargingMutation,
  useActiveTransactionQuery,
  useCancelPendingTransactionMutation,
} = extendedApi;

export const transactionsApi = extendedApi;
