import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CompanyBillingDetails } from 'models/company';
import { User } from 'models/user';

import { removeCredentials } from './actions';
import { authApi } from './endpoints';

export interface AuthState {
  user?: User;
  companyBillingDetails?: CompanyBillingDetails;
}

const initialState: AuthState = {
  user: undefined,
  companyBillingDetails: undefined,
};

const setUser = (
  state: AuthState,
  { payload }: PayloadAction<{ user: User; company?: CompanyBillingDetails }>,
) => {
  state.user = payload.user;
  state.companyBillingDetails = payload.company;
};

const setCompanyBillingDetails = (
  state: AuthState,
  { payload }: PayloadAction<{ company?: CompanyBillingDetails }>,
) => {
  state.companyBillingDetails = payload.company;
};

const clearAuth = (state: AuthState) => {
  state.user = undefined;
  state.companyBillingDetails = undefined;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(removeCredentials, clearAuth);
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, setUser);
    builder.addMatcher(authApi.endpoints.self.matchFulfilled, setUser);
    builder.addMatcher(authApi.endpoints.updateSelf.matchFulfilled, setUser);
    builder.addMatcher(
      authApi.endpoints.editBillingDetails.matchFulfilled,
      setCompanyBillingDetails,
    );
  },
});

export default authSlice.reducer;
