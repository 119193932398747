import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { getApiUrl } from 'config/api';
import { getCookie, setCookie } from 'handlers/cookie.handler';
import { removeCredentials } from 'services/auth/actions';

const baseQuery = fetchBaseQuery({
  baseUrl: getApiUrl(),
  prepareHeaders: (headers) => {
    const tokenCookie = getCookie('cu_token') ?? getCookie('cu_temporaryToken');

    if (tokenCookie) {
      headers.set('Authorization', `Bearer ${tokenCookie}`);
    }

    return headers;
  },
});

type RefreshTokenResponse = {
  token: string;
  refreshToken: string;
};

type ExpiryTime = {
  temporaryToken: number;
};

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const oldRefreshToken = getCookie('cu_refreshToken');

    if (oldRefreshToken) {
      const refreshResult = await baseQuery(
        {
          url: '/auth/refresh-token',
          method: 'POST',
          body: { refreshToken: oldRefreshToken },
        },
        api,
        extraOptions,
      );

      const { token, refreshToken: newRefreshToken } =
        (refreshResult.data as RefreshTokenResponse) || {};

      setCookie('cu_token', token, { expires: 30, sameSite: 'Strict' });
      setCookie('cu_refreshToken', newRefreshToken, { expires: 30, sameSite: 'Strict' });
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(removeCredentials());
    }
  } else if (result?.data) {
    const expiryTime: ExpiryTime = {
      temporaryToken: 6 / 24,
    };

    Object.entries(result?.data ?? {})
      .filter(([key]) => key.toLowerCase().includes('token'))
      .forEach(([tokenName, tokenValue]) => {
        setCookie(`cu_${tokenName}`, tokenValue, {
          expires: expiryTime[tokenName as keyof ExpiryTime] || 30,
          sameSite: 'Strict',
        });
      });
  }

  return result;
};

// https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

type FetchBaseQueryErrorWithMessage = FetchBaseQueryError & {
  data: { message: string };
};

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError` with a `data` object with a string `message` property
 */
export function isFetchBaseQueryErrorWithMessage(
  error: unknown,
): error is FetchBaseQueryErrorWithMessage {
  return (
    isFetchBaseQueryError(error) &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'message' in error.data
  );
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

export const getErrorMessage = (error: unknown) => {
  if (isFetchBaseQueryErrorWithMessage(error)) {
    return error.data.message;
  } else if (isErrorWithMessage(error)) {
    return error.message;
  }

  return 'Something went wrong. Please try again later.';
};

export const splitApi = createApi({
  reducerPath: 'api',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Auth', 'PaymentMethods', 'Invoices'],
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
