type ChargerIconProps = {
  stroke?: string;
};

export const ChargerIcon = ({ stroke = '#386957' }: ChargerIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7782 2L5.14566 12.1956C5.07002 12.2764 5.02142 12.3752 5.00562 12.4804C4.98982 12.5855 5.00748 12.6926 5.05651 12.7889C5.10554 12.8852 5.18388 12.9667 5.28227 13.0238C5.38067 13.0809 5.49499 13.1112 5.61172 13.1111H9.8894V22L19.5219 11.8044C19.5976 11.7236 19.6462 11.6248 19.662 11.5196C19.6778 11.4145 19.6601 11.3074 19.6111 11.2111C19.562 11.1148 19.4837 11.0333 19.3853 10.9762C19.2869 10.9191 19.1726 10.8888 19.0559 10.8889H14.7782V2Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
