import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ParkingAndReservation } from 'models/parking-and-reservation';
import { transactionsApi } from 'services/transactions/endpoints';

import { clearParkingAndReservation } from './actions';
import { parkingAndReservationApi } from './endpoints';

interface ParkingAndReservationsState {
  activeParkingAndReservation?: ParkingAndReservation;
}

const initialState: ParkingAndReservationsState = {
  activeParkingAndReservation: undefined,
};

const setActiveParkingAndReservationState = (
  state: ParkingAndReservationsState,
  { payload }: PayloadAction<{ data?: ParkingAndReservation }>,
) => {
  state.activeParkingAndReservation = payload.data;
};

const clearActiveParkingAndReservationState = () => initialState;

const setActiveParkingToState = (
  state: ParkingAndReservationsState,
  { payload }: PayloadAction<{ parking: any }>,
) => {
  state.activeParkingAndReservation = payload.parking;
};

const parkingAndReservationsSlice = createSlice({
  name: 'parkingAndReservations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clearParkingAndReservation, () => initialState);
    builder.addMatcher(
      parkingAndReservationApi.endpoints.reserveDevice.matchFulfilled,
      setActiveParkingAndReservationState,
    );
    builder.addMatcher(
      parkingAndReservationApi.endpoints.getActiveParkingAndReservation.matchFulfilled,
      setActiveParkingAndReservationState,
    );
    builder.addMatcher(
      parkingAndReservationApi.endpoints.cancelDeviceReservation.matchFulfilled,
      clearActiveParkingAndReservationState,
    );
    builder.addMatcher(
      transactionsApi.endpoints.stopCharging.matchFulfilled,
      setActiveParkingToState,
    );
  },
});

export default parkingAndReservationsSlice.reducer;
