import { useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import arrowRightIcon from 'app/images/icons/arrowRight.svg';
import costIcon from 'app/images/icons/costBlack.svg';
import timeIcon from 'app/images/icons/time.svg';
import { Notification } from 'app/views/Notifications/Notification';
import { DurationCounter } from 'components/atoms/DurationCounter';
import getMonetaryRevenue from 'handlers/get-monetary-revenue';
import { useCurrency } from 'hooks/use-currency';
import moment from 'moment';
import { clearParkingAndReservation } from 'services/parkingAndReservations/actions';
import { useGetActiveParkingAndReservationQuery } from 'services/parkingAndReservations/endpoints';
import { selectActiveParking } from 'services/parkingAndReservations/selectors';
import {
  selectActiveEvse,
  selectPublicSites,
  selectSiteVatMultiplier,
} from 'services/sites/selectors';
import { useGetTransactionQuery } from 'services/transactions/endpoints';
import { selectTransaction } from 'services/transactions/selectors';

export const ParkingSnackbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const activeParking = useSelector(selectActiveParking);
  const activeParkingTransactionId = activeParking?.transactionId;
  // TODO: Code should handle better if there is no active parking
  const transactionFromStore = useSelector(selectTransaction(activeParkingTransactionId ?? ''));
  const { data } = useGetTransactionQuery(activeParkingTransactionId ?? '', {
    skip: !!transactionFromStore,
  });
  const lastTransactionData = transactionFromStore ?? data?.transaction;
  const currency = useCurrency(activeParking?.currency);
  const activeParkingDeviceUuid = activeParking?.deviceUuid;
  const activeParkingConnectorId = activeParking?.connectorId;
  const freeParkingSeconds = (activeParking?.freeMinutes ?? 0) * 60;
  const vatMultiplier = useSelector(selectSiteVatMultiplier(activeParking?.siteUuid ?? '')) ?? 1;
  const parkingPricePerMinute = ((activeParking?.priceCentsPerMinute ?? 0) / 100) * vatMultiplier;
  const startParkingTimestamp = activeParking?.startTime;

  const remainingSeconds = moment().diff(moment(startParkingTimestamp), 'seconds');
  const paidParkingStart = moment(startParkingTimestamp).add(freeParkingSeconds, 'seconds');
  const isAfterPaidTime = moment().isAfter(paidParkingStart);
  const lastTransactionConsuption = (lastTransactionData?.amount ?? 0) / 1000;
  const transactionCost = getMonetaryRevenue(lastTransactionData?.monetaryRevenueWithVAT ?? 0);
  const [isPaidParking, setIsPaidParking] = useState(isAfterPaidTime);
  const [paidParkingCost, setPaidParkingCost] = useState(0);
  const publicSites = useSelector(selectPublicSites);
  const activeEvse = useSelector(selectActiveEvse);
  const { refetch } = useGetActiveParkingAndReservationQuery(undefined);

  const handleNavigateToParkedDevice = () => {
    navigate(
      `/sites?site=${activeParking?.siteUuid}&evse=${activeParkingDeviceUuid}-${activeParkingConnectorId}`,
    );
  };

  const calculatePaidParkingPrice = () => {
    const paidParkingMinutes = moment().diff(moment(paidParkingStart), 'seconds') / 60;
    return paidParkingMinutes * parkingPricePerMinute;
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;
    if (isPaidParking) {
      intervalId = setInterval(() => {
        setPaidParkingCost(calculatePaidParkingPrice());
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isPaidParking]);

  useEffect(() => {
    refetch();
  }, [publicSites]);

  return (
    <div className="relative flex w-full">
      <Notification
        onClick={handleNavigateToParkedDevice}
        backgroundColor="bg-raisingBlack"
        className="h-[72px]"
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center">
            <CountdownCircleTimer
              isPlaying={true}
              size={55}
              strokeWidth={3}
              duration={freeParkingSeconds}
              trailColor="#eb4e20"
              colors={freeParkingSeconds === 0 ? '#eb4e20' : '#5e5e5e'}
              initialRemainingTime={freeParkingSeconds - remainingSeconds}
              onComplete={() => ({
                shouldRepeat: false,
              })}
            >
              {() => (
                <img src={isPaidParking ? costIcon : timeIcon} alt="time" className="invert" />
              )}
            </CountdownCircleTimer>
            <div className="items-start ml-3">
              <p className="font-normal text-white">
                <span className="flex text-sm">
                  <DurationCounter
                    datetime={
                      remainingSeconds < freeParkingSeconds
                        ? startParkingTimestamp
                        : paidParkingStart
                    }
                    maxTimeSeconds={freeParkingSeconds}
                    onMaxTimeAction={() =>
                      parkingPricePerMinute === 0
                        ? dispatch(clearParkingAndReservation())
                        : setIsPaidParking(true)
                    }
                  />
                  <span className="pl-2">
                    {isPaidParking && `-  ${paidParkingCost.toFixed(2)} ${currency.symbol}`}
                  </span>
                </span>
                <span className="flex flex-col items-start">
                  <span className="text-xs opacity-50">
                    {`${t('chargingFinished', 'Charging finished')}:
                    ${
                      lastTransactionConsuption > 0 ? lastTransactionConsuption.toFixed(2) : '0.00'
                    } kWh -
                    ${transactionCost > 0 ? transactionCost.toFixed(2) : '0.00'} ${
                      currency.symbol
                    }`}
                  </span>
                  <span className="text-xs opacity-50">
                    {isPaidParking
                      ? t('paidParking', 'Paid parking')
                      : t('freeParking', 'Free parking')}
                  </span>
                </span>
              </p>
            </div>
          </div>
          {!activeEvse && !activeParking && (
            <img src={arrowRightIcon} alt="arrow" className="filter invert w-6 h-6 mr-3" />
          )}
        </div>
      </Notification>
    </div>
  );
};
