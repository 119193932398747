export interface CountryType {
  code: string;
  label: string;
  phone: string;
}

export const countries: readonly CountryType[] = [
  {
    code: 'AF',
    label: 'Afghanistan',
    phone: '93',
  },
  {
    code: 'AL',
    label: 'Albania',
    phone: '355',
  },
  {
    code: 'DZ',
    label: 'Algeria',
    phone: '213',
  },
  {
    code: 'AX',
    label: 'Alland Islands',
    phone: '358',
  },
  {
    code: 'AS',
    label: 'American Samoa',
    phone: '1-684',
  },
  {
    code: 'AD',
    label: 'Andorra',
    phone: '376',
  },
  {
    code: 'AO',
    label: 'Angola',
    phone: '244',
  },
  {
    code: 'AI',
    label: 'Anguilla',
    phone: '1-264',
  },
  {
    code: 'AQ',
    label: 'Antarctica',
    phone: '672',
  },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
  },
  {
    code: 'AR',
    label: 'Argentina',
    phone: '54',
  },
  {
    code: 'AM',
    label: 'Armenia',
    phone: '374',
  },
  {
    code: 'AW',
    label: 'Aruba',
    phone: '297',
  },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
  },
  {
    code: 'AT',
    label: 'Austria',
    phone: '43',
  },
  {
    code: 'AZ',
    label: 'Azerbaijan',
    phone: '994',
  },
  {
    code: 'BS',
    label: 'Bahamas',
    phone: '1-242',
  },
  {
    code: 'BH',
    label: 'Bahrain',
    phone: '973',
  },
  {
    code: 'BD',
    label: 'Bangladesh',
    phone: '880',
  },
  {
    code: 'BB',
    label: 'Barbados',
    phone: '1-246',
  },
  {
    code: 'BY',
    label: 'Belarus',
    phone: '375',
  },
  {
    code: 'BE',
    label: 'Belgium',
    phone: '32',
  },
  {
    code: 'BZ',
    label: 'Belize',
    phone: '501',
  },
  {
    code: 'BJ',
    label: 'Benin',
    phone: '229',
  },
  {
    code: 'BM',
    label: 'Bermuda',
    phone: '1-441',
  },
  {
    code: 'BT',
    label: 'Bhutan',
    phone: '975',
  },
  {
    code: 'BO',
    label: 'Bolivia',
    phone: '591',
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
  },
  {
    code: 'BW',
    label: 'Botswana',
    phone: '267',
  },
  {
    code: 'BV',
    label: 'Bouvet Island',
    phone: '47',
  },
  {
    code: 'BR',
    label: 'Brazil',
    phone: '55',
  },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
  },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
  },
  {
    code: 'BN',
    label: 'Brunei Darussalam',
    phone: '673',
  },
  {
    code: 'BG',
    label: 'Bulgaria',
    phone: '359',
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
    phone: '226',
  },
  {
    code: 'BI',
    label: 'Burundi',
    phone: '257',
  },
  {
    code: 'KH',
    label: 'Cambodia',
    phone: '855',
  },
  {
    code: 'CM',
    label: 'Cameroon',
    phone: '237',
  },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
  },
  {
    code: 'CV',
    label: 'Cape Verde',
    phone: '238',
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
    phone: '1-345',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
  },
  {
    code: 'TD',
    label: 'Chad',
    phone: '235',
  },
  {
    code: 'CL',
    label: 'Chile',
    phone: '56',
  },
  {
    code: 'CN',
    label: 'China',
    phone: '86',
  },
  {
    code: 'CX',
    label: 'Christmas Island',
    phone: '61',
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
  },
  {
    code: 'CO',
    label: 'Colombia',
    phone: '57',
  },
  {
    code: 'KM',
    label: 'Comoros',
    phone: '269',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
  },
  {
    code: 'CK',
    label: 'Cook Islands',
    phone: '682',
  },
  {
    code: 'CR',
    label: 'Costa Rica',
    phone: '506',
  },
  {
    code: 'CI',
    label: "Cote d'Ivoire",
    phone: '225',
  },
  {
    code: 'HR',
    label: 'Croatia',
    phone: '385',
  },
  {
    code: 'CU',
    label: 'Cuba',
    phone: '53',
  },
  {
    code: 'CW',
    label: 'Curacao',
    phone: '599',
  },
  {
    code: 'CY',
    label: 'Cyprus',
    phone: '357',
  },
  {
    code: 'CZ',
    label: 'Czech Republic',
    phone: '420',
  },
  {
    code: 'DK',
    label: 'Denmark',
    phone: '45',
  },
  {
    code: 'DJ',
    label: 'Djibouti',
    phone: '253',
  },
  {
    code: 'DM',
    label: 'Dominica',
    phone: '1-767',
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
  },
  {
    code: 'EC',
    label: 'Ecuador',
    phone: '593',
  },
  {
    code: 'EG',
    label: 'Egypt',
    phone: '20',
  },
  {
    code: 'SV',
    label: 'El Salvador',
    phone: '503',
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinea',
    phone: '240',
  },
  {
    code: 'ER',
    label: 'Eritrea',
    phone: '291',
  },
  {
    code: 'EE',
    label: 'Estonia',
    phone: '372',
  },
  {
    code: 'ET',
    label: 'Ethiopia',
    phone: '251',
  },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
  },
  {
    code: 'FO',
    label: 'Faroe Islands',
    phone: '298',
  },
  {
    code: 'FJ',
    label: 'Fiji',
    phone: '679',
  },
  {
    code: 'FI',
    label: 'Finland',
    phone: '358',
  },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
  },
  {
    code: 'GF',
    label: 'French Guiana',
    phone: '594',
  },
  {
    code: 'PF',
    label: 'French Polynesia',
    phone: '689',
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
  },
  {
    code: 'GA',
    label: 'Gabon',
    phone: '241',
  },
  {
    code: 'GM',
    label: 'Gambia',
    phone: '220',
  },
  {
    code: 'GE',
    label: 'Georgia',
    phone: '995',
  },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
  },
  {
    code: 'GH',
    label: 'Ghana',
    phone: '233',
  },
  {
    code: 'GI',
    label: 'Gibraltar',
    phone: '350',
  },
  {
    code: 'GR',
    label: 'Greece',
    phone: '30',
  },
  {
    code: 'GL',
    label: 'Greenland',
    phone: '299',
  },
  {
    code: 'GD',
    label: 'Grenada',
    phone: '1-473',
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
    phone: '590',
  },
  {
    code: 'GU',
    label: 'Guam',
    phone: '1-671',
  },
  {
    code: 'GT',
    label: 'Guatemala',
    phone: '502',
  },
  {
    code: 'GG',
    label: 'Guernsey',
    phone: '44',
  },
  {
    code: 'GN',
    label: 'Guinea',
    phone: '224',
  },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
    phone: '245',
  },
  {
    code: 'GY',
    label: 'Guyana',
    phone: '592',
  },
  {
    code: 'HT',
    label: 'Haiti',
    phone: '509',
  },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
  },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
  },
  {
    code: 'HN',
    label: 'Honduras',
    phone: '504',
  },
  {
    code: 'HK',
    label: 'Hong Kong',
    phone: '852',
  },
  {
    code: 'HU',
    label: 'Hungary',
    phone: '36',
  },
  {
    code: 'IS',
    label: 'Iceland',
    phone: '354',
  },
  {
    code: 'IN',
    label: 'India',
    phone: '91',
  },
  {
    code: 'ID',
    label: 'Indonesia',
    phone: '62',
  },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
  },
  {
    code: 'IQ',
    label: 'Iraq',
    phone: '964',
  },
  {
    code: 'IE',
    label: 'Ireland',
    phone: '353',
  },
  {
    code: 'IM',
    label: 'Isle of Man',
    phone: '44',
  },
  {
    code: 'IL',
    label: 'Israel',
    phone: '972',
  },
  {
    code: 'IT',
    label: 'Italy',
    phone: '39',
  },
  {
    code: 'JM',
    label: 'Jamaica',
    phone: '1-876',
  },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
  },
  {
    code: 'JE',
    label: 'Jersey',
    phone: '44',
  },
  {
    code: 'JO',
    label: 'Jordan',
    phone: '962',
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
    phone: '7',
  },
  {
    code: 'KE',
    label: 'Kenya',
    phone: '254',
  },
  {
    code: 'KI',
    label: 'Kiribati',
    phone: '686',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
  },
  {
    code: 'KR',
    label: 'Korea, Republic of',
    phone: '82',
  },
  {
    code: 'XK',
    label: 'Kosovo',
    phone: '383',
  },
  {
    code: 'KW',
    label: 'Kuwait',
    phone: '965',
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
    phone: '996',
  },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
  },
  {
    code: 'LV',
    label: 'Latvia',
    phone: '371',
  },
  {
    code: 'LB',
    label: 'Lebanon',
    phone: '961',
  },
  {
    code: 'LS',
    label: 'Lesotho',
    phone: '266',
  },
  {
    code: 'LR',
    label: 'Liberia',
    phone: '231',
  },
  {
    code: 'LY',
    label: 'Libya',
    phone: '218',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    phone: '423',
  },
  {
    code: 'LT',
    label: 'Lithuania',
    phone: '370',
  },
  {
    code: 'LU',
    label: 'Luxembourg',
    phone: '352',
  },
  {
    code: 'MO',
    label: 'Macao',
    phone: '853',
  },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  {
    code: 'MG',
    label: 'Madagascar',
    phone: '261',
  },
  {
    code: 'MW',
    label: 'Malawi',
    phone: '265',
  },
  {
    code: 'MY',
    label: 'Malaysia',
    phone: '60',
  },
  {
    code: 'MV',
    label: 'Maldives',
    phone: '960',
  },
  {
    code: 'ML',
    label: 'Mali',
    phone: '223',
  },
  {
    code: 'MT',
    label: 'Malta',
    phone: '356',
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
    phone: '692',
  },
  {
    code: 'MQ',
    label: 'Martinique',
    phone: '596',
  },
  {
    code: 'MR',
    label: 'Mauritania',
    phone: '222',
  },
  {
    code: 'MU',
    label: 'Mauritius',
    phone: '230',
  },
  {
    code: 'YT',
    label: 'Mayotte',
    phone: '262',
  },
  {
    code: 'MX',
    label: 'Mexico',
    phone: '52',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
  },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
  },
  {
    code: 'MC',
    label: 'Monaco',
    phone: '377',
  },
  {
    code: 'MN',
    label: 'Mongolia',
    phone: '976',
  },
  {
    code: 'ME',
    label: 'Montenegro',
    phone: '382',
  },
  {
    code: 'MS',
    label: 'Montserrat',
    phone: '1-664',
  },
  {
    code: 'MA',
    label: 'Morocco',
    phone: '212',
  },
  {
    code: 'MZ',
    label: 'Mozambique',
    phone: '258',
  },
  {
    code: 'MM',
    label: 'Myanmar',
    phone: '95',
  },
  {
    code: 'NA',
    label: 'Namibia',
    phone: '264',
  },
  {
    code: 'NR',
    label: 'Nauru',
    phone: '674',
  },
  {
    code: 'NP',
    label: 'Nepal',
    phone: '977',
  },
  {
    code: 'NL',
    label: 'Netherlands',
    phone: '31',
  },
  {
    code: 'NC',
    label: 'New Caledonia',
    phone: '687',
  },
  {
    code: 'NZ',
    label: 'New Zealand',
    phone: '64',
  },
  {
    code: 'NI',
    label: 'Nicaragua',
    phone: '505',
  },
  {
    code: 'NE',
    label: 'Niger',
    phone: '227',
  },
  {
    code: 'NG',
    label: 'Nigeria',
    phone: '234',
  },
  {
    code: 'NU',
    label: 'Niue',
    phone: '683',
  },
  {
    code: 'NF',
    label: 'Norfolk Island',
    phone: '672',
  },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
  },
  {
    code: 'NO',
    label: 'Norway',
    phone: '47',
  },
  {
    code: 'OM',
    label: 'Oman',
    phone: '968',
  },
  {
    code: 'PK',
    label: 'Pakistan',
    phone: '92',
  },
  {
    code: 'PW',
    label: 'Palau',
    phone: '680',
  },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
  },
  {
    code: 'PA',
    label: 'Panama',
    phone: '507',
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
    phone: '675',
  },
  {
    code: 'PY',
    label: 'Paraguay',
    phone: '595',
  },
  {
    code: 'PE',
    label: 'Peru',
    phone: '51',
  },
  {
    code: 'PH',
    label: 'Philippines',
    phone: '63',
  },
  {
    code: 'PN',
    label: 'Pitcairn',
    phone: '870',
  },
  {
    code: 'PL',
    label: 'Poland',
    phone: '48',
  },
  {
    code: 'PT',
    label: 'Portugal',
    phone: '351',
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
    phone: '1',
  },
  {
    code: 'QA',
    label: 'Qatar',
    phone: '974',
  },
  {
    code: 'RE',
    label: 'Reunion',
    phone: '262',
  },
  {
    code: 'RO',
    label: 'Romania',
    phone: '40',
  },
  {
    code: 'RU',
    label: 'Russian Federation',
    phone: '7',
  },
  {
    code: 'RW',
    label: 'Rwanda',
    phone: '250',
  },
  {
    code: 'BL',
    label: 'Saint Barthelemy',
    phone: '590',
  },
  {
    code: 'SH',
    label: 'Saint Helena',
    phone: '290',
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
    phone: '1-758',
  },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
  },
  {
    code: 'WS',
    label: 'Samoa',
    phone: '685',
  },
  {
    code: 'SM',
    label: 'San Marino',
    phone: '378',
  },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
  },
  {
    code: 'SA',
    label: 'Saudi Arabia',
    phone: '966',
  },
  {
    code: 'SN',
    label: 'Senegal',
    phone: '221',
  },
  {
    code: 'RS',
    label: 'Serbia',
    phone: '381',
  },
  {
    code: 'SC',
    label: 'Seychelles',
    phone: '248',
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
    phone: '232',
  },
  {
    code: 'SG',
    label: 'Singapore',
    phone: '65',
  },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
  },
  {
    code: 'SK',
    label: 'Slovakia',
    phone: '421',
  },
  {
    code: 'SI',
    label: 'Slovenia',
    phone: '386',
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
    phone: '677',
  },
  {
    code: 'SO',
    label: 'Somalia',
    phone: '252',
  },
  {
    code: 'ZA',
    label: 'South Africa',
    phone: '27',
  },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  {
    code: 'SS',
    label: 'South Sudan',
    phone: '211',
  },
  {
    code: 'ES',
    label: 'Spain',
    phone: '34',
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
    phone: '94',
  },
  {
    code: 'SD',
    label: 'Sudan',
    phone: '249',
  },
  {
    code: 'SR',
    label: 'Suriname',
    phone: '597',
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
  },
  {
    code: 'SZ',
    label: 'Swaziland',
    phone: '268',
  },
  {
    code: 'SE',
    label: 'Sweden',
    phone: '46',
  },
  {
    code: 'CH',
    label: 'Switzerland',
    phone: '41',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
  },
  {
    code: 'TW',
    label: 'Taiwan, Republic of China',
    phone: '886',
  },
  {
    code: 'TJ',
    label: 'Tajikistan',
    phone: '992',
  },
  {
    code: 'TH',
    label: 'Thailand',
    phone: '66',
  },
  {
    code: 'TL',
    label: 'Timor-Leste',
    phone: '670',
  },
  {
    code: 'TG',
    label: 'Togo',
    phone: '228',
  },
  {
    code: 'TK',
    label: 'Tokelau',
    phone: '690',
  },
  {
    code: 'TO',
    label: 'Tonga',
    phone: '676',
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
  },
  {
    code: 'TN',
    label: 'Tunisia',
    phone: '216',
  },
  {
    code: 'TR',
    label: 'Turkey',
    phone: '90',
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
    phone: '993',
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
  },
  {
    code: 'TV',
    label: 'Tuvalu',
    phone: '688',
  },
  {
    code: 'UG',
    label: 'Uganda',
    phone: '256',
  },
  {
    code: 'UA',
    label: 'Ukraine',
    phone: '380',
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  {
    code: 'GB',
    label: 'United Kingdom',
    phone: '44',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
  },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
  },
  {
    code: 'UY',
    label: 'Uruguay',
    phone: '598',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
    phone: '998',
  },
  {
    code: 'VU',
    label: 'Vanuatu',
    phone: '678',
  },
  {
    code: 'VE',
    label: 'Venezuela',
    phone: '58',
  },
  {
    code: 'VN',
    label: 'Vietnam',
    phone: '84',
  },
  {
    code: 'WF',
    label: 'Wallis and Futuna',
    phone: '681',
  },
  {
    code: 'EH',
    label: 'Western Sahara',
    phone: '212',
  },
  {
    code: 'YE',
    label: 'Yemen',
    phone: '967',
  },
  {
    code: 'ZM',
    label: 'Zambia',
    phone: '260',
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
    phone: '263',
  },
];
